import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import { Box, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/common/CustomButton';
import { ROUTES } from '../../../services/constantService';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CompanyImageAvatar from '../CompanyImageAvatar';
import ViewReportDrawer from './CandidatePipeline/ViewReportDrawer';
import { ProjectDataContext } from './Context';
function HeaderClosed(props) {
  const { t } = useTranslation();
  const { handleExpand, isPublished, handlePublish, stages, handleStageChange = () => {}, stage } = props;
  const { projectData, isBD } = useContext(ProjectDataContext);
  const [viewReport, setViewReport] = useState(false);
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      className='mt-3 p-3 pl-2 pr-2'
      sx={{
        backgroundColor: 'titleBarBackground.main',
        width: '100%',
        borderRadius: '12px',
        position: 'relative'
      }}
      flexWrap={'wrap'}
      spacing={1}
    >
      {!isBD && <ViewReportDrawer fromHeaderBar projectName={projectData?.job_title} isDrawerOpen={viewReport} setIsDrawerOpen={setViewReport} projectId={projectData?.id} />}
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <CompanyImageAvatar id={projectData?.ign_companies?.id} sx={{ width: '30px', borderRadius: '50%' }} />
        <Typography variant='h6' className='ml-1' sx={{ fontWeight: 600 }}>
          {projectData?.job_title}
        </Typography>
        {!!projectData?.job_number && (
          <Typography variant='caption' className='ml-2'>
            {projectData?.record_type === 'Project' ? t('module.project') : projectData?.record_type} : {projectData?.job_number}
          </Typography>
        )}
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
        <Select components={'div'} className='stage-drop-down' size='small' sx={{ maxWidth: '100px' }} value={stage ?? ''} onChange={e => handleStageChange(e.target.value)}>
          {stages.map(stage => (
            <MenuItem key={stage.id} value={stage.name}>
              {stage.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Link
        style={{
          color: 'black'
        }}
        target='_blank'
        to={`${ROUTES.allCompanies}/${projectData?.ign_companies?.id}`}
      >
        <WorkIcon className='p-1' />
        <Typography variant='caption' className='pe-auto' sx={{ cursor: 'pointer' }}>
          {projectData?.ign_companies?.name}
        </Typography>
      </Link>
      <Box>
        <LocationOnIcon className='p-1' />
        {projectData?.location?.length > 15 ? (
          <Tooltip title={projectData?.location}>
            <Typography variant='caption'>{projectData?.location.slice(0, 15) + '...'}</Typography>
          </Tooltip>
        ) : (
          <Typography variant='caption'>{projectData?.location}</Typography>
        )}
      </Box>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
        <Typography variant='caption'>{t('common.addedBy')}:</Typography>
        <Typography variant='caption' width={'bold'}>
          {projectData?.creator?.name}
        </Typography>
      </Stack>

      <Box>
        <Typography variant='caption'>
          {t('project.hiringManager')}:{' '}
          <Link
            style={{
              color: 'black'
            }}
            target='_blank'
            to={`${ROUTES.allContactGrid}/${projectData?.hiring_manager?.contact?.id}`}
          >
            <Typography component={'span'} className='cursor-pointer' variant='caption' sx={{ textDecoration: 'underline' }}>
              {projectData?.hiring_manager?.contact?.name}
            </Typography>
          </Link>
        </Typography>
      </Box>
      {isPublished ? (
        <CustomButton show={!isBD} buttonText={t('project.published')} variant={'contained'} type={'primary'} iconRight={<ArrowDropDownIcon />} onClick={handlePublish} />
      ) : (
        <CustomButton show={!isBD} buttonText={t('project.unpublished')} variant={'text'} type={'btn'} iconRight={<ArrowDropDownIcon />} onClick={handlePublish} />
      )}
      <Box>
        <CustomButton
          show={!isBD}
          variant='contained'
          buttonText={t(`${TRANS_KEYS.VIEW_REPORTS}:key`)}
          type={'primary'}
          onClick={() => {
            setViewReport(true);
          }}
        />

        {/* <Typography variant="caption">
          {t(`${TRANS_KEYS.SEARCH_ROAD_MAP}:key`)}:
          <Typography
            component={"span"}
            variant="caption"
            sx={{ color: "primary.main", fontWeight: "600" }}
          >
            Completed
          </Typography>
        </Typography> */}
      </Box>
      <ExpandMoreIcon
        sx={{
          position: 'absolute',
          bottom: '-10px',
          left: '48%',
          backgroundColor: 'primary.main',
          color: 'titleBarBackground.main',
          borderRadius: '50%',
          cursor: 'pointer'
        }}
        onClick={handleExpand}
      />
    </Stack>
  );
}

HeaderClosed.propTypes = {
  handleExpand: PropTypes.func,
  isPublished: PropTypes.bool,
  handlePublish: PropTypes.func,
  stages: PropTypes.array,
  handleStageChange: PropTypes.func,
  stage: PropTypes.string
};

export default HeaderClosed;
