import { Box, Divider, Checkbox, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CustomTable from '../../common/CustomTable';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import '../../../../src/utils/common.scss';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import PhoneTypeSelection from '../../common/FunctionalComponents/PhoneTypeSelection';
import EmailTypeSelection from '../../common/FunctionalComponents/EmailTypeSelection';
import { ADDRESS_TYPE, GET, IGNYTE_CONSTANTS } from '../../../services/constantService';
import CountrySelection from '../../common/FunctionalComponents/CountrySelection';
import { ExpandableSection } from '../../ActivityLog/Components/ExpandableSection';
import StateSelection from '../../common/FunctionalComponents/StateSelection';
import AddressTypeSelection from '../../common/FunctionalComponents/AddressTypeSelection';
import MakeConfidential from '../../common/MakeConfidential/MakeConfidential';
import GeoLocationInput from '../../common/GeoLocation';
import CustomPhoneInput, { formatPhoneNumber, isValidPhoneNumber } from '../../common/CustomPhoneInput';
import { validateEmailV1 as validateEmail } from '../../MessageTemplatePopup/utils';
import CompanyAddressTypeSelection from '../../common/FunctionalComponents/CompanyAddressTypeSelection';
import { LocationSetupApi } from '../../../services/ApiService';
import axios from 'axios';

const ViewPersonalInfo = props => {
  const { renderActions, data, register, unregister, setValue = () => {}, updateField } = props;
  const edit = {
    primaryPhoneNumber: false,
    secondaryPhoneNumber: false,
    primaryEmail: false,
    secondaryEmail: false,
    contact_address: false,
    highlights: false,
    company_address: false
  };
  let phoneNumber = '123456789';

  const [, /* isMakeAddressPrimary */ setIsMakeAddressPrimary] = useState(false);
  const [editing, setEditing] = useState(edit);
  const [tempPhoneNumber /* setTempPhoneNumber */] = useState(phoneNumber);
  const [email /* GIT STS */] = useState('souravvinod@gmail.com');
  const [tempEmail /* setTempEmail */] = useState(email);
  // const [workAddress /* setworkAddress */] = useState('XYZ XYZ XYZ');
  // const [tempWorkAddress /* setTempWorkAddress */] = useState(workAddress);
  const [isEditingPhone, setIsEditingPhone] = useState(true);
  const [isEditingEmail, setIsEditingEmail] = useState(true);
  const [isShowDoneIconForAddress, setIsShowDoneIconForAddress] = useState(false);
  // const [isEditingAddress, setIsEditingAddress] = useState(true);
  const [isEditingAddress, setIsEditingAddress] = useState({ workAddress: true, homeAddress: true });
  const [isEditingHomeAddress, setIsEditingHomeAddress] = useState(false);
  const [country, setCountry] = useState({});
  let address = {
    address_lines: '',
    address_type: null,
    checked: true,
    city: '',
    contact_country: null,
    state: '',
    zip_code: '',
    metropolitan_area: '',
    errors: {
      address_type: false,
      contact_country: false
    }
  };
  const [addressDetails, setAddressDetails] = useState([address]);
  const [defaultAddressDetails, setDefaultAddressDetails] = useState([address]);

  const [phoneList, setPhoneList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [defaultPhoneNumbers, setDefaultPhoneNumbers] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [placeId, setPlaceId] = useState('');
  const [defaultEmails, setDefaultEmails] = useState([]);
  const [emails, setEmails] = useState([]);
  useEffect(() => {
    setEmails(data?.contact_emails?.map(email => ({ ...email, is_valid: validateEmail(email?.email || ''), is_type_selected: email?.email_type !== '' })));
    setDefaultEmails(data?.contact_emails?.map(email => ({ ...email, is_valid: validateEmail(email?.email || ''), is_type_selected: email?.email_type !== '' })));

    setPhoneNumbers(data?.contact_phones?.map(phone => ({ ...phone, is_valid: isValidPhoneNumber(phone?.phone_number || ''), is_type_selected: phone?.phone_type !== '' })));
    setDefaultPhoneNumbers(data?.contact_phones?.map(phone => ({ ...phone, is_valid: isValidPhoneNumber(phone?.phone_number || ''), is_type_selected: phone?.phone_type !== '' })));

    setIsMakeAddressPrimary(
      data?.contact_address?.map(ele => {
        return ele.is_primary;
      })
    );

    if (data?.contact_address && data?.contact_address?.length) {
      const updatedAddresses = data?.contact_address?.map(ele => {
        let address = {
          ...ele,
          address_lines: ele.address_lines,
          address_type: ele.address_type,
          checked: ele.is_primary ? ele.is_primary : false,
          city: ele.city,
          contact_country: ele.contact_country,
          state: ele.state,
          zip_code: ele.zip_code,
          metropolitan_area: ele.metropolitan_area,
          ign_translate_value: ele.ign_translate_value,
          errors: {
            address_type: false,
            contact_country: false
          }
          //id: ele.id
        };
        return address;
      });
      setAddressDetails(updatedAddresses);
      setDefaultAddressDetails(updatedAddresses);
    } else {
      setAddressDetails([]);
      setDefaultAddressDetails([]);
    }
  }, [data]);

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_HIGHLIGHTS,
    TRANS_KEYS.CONTACTS_WORK_ADDRESS,
    TRANS_KEYS.CONTACTS_RESIDENTIAL_ADDRESS,
    TRANS_KEYS.CONTACTS_PHONE_TYPE,
    TRANS_KEYS.CONTACTS_PHONE,
    TRANS_KEYS.CONTACTS_IS_PRIMARY,
    TRANS_KEYS.CONTACTS_ADD_PHONE,
    TRANS_KEYS.CONTACTS_EMAIL_TYPE,
    TRANS_KEYS.CONTACTS_EMAIL,
    TRANS_KEYS.CONTACTS_ADD_EMAIL,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_COUNTRY,
    TRANS_KEYS.CONTACTS_STATE,
    TRANS_KEYS.CONTACTS_CITY,
    TRANS_KEYS.CONTACTS_METROPOLITAN_AREA,
    TRANS_KEYS.CONTACTS_ADDRESS_TYPE,
    TRANS_KEYS.CONTACTS_ZIP,
    TRANS_KEYS.CONTACTS_ADDRESS
  ]);
  useEffect(() => {
    register('contact_address');
    register('contact_phones');
    register('contact_emails');
    register('makePrimary');
    return () => {
      unregister('contact_address');
      unregister('contact_phones');
      unregister('contact_emails');
      unregister('makePrimary');
    };
  }, [register, unregister]);

  useEffect(() => {
    setValue(
      'contact_address',
      addressDetails?.map(ele => {
        let address = {
          id: ele?.id && ele.id,
          address_lines: ele?.address_lines,
          address_type: ele?.address_type,
          city: ele?.city,
          contact_id: data?.id,
          country_id: ele?.contact_country?.id,
          is_primary: ele?.checked,
          metropolitan_area: ele?.metropolitan_area,
          state_id: ele?.state?.id,
          zip_code: ele?.zip_code,
          errors: ele.errors || {
            address_type: false,
            contact_country: false
          }
        };
        console.log(address, 'address123');
        return address;
      })
    );
  }, [addressDetails]);

  const addAddressForm = () => {
    const newList = [
      ...addressDetails,
      {
        contact_country: null,
        state: '',
        zip_code: '',
        city: '',
        metropolitan_area: '',
        address_lines: '',
        address_type: null,
        checked: false,
        errors: {
          contact_country: false,
          address_type: false
        }
      }
    ];
    setAddressDetails(newList);
  };
  const validateAddress = (addressDetails, index, name) => {
    const updatedAddressDetails = [...addressDetails];
    const validations = {
      address_type: true,
      contact_country: true
    };

    if (validations[name]) {
      updatedAddressDetails[index] = {
        ...updatedAddressDetails[index],
        errors: {
          ...updatedAddressDetails[index].errors,
          [name]: !updatedAddressDetails[index][name]
        }
      };
    }

    return updatedAddressDetails;
  };

  const handleChangeAddressData = async (index, name, data) => {
    let newList = [...addressDetails];
    newList[index] = { ...newList[index], [name]: data };
    const updatedList = [...newList];
    const validatedAddress = validateAddress(updatedList, index, name);
    setAddressDetails(validatedAddress);

    if (name === 'metropolitan_area') {
      console.log(data, 'data1234567890');
      await fetchData(index, data, true);
    }
  };

  const hadlechangeForCheckbox = (e, index) => {
    // setIsMakeAddressPrimary(e.target.checked)
    const hasPrimaryAddress = addressDetails.some(address => address.checked);
    const newAddressDetails = addressDetails.map((address, i) => {
      if (i === index) {
        if (!hasPrimaryAddress && i === 0) {
          return { ...address, checked: true };
        }
        return { ...address, checked: true };
      } else {
        return { ...address, checked: false };
      }
    });
    setAddressDetails(newAddressDetails);
    // handleChangeAddressData(index, "checked", e.target.checked)
  };

  const removeAddress = index => {
    let details = [...addressDetails];
    details.splice(index, 1);
    setAddressDetails(details);
    // addAddressCount(details);
    // setCheckboxData(false);
  };

  const handleEditPhoneChange = value => {
    if (phoneNumbers.length === 0) handleAddPhoneNumber();
    setIsEditingPhone(value);
  };

  // const handleEditAddress = (value) => {
  //   setIsEditingAddress(value);
  // };

  const handleEditEmailChange = value => {
    if (emails.length === 0) handleAddEmail();
    console.log(emails.length, 'value123');
    setIsEditingEmail(value);
  };

  // const handleEditAddressChange = value => {
  //   console.log(value, 'value1234567890');
  //   setIsEditingAddress(value);
  // };

  const handleEditAddressChange = value => {
    console.log(value, 'value1234567890');
    if (addressDetails.length === 0) handleAddAddress();
    console.log(value);
    setIsEditingAddress(value);
  };

  const handleEditClick = type => {
    const typeToStateMap = {
      primaryPhoneNumber: {
        tempState: tempPhoneNumber,
        editKey: 'primaryPhoneNumber'
      },
      secondaryPhoneNumber: {
        tempState: tempPhoneNumber,
        editKey: 'secondaryPhoneNumber'
      },
      primaryEmail: { tempState: tempEmail, editKey: 'primaryEmail' },
      secondaryEmail: { tempState: tempEmail, editKey: 'secondaryEmail' }
      // contact_address: { tempState: tempWorkAddress, editKey: 'contact_address' }
    };

    if (type in typeToStateMap) {
      const { editKey } = typeToStateMap[type];
      setEditing(prevState => ({ ...prevState, [editKey]: true }));
    } else {
      setEditing(edit);
    }
  };

  const onEditCancel = type => {
    if (type === 'contact_phones') {
      setPhoneNumbers(defaultPhoneNumbers);
    }

    if (type === 'contact_emails') {
      setEmails(defaultEmails);
    }
    if (type === 'company_address') {
      setIsEditingAddress({
        ...isEditingAddress,
        workAddress: false
      });
      setEditing({ ...editing, contact_address: false });
    }
    if (type === 'contact_address') {
      setIsEditingAddress({
        ...isEditingAddress,
        homeAddress: true
      });
      setEditing({ ...editing, company_address: false });
    }
  };
  const handleCloseClick = type => {
    console.log(type, 'type123yyyy');

    const typeToEditKey = {
      primaryPhoneNumber: 'primaryPhoneNumber',
      secondaryPhoneNumber: 'secondaryPhoneNumber',
      primaryEmail: 'primaryEmail',
      secondaryEmail: 'secondaryEmail',
      company_address: 'company_address',
      contact_address: 'contact_address',
      highlights: 'highlights',
      workAddress: 'workAddress',
      homeAddress: 'homeAddress'
    };

    if (type in typeToEditKey) {
      setEditing(prevState => ({
        ...prevState,
        [typeToEditKey[type]]: false
      }));
      if (type === 'company_address' || type === 'contact_address') {
        setIsEditingAddress(prevState => {
          const newState = {
            ...prevState,
            [type === 'company_address' ? 'workAddress' : 'homeAddress']: false
          };
          console.log(newState, 'Updated isEditingAddress state'); // Log the updated state
          return newState;
        });
      }
    }
  };
  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { phone_type: '', phone_number: '', is_primary: false, is_valid: true, is_type_selected: true }]);
  };

  const handleRemovePhoneNumber = index => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers.splice(index, 1);
    if (updatedPhoneNumbers?.length === 1) {
      const primaryPhone = updatedPhoneNumbers.map(number => {
        return { ...number, is_primary: true };
      });
      setPhoneNumbers(primaryPhone);
    } else {
      setPhoneNumbers(updatedPhoneNumbers);
    }
  };

  const handleRemoveEmail = index => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    if (updatedEmails?.length === 1) {
      const primaryEmail = updatedEmails.map(email => {
        return { ...email, is_primary: true };
      });
      setEmails(primaryEmail);
    } else {
      setEmails(updatedEmails);
    }
  };

  const handleAddEmail = () => {
    setEmails([...emails, { email_type: '', email: '', is_primary: false, is_valid: true, is_type_selected: true }]);
  };

  const handleAddAddress = type => {
    console.log(type, 'type1234567890');
    setAddressDetails([
      ...addressDetails,
      {
        contact_country: null,
        state: '',
        zip_code: '',
        city: '',
        metropolitan_area: '',
        address_lines: '',
        address_type: type,
        checked: false,
        errors: { contact_country: false, address_type: false }
      }
    ]);
  };

  const validateEmailAndType = (field, newValue = '', email) => {
    let is_valid = email.is_valid;
    let is_type_selected = email.is_type_selected;

    if (field === 'email') {
      is_valid = validateEmail(newValue);
    }
    if (field === 'email_type') {
      is_type_selected = newValue !== '';
    }

    return {
      is_valid,
      is_type_selected
    };
  };

  const handleChangeEmail = (index, field, value) => {
    const updatedEmail = emails.map((email, i) => {
      if (i === index) {
        const validateFields = validateEmailAndType(field, value, email);
        if (field === 'is_primary') {
          return { ...email, is_primary: true, ...validateFields };
        }
        return { ...email, [field]: value, ign_translate_value: value, ...validateFields };
      }
      if (i !== index && field === 'is_primary') {
        return { ...email, is_primary: false };
      }

      return { ...email };
    });

    setEmails(updatedEmail);
  };

  const validatePhoneNumberAndType = (field, newValue = '', phoneNumber) => {
    let is_valid = phoneNumber.is_valid;
    let is_type_selected = phoneNumber.is_type_selected;

    if (field === 'phone_number') {
      is_valid = isValidPhoneNumber(newValue);
    }
    if (field === 'phone_type') {
      is_type_selected = newValue !== '';
    }

    return {
      is_valid,
      is_type_selected
    };
  };

  const validatePhoneNumbersFormData = phone => {
    return isValidPhoneNumber(phone.phone_number || '') && phone.phone_type !== '';
  };

  const validateEmailsFormData = email => {
    return validateEmail(email.email || '') && email.email_type !== '';
  };

  const validateAddressFormData = address => {
    return address?.contact_country && address?.address_type ? true : false;
  };

  const getPhoneNumberDisabledState = () => {
    let disabled = false;
    phoneNumbers?.forEach(phone => {
      const validate = validatePhoneNumbersFormData(phone);

      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isPhoneDisabled = getPhoneNumberDisabledState();

  const getEmailDisabledState = () => {
    let disabled = false;
    emails?.forEach(email => {
      const validate = validateEmailsFormData(email);

      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isEmailDisbled = getEmailDisabledState();

  const getAddressDisabledState = () => {
    let disabled = false;
    addressDetails?.forEach(address => {
      const validate = validateAddressFormData(address);

      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isAddressDisabled = getAddressDisabledState();

  const getAddress = addressType => {
    const address =
      data &&
      data?.contact_address?.find(el => {
        return el.address_type === addressType;
      });
    // setValue("contact_address", address)

    const addressArray = [address?.address_lines, address?.city, address?.zip_code, address?.contact_country?.name];

    const newAddress = addressArray.filter(item => item !== null && item !== undefined);

    const commaSeparatedAddress = newAddress.join(', ');
    return commaSeparatedAddress;
  };

  const handleChangePhoneNumber = (index, field, value) => {
    let updatedPhoneNumbers = phoneNumbers.map((phoneNumber, i) => {
      if (i === index) {
        const validateFields = validatePhoneNumberAndType(field, value, phoneNumber);
        if (field === 'is_primary') {
          return {
            ...phoneNumber,
            [field]: true,
            ...validateFields
          };
        }
        return {
          ...phoneNumber,
          [field]: value,
          ign_translate_value: value,
          ...validateFields
        };
      }
      if (i !== index && field === 'is_primary') {
        return {
          ...phoneNumber,
          is_primary: false
        };
      }
      return { ...phoneNumber };
    });

    setPhoneNumbers(updatedPhoneNumbers);
  };
  const handleSaveAddress = async () => {
    const updatedAddressDetails = addressDetails.map(address => ({
      ...address,
      errors: {
        address_type: !address.address_type,
        contact_country: !address.contact_country
      }
    }));
    setAddressDetails(updatedAddressDetails);

    if (!isAddressDisabled) {
      try {
        const addressesToSave = updatedAddressDetails.map(address => ({
          id: address.id, // This will be undefined for new addresses
          address_lines: address.address_lines || '',
          address_type: address.address_type || '',
          city: address.city || '',
          contact_id: data?.id,
          country_id: address.contact_country?.id || null,
          is_primary: address.checked || false,
          metropolitan_area: address.metropolitan_area || '',
          state_id: address.state?.id || null,
          zip_code: address.zip_code || ''
        }));
        await updateField('contact_address', addressesToSave);
        setEditing(prevState => ({ ...prevState, contact_address: false }));
      } catch (error) {
        console.error('Error saving address:', error);
      }
    }
  };

  const fetchData = async (index, address, isEdit = false) => {
    try {
      let apiAddress = isEdit ? address : addressDetails[index]?.metropolitan_area || address;
      const res = await LocationSetupApi(GET, apiAddress);
      let city = '';
      let state = '';
      let country = '';
      let location = '';
      const prediction = res?.data?.predictions[0];
      console.log(prediction.description, 'prediction');
      if (prediction && prediction.structured_formatting?.main_text !== 'Undefined') {
        const terms = prediction.terms;
        country = terms[terms.length - 1]?.value || '';
        state = terms[terms.length - 2]?.value || '';
        city = terms[terms.length - 3]?.value || '';
        // location = terms[0]?.value || '';
        location = prediction.description;
      } else {
        city = addressDetails[index]?.city || '';
        state = addressDetails[index]?.state || '';
        country = addressDetails[index]?.contact_country?.name || '';
        location = apiAddress;
      }

      const updatedAddressDetails = addressDetails.map((addressItem, i) => {
        if (i === index) {
          return {
            ...addressItem,
            city,
            state: { name: state },
            contact_country: { name: country },
            metropolitan_area: location,
            address_lines: location
          };
        }
        return addressItem;
      });
      setAddressDetails(updatedAddressDetails);
    } catch (err) {
      console.log('Error fetching data', err);
    }
  };

  useEffect(() => {
    setValue('contact_emails', emails);
    setValue('contact_phones', phoneNumbers);
    if (addressDetails.length > 0) {
      fetchData(0, addressDetails[0]?.metropolitan_area, false);
    }
  }, [phoneNumbers, emails]);
  return (
    <div id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'>
          <Grid container spacing={2} sx={{ padding: '16px' }}>
            <Grid item xs={12}>
              <Box className='section-details m-2'>
                <Box className='communication-section'>
                  <Box className='head-container'>
                    <Box className='d-flex sub-head-content'>
                      <Box className='field-label-input'>
                        <CustomTable
                          headerData={[t(`${TRANS_KEYS.CONTACTS_PHONE_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_PHONE}:key`), t(`${TRANS_KEYS.CONTACTS_IS_PRIMARY}:key`)]}
                          title={t(`${TRANS_KEYS.CONTACTS_ADD_PHONE}:key`)}
                          handleAnotherRow={handleAddPhoneNumber}
                          rows={phoneNumbers}
                          disable={3}
                          isEditing={isEditingPhone}
                          showAddAnotherButton={false}
                          onEditChange={handleEditPhoneChange}
                          customTableId={'view-contact-content'}
                          headerSize={'fs-12'}
                          titleSize={'fs-13'}
                          isDrawer={true}
                          updateField={async (...rest) => {
                            const updatedPhoneNumbers = phoneNumbers.map(phone => {
                              return { ...phone, is_valid: isValidPhoneNumber(phone.phone_number || ''), is_type_selected: phone.phone_type !== '' };
                            });
                            setPhoneNumbers(updatedPhoneNumbers);
                            if (!isPhoneDisabled) {
                              const value = await updateField(...rest);
                              setIsEditingPhone(false);
                              return value;
                            }
                          }}
                          dataList={phoneList}
                          handleCloseClick={handleCloseClick}
                          onEditCancel={() => onEditCancel('contact_phones')}
                          saveDataKey={'contact_phones'}
                          disabledDone={isPhoneDisabled}
                        >
                          {isEditingPhone ? (
                            phoneNumbers?.length === 0 ? (
                              <TableRow>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>-</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>-</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>-</Box>
                                </TableCell>
                              </TableRow>
                            ) : (
                              phoneNumbers?.map((phoneNumber, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align='center' className='p-2'>
                                      <Box className='fs-11'> {phoneNumber?.ign_translate_value?.short_desc || '-'}</Box>
                                    </TableCell>
                                    <TableCell align='center' className='p-2'>
                                      <Box className='fs-11'>
                                        {phoneNumber?.phone_number ? (
                                          <div className=''>
                                            <CustomPhoneInput
                                              className='phone-input-read-only d-inline-block'
                                              id='outlined-basic'
                                              variant='outlined'
                                              size='small'
                                              value={phoneNumber.phone_number}
                                              onChange={() => {}}
                                              disabled={true}
                                            />
                                            <span className='text-truncate'>{formatPhoneNumber(phoneNumber?.phone_number)}</span>
                                          </div>
                                        ) : (
                                          '-'
                                        )}
                                      </Box>
                                    </TableCell>
                                    <TableCell align='center' className='p-2'>
                                      <Box className='fs-11'>{phoneNumber?.is_primary ? IGNYTE_CONSTANTS.Yes : IGNYTE_CONSTANTS.No}</Box>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            )
                          ) : (
                            phoneNumbers?.map((phoneNumber, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell className='viewcontact-icon-style p-2' align='center'>
                                    <PhoneTypeSelection
                                      isDrawer={true}
                                      value={phoneNumber?.ign_translate_value?.short_desc ? phoneNumber?.ign_translate_value?.short_desc : phoneNumber?.phone_type}
                                      onChange={(e, value) => handleChangePhoneNumber(index, 'phone_type', value || '')}
                                      label={t(`${TRANS_KEYS.CONTACTS_PHONE_TYPE}:key`)}
                                      selectedList={phoneNumbers}
                                      setPhoneList={setPhoneList}
                                    />
                                    {!phoneNumber.is_type_selected && <Typography className='invalid-message'>Please select type</Typography>}
                                  </TableCell>
                                  <TableCell align='center' className='p-2'>
                                    <CustomPhoneInput
                                      id='outlined-basic'
                                      variant='outlined'
                                      size='small'
                                      label={t(`${TRANS_KEYS.CONTACTS_PHONE}:key`)}
                                      value={phoneNumber?.phone_number || ''}
                                      onChange={e => handleChangePhoneNumber(index, 'phone_number', e)}
                                    />
                                    <Typography className='invalid-message' style={{ textAlign: 'center' }}>
                                      {!phoneNumber.is_valid && 'Invalid Phone Number'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align='center' className='primary-checkbox p-2'>
                                    <Checkbox
                                      className='checkboxStyle'
                                      checked={phoneNumber?.is_primary}
                                      onChange={e => handleChangePhoneNumber(index, 'is_primary', e.target.checked)}
                                      size='small'
                                      name={`is_primary_${index}`}
                                    />
                                  </TableCell>
                                  {phoneNumbers?.length > 1 && (
                                    <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                      <CloseIcon onClick={() => handleRemovePhoneNumber(index)} className='table-close-icon' />
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })
                          )}
                        </CustomTable>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className='section-details m-2'>
                <Box className='communication-section'>
                  <Box className='d-flex sub-head-content'>
                    <Box className='field-label-input'>
                      <CustomTable
                        disabledDone={isEmailDisbled}
                        headerData={[t(`${TRANS_KEYS.CONTACTS_EMAIL_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_EMAIL}:key`), t(`${TRANS_KEYS.CONTACTS_IS_PRIMARY}:key`)]}
                        title={t(`${TRANS_KEYS.CONTACTS_ADD_EMAIL}:key`)}
                        handleAnotherRow={handleAddEmail}
                        rows={emails}
                        disable={2}
                        isEditing={isEditingEmail}
                        onEditChange={handleEditEmailChange}
                        showAddAnotherButton={false}
                        customTableId={'view-contact-content'}
                        headerSize={'fs-12'}
                        titleSize={'fs-13'}
                        isDrawer={true}
                        dataList={emailList}
                        updateField={async (...rest) => {
                          const updatedemails = emails.map(email => {
                            return { ...email, is_valid: validateEmail(email.email || ''), is_type_selected: email.email_type !== '' };
                          });
                          setEmails(updatedemails);
                          if (!isEmailDisbled) {
                            const value = await updateField(...rest);
                            setIsEditingEmail(false);
                            return value;
                          }
                        }}
                        handleCloseClick={handleCloseClick}
                        onEditCancel={() => onEditCancel('contact_emails')}
                        saveDataKey={'contact_emails'}
                      >
                        {isEditingEmail ? (
                          emails?.length === 0 ? (
                            <TableRow>
                              <TableCell align='center' className='p-2'>
                                <Box className='fs-11'>-</Box>
                              </TableCell>
                              <TableCell align='center' className='p-2'>
                                <Box className='fs-11'>-</Box>
                              </TableCell>
                              <TableCell align='center' className='p-2'>
                                <Box className='fs-11'>-</Box>
                              </TableCell>
                            </TableRow>
                          ) : (
                            emails?.map((email, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell align='center' className='p-2'>
                                    <Box className='fs-11'>{email?.ign_translate_value?.short_desc || '-'}</Box>
                                  </TableCell>
                                  <TableCell align='center' className='p-2'>
                                    <Box className='fs-11'>{email?.email || '-'}</Box>
                                  </TableCell>

                                  <TableCell align='center' className='p-2'>
                                    <Box className='fs-11'>{email?.is_primary ? IGNYTE_CONSTANTS.Yes : IGNYTE_CONSTANTS.No}</Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          )
                        ) : (
                          emails?.map((email, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell className='viewcontact-icon-style p-2' align='center'>
                                  <EmailTypeSelection
                                    required={true}
                                    isView={true}
                                    isDrawer={true}
                                    value={email?.ign_translate_value?.short_desc ? email?.ign_translate_value?.short_desc : email?.email_type}
                                    onChange={(e, value) => handleChangeEmail(index, 'email_type', value || '')}
                                    selectedList={emails}
                                    setEmailList={setEmailList}
                                    label={t(`${TRANS_KEYS.CONTACTS_EMAIL_TYPE}:key`)}
                                    className={'custom-drop-down-size'}
                                  />
                                  {!email.is_type_selected && <Typography className='invalid-message'>Select type</Typography>}
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <CustomInputField
                                    required={true}
                                    value={email?.email || ''}
                                    onChange={e => handleChangeEmail(index, 'email', e.target.value)}
                                    label={t(`${TRANS_KEYS.CONTACTS_EMAIL}:key`)}
                                  />
                                  {!email.is_valid && <Typography className='invalid-message'>Invalid Email</Typography>}
                                </TableCell>
                                <TableCell align='center' className='primary-checkbox p-2'>
                                  <Checkbox
                                    className='checkboxStyle'
                                    checked={email?.is_primary}
                                    onChange={e => handleChangeEmail(index, 'is_primary', e.target.checked)}
                                    size='small'
                                    name={`is_primary_${index}`}
                                  />
                                </TableCell>
                                {emails?.length > 1 && (
                                  <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                    <CloseIcon onClick={() => handleRemoveEmail(index)} className='table-close-icon' />
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })
                        )}
                      </CustomTable>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className='section_details m-2'>
                <Box className='communication-section'>
                  <Box className='d-flex sub-head-content'>
                    <CustomTable
                      title={'Address'}
                      customTableId={'view-company-content'}
                      isShowDoneIconForAddress={isShowDoneIconForAddress}
                      isEditing={isEditingAddress?.workAddress}
                      onEditChange={value => handleEditAddressChange({ ...isEditingAddress, workAddress: value })}
                      handleAnotherRow={() => handleAddAddress('WORK')}
                      rows={addressDetails}
                      disabledDone={isAddressDisabled}
                      updateField={async (...rest) => {
                        const updatedAddressDetails = addressDetails.map(address => {
                          return {
                            ...address,
                            errors: {
                              address_type: !address.address_type,
                              contact_country: !address.contact_country
                            }
                          };
                        });
                        setAddressDetails(updatedAddressDetails);
                        if (!isAddressDisabled) {
                          const value = await updateField(...rest);
                          setEditing(prevState => ({ ...prevState, contact_address: false }));
                          return value;
                        }
                      }}
                      dataList={addressTypeList}
                      showAddAnotherButton={false}
                      saveDataKey={'contact_address'}
                      onEditCancel={() => {
                        onEditCancel('contact_address');
                      }}
                      titleSize={'fs-13'}
                      handleCloseClick={() => handleCloseClick('contact_address')}
                      isDrawer={true}
                      renderActions={!isEditingAddress.workAddress ? renderActions('contact_address', false, handleCloseClick) : null}
                    >
                      {!isEditingAddress?.workAddress ? (
                        <Box className='address-container'>
                          <Box>
                            {addressDetails?.map((element, index) => {
                              return (
                                <Box className='mb-2 mx-2 address' key={index}>
                                  <ExpandableSection
                                    title={`Address ${index + 1}`}
                                    width={'100%'}
                                    showCheckbox={false}
                                    hadleChange={e => {
                                      hadlechangeForCheckbox(e, index);
                                    }}
                                    defaultOpen={true}
                                  >
                                    {addressDetails.length > 1 && (
                                      <Box className='d-flex flex-row-reverse align-items-center'>
                                        <Box>
                                          <MakeConfidential
                                            name='Make Primary'
                                            defaultChecked={element.checked}
                                            handleChange={e => {
                                              hadlechangeForCheckbox(e, index);
                                            }}
                                          />
                                        </Box>
                                        <Box className='pl-3'>
                                          <CloseIcon className='close-icon' cursor='pointer' onClick={() => removeAddress(index)} />
                                        </Box>
                                      </Box>
                                    )}
                                    <Grid container spacing={2}>
                                      <Grid item sm={12} md={12} lg={12} mt={2}>
                                        <Box>
                                          <GeoLocationInput
                                            label={'Address'}
                                            val={element?.metropolitan_area || null}
                                            onChange={(event, location) => {
                                              handleChangeAddressData(index, 'metropolitan_area', location?.description);
                                            }}
                                            className='w-100'
                                            size='small'
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item sm={12} md={6} lg={4}>
                                        <Box className='viewcontact-icon-style'>
                                          <CountrySelection
                                            viewAddress={true}
                                            label={'Country'}
                                            value={element?.contact_country || element?.contact_country?.name || ''}
                                            onChange={(e, data) => {
                                              handleChangeAddressData(index, 'contact_country', data);
                                              setCountry(data?.name);
                                            }}
                                          />
                                          <Typography className='invalid-message'>{element?.errors?.company_country ? 'Please fill this field' : ''}</Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item sm={12} md={6} lg={4}>
                                        <Box className='viewcontact-icon-style'>
                                          <StateSelection
                                            viewAddress={true}
                                            label={t(`${TRANS_KEYS.CONTACTS_STATE}:key`)}
                                            value={element?.state || element?.contact_state?.name || ''}
                                            onChange={(e, data) => {
                                              handleChangeAddressData(index, 'state', data);
                                            }}
                                            country={country}
                                          />
                                        </Box>
                                      </Grid>
                                      <Grid item sm={12} md={6} lg={4}>
                                        <Box>
                                          <CustomInputField
                                            label={'Zip'}
                                            value={element?.zip_code || ''}
                                            onChange={e => {
                                              handleChangeAddressData(index, 'zip_code', e.target.value);
                                            }}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item sm={12} md={6} lg={4} mt={2}>
                                        <Box>
                                          <CustomInputField
                                            label={'City'}
                                            value={element?.city || ''}
                                            onChange={e => {
                                              handleChangeAddressData(index, 'city', e.target.value);
                                            }}
                                          />
                                        </Box>
                                      </Grid>
                                      {/* <Grid item sm={12} md={6} lg={4} mt={2}>
                                        <Box>
                                          <CustomInputField
                                            label={'Address'}
                                            value={element?.address_lines || ''}
                                            onChange={e => {
                                              handleChangeAddressData(index, 'address_lines', e.target.value);
                                            }}
                                          />
                                        </Box>
                                      </Grid> */}
                                      <Grid item sm={12} md={6} lg={4} mt={2}>
                                        <Box className='viewcontact-icon-style'>
                                          <AddressTypeSelection
                                            viewAddress={true}
                                            setAddressTypeList={setAddressTypeList}
                                            label={t(`${TRANS_KEYS.CONTACTS_ADDRESS_TYPE}:key`)}
                                            value={ADDRESS_TYPE[element?.address_type?.toUpperCase()] || element?.address_type || ''}
                                            selectedList={addressDetails}
                                            onChange={(e, data) => {
                                              handleChangeAddressData(index, 'address_type', data?.field_value);
                                            }}
                                          />
                                          <Typography className='invalid-message'>{element?.errors?.address_type ? 'Please fill this field' : ''}</Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </ExpandableSection>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      ) : (
                        <Box className='address-container'>
                          {addressDetails?.length > 0 ? (
                            addressDetails?.map((element, index) => (
                              <Box key={index} className='mb-2 mx-2 address'>
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                  <Typography variant='subtitle1' className='fs-12 mb-1'>
                                    <strong>
                                      {element.address_type === 'residential'
                                        ? 'Residential Address'
                                        : element.address_type === 'work' || element.address_type === 'WORK'
                                          ? 'Work Address'
                                          : element.address_type}
                                    </strong>
                                  </Typography>
                                  {element.checked && (
                                    <Typography variant='subtitle1' className='fs-12 mb-1' style={{ fontStyle: 'italic' }}>
                                      Primary
                                    </Typography>
                                  )}
                                </Box>
                                <Typography variant='body2' className='fs-11'>
                                  {[element.address_lines, element.city, element.state?.name, element.company_country?.name, element.zip_code].filter(Boolean).join(', ')}
                                </Typography>
                              </Box>
                            ))
                          ) : (
                            <Box className='address-container' style={{ marginTop: '2px', marginLeft: '10px' }}>
                              <Typography variant='body2' className='fs-11' style={{ fontWeight: 'bold' }}>
                                Address
                                <br />
                                <Typography variant='body2' className='fs-11' style={{ marginTop: '2px', marginLeft: '4px' }}>
                                  . . .
                                </Typography>
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

ViewPersonalInfo.propTypes = {
  renderActions: PropTypes.func,
  data: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  watch: PropTypes.func,
  handleSave: PropTypes.func,
  defaultValues: PropTypes.object,
  updateField: PropTypes.func
};

export default ViewPersonalInfo;
