import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import DynamicTabLayout from '../../DynamicTabs/TabLayout';
import ActiveProjects from '../ActivityLogsIgnyte/ActivityHistorys/ActiveProjectDetails';
import ActivityHistoryDetail from '../ActivityLogsIgnyte/ActivityHistorys/ActivityHistoryDetail';
import AthenaDecision from './AthenaDecision';
import AthenaScore from './AthenaScore';
import ViewCompetencyScore from './fit-score/ViewCompetencyScore';
import ViewExperience from './fit-score/ViewExperience';
import ViewCompensation from './ViewCompensation';
import ViewDetailsProfile from './ViewDetailsProfile';
import ViewDocuments from './ViewDocuments';
import ViewEducationLanguages from './ViewEducationLanguages';
import ViewPersonalInfo from './ViewPersonalInfo';
import ViewPersonality from './ViewPersonality';
import ViewProfessionalExperience from './ViewProfessionalExperience';

const ContactDetails = props => {
  // const [activeTab, setActiveTab] = useState(0);
  // const [tabIndex, setTabIndex] = useState(0);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_LAYOUT_DETAILS,
    TRANS_KEYS.CONTACTS_PROFILE,
    TRANS_KEYS.CONTACTS_PERSONAL_INFO,
    TRANS_KEYS.CONTACTS_COMMUNICATION,
    TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE,
    TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES,
    TRANS_KEYS.CONTACTS_DOCUMENT,
    TRANS_KEYS.CONTACTS_COMPENSATION,
    TRANS_KEYS.CONTACTS_PERSONALITY,
    TRANS_KEYS.CONTACTS_ENGAGEMENT,
    TRANS_KEYS.CONTACTS_ATHENA_AND_ATTRIBUTE_SELECTOR,
    TRANS_KEYS.CONTACTS_PERSONAL_INFO_AND_COMMUNICATION,
    TRANS_KEYS.CONTACTS_RECENT_ACTIVITY,
    TRANS_KEYS.CONTACTS_PROJECTS,
    TRANS_KEYS.CONTACTS_PROJECTS_AND_ACTIVITIES,
    TRANS_KEYS.ATHENA,
    TRANS_KEYS.SCORES,
    TRANS_KEYS.DECISION_FRAMEWORK,
    TRANS_KEYS.FIT_SCORE,
    TRANS_KEYS.FIT_SCORE_EXPERIENCE_SCORE,
    TRANS_KEYS.FIT_SCORE_COMPETENCY_SCORE
  ]);

  const {
    id: contactId = null,
    /* id, */ data,
    register,
    defaultValues,
    getValues,
    unregister,
    handleSave = () => {},
    setValue = () => {},
    watch = () => {},
    updateField = () => {},
    getContactDetails = () => {},
    isPopupOpen = () => {},
    setIsPopupOpen = () => {},
    onTabChange,
    popupClose,
    isHeaderNav,
    activityDetails,
    getHeaderDetails = () => {},
    baseRoute = '',
    fetchData,
    projectAssociatedData,
    isCandidate = false,
    isLogAnActivityOpen = false,
    fitScoreData = null,
    getFitScoreDetails = () => {},
    project_id = null,
    candidate_id = null,
    tabIndex,
    setTabIndex,
    activeTab,
    setActiveTab
  } = props;

  const TabData = [
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_LAYOUT_DETAILS}:key`)}`,
      content: '',

      subTabs: [
        {
          label: `${t(`${TRANS_KEYS.CONTACTS_PROFILE}:key`)}`,
          content: 'Content for Profile'
        },
        {
          label: `${t(`${TRANS_KEYS.CONTACTS_PERSONAL_INFO_AND_COMMUNICATION}:key`)}`,
          content: 'Content for Personal Info and Communication'
        },
        {
          label: `${t(`${TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE}:key`)}`,
          content: 'Content for Professional Experience'
        },
        {
          label: `${t(`${TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES}:key`)}`,
          content: 'Content for Education and Languages'
        }
      ]
    },
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_COMPENSATION}:key`)}`,
      content: 'Content for Compensation',
      subTabs: []
    },
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_PERSONALITY}:key`)}`,
      content: 'Content for Personality',
      subTabs: []
    },
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_DOCUMENT}:key`)}`,
      content: 'Content for Documents',
      subTabs: []
    },
    {
      label: t('project.projectAndActivities'),
      content: 'Content for Projects and Activities',
      subTabs: [
        {
          label: t('module.projects'),
          content: 'Content for Projects'
        },
        {
          label: `${t(`${TRANS_KEYS.CONTACTS_RECENT_ACTIVITY}:key`)}`,
          content: 'Content for Recent Activity'
        }
      ]
    },
    {
      label: `${t(`${TRANS_KEYS.ATHENA}:key`)}`,
      content: 'Content for Athena',
      subTabs: [
        {
          label: `${t(`${TRANS_KEYS.SCORES}:key`)}`,
          content: 'Content for Scores'
        },
        {
          label: `${t(`${TRANS_KEYS.DECISION_FRAMEWORK}:key`)}`,
          content: 'Content for Decision Framework'
        }
      ]
    }
  ];

  if (isCandidate) {
    TabData.push({
      label: `${t(`${TRANS_KEYS.FIT_SCORE}:key`)}`,
      content: 'Content for Fit Score',
      subTabs: [
        {
          label: `${t(`${TRANS_KEYS.FIT_SCORE_EXPERIENCE_SCORE}:key`)}`,
          content: 'Content for Fit Score - Experience Score'
        },
        {
          label: `${t(`${TRANS_KEYS.FIT_SCORE_COMPETENCY_SCORE}:key`)}`,
          content: 'Content for Fit Score - Competency Score'
        }
      ]
    });
  }

  // const tabData = [
  //   {
  //     label: "Roadmap",
  //     subTabs: [
  //       {
  //         label: `Scope`,
  //       },
  //       {
  //         label: `Experience`,
  //       },
  //       {
  //         label: `Competency`,
  //       },
  //       {
  //         label: `Decisions`,
  //       },
  //     ],
  //   },
  //   {
  //     label: "Candidates",
  //     subTabs: [
  //       {
  //         label: `Product One`,
  //       },
  //       {
  //         label: `Sourced`,
  //       },
  //     ],
  //   },
  //   {
  //     label: "Pipeline",
  //     subTabs: [],
  //   },
  //   {
  //     label: "Overview",
  //     subTabs: [
  //       // {
  //       //   label: `XYZ`,
  //       // },
  //       // {
  //       //   label: `Experience And Leadership`,
  //       // },
  //       // {
  //       //   label: `Decision`,
  //       // },
  //       {
  //         label: `Basic Info`,
  //       },
  //       {
  //         label: `Team Info`,
  //       },
  //       {
  //         label: `Position Profile`,
  //       },
  //     ],
  //   },
  //   // {
  //   //   label: "Insights",
  //   //   subTabs: [
  //   //     {
  //   //       label: `XYZ`,
  //   //     },
  //   //     {
  //   //       label: `Experience And Leadership`,
  //   //     },
  //   //     {
  //   //       label: `Decision`,
  //   //     },
  //   //   ],
  //   // },
  //   {
  //     label: "Billing",
  //     subTabs: [
  //       {
  //         label: `Billing Info`,
  //       },
  //       {
  //         label: `Invoice Info`,
  //       },
  //     ],
  //   },
  //   {
  //     label: "Client Suite",
  //     subTabs: [
  //       {
  //         label: `XYZ`,
  //       },
  //       {
  //         label: `Experience And Leadership`,
  //       },
  //       {
  //         label: `Decision`,
  //       },
  //     ],
  //   },
  //   {
  //     label: "Documents",
  //     subTabs: [],
  //   },
  //   {
  //     label: "Approval",
  //     subTabs: [
  //       {
  //         label: `Pending Approval`,
  //       },
  //       {
  //         label: `Rejected`,
  //       },
  //     ],
  //   },
  // ];

  const handleTabClick = async tabName => {
    onTabChange(tabName);
  };
  const renderActions = (field, required = false, onCloseClick) => {
    return (
      <>
        <Box component='span' className='action-icon' onClick={() => updateField(field, required, onCloseClick)}>
          <DoneIcon className='fs-12' cursor='pointer' color='primary' />
        </Box>
        <Box component='span' className='action-icon' onClick={() => onCloseClick(field)}>
          <CloseIcon className='fs-12' cursor='pointer' color='error' />
        </Box>
      </>
    );
  };

  return (
    <React.Fragment>
      <DynamicTabLayout
        navItems={TabData}
        baseRoute={baseRoute}
        isDrawer={true}
        isDrawerCss={true}
        onTabChange={handleTabClick}
        isHeaderNav={isHeaderNav}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        {data && (
          <ViewDetailsProfile
            index='Details'
            childIndex='Profile'
            renderActions={renderActions}
            data={data}
            register={register}
            unregister={unregister}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            values={defaultValues}
            updateField={updateField}
            isHeaderNav={isHeaderNav}
          ></ViewDetailsProfile>
        )}
        <ViewPersonalInfo
          index='Details'
          childIndex='Communication'
          renderActions={renderActions}
          data={data}
          register={register}
          unregister={unregister}
          watch={watch}
          setValue={setValue}
          values={defaultValues}
          updateField={updateField}
        ></ViewPersonalInfo>
        <ViewProfessionalExperience
          register={register}
          unregister={unregister}
          handleSave={handleSave}
          watch={watch}
          setValue={setValue}
          contactData={data}
          index='Details'
          childIndex='Professional Experience'
          renderActions={renderActions}
          getContactDetails={getContactDetails}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        ></ViewProfessionalExperience>
        <ViewEducationLanguages
          index='Details'
          data={data}
          childIndex='Education and Languages'
          register={register}
          unregister={unregister}
          watch={watch}
          setValue={setValue}
          values={defaultValues}
          updateField={updateField}
          handleSave={handleSave}
          getContactDetails={getContactDetails}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        ></ViewEducationLanguages>
        <ViewCompensation
          index='Compensation'
          renderActions={renderActions}
          data={data}
          register={register}
          unregister={unregister}
          watch={watch}
          setValue={setValue}
          defaultValues={defaultValues}
          updateField={updateField}
          isHeaderNav={isHeaderNav}
        ></ViewCompensation>
        <ViewPersonality
          currentValues={data}
          updateField={updateField}
          isContactView={true}
          index='Personality'
          setValue={setValue}
          register={register}
          unregister={unregister}
          getContactDetails={getContactDetails}
          popupClose={popupClose}
          isHeaderNav={isHeaderNav}
        ></ViewPersonality>
        <ViewDocuments
          index='Documents'
          data={data}
          setValue={setValue}
          register={register}
          unregister={unregister}
          getContactDetails={getContactDetails}
          isHeaderNav={isHeaderNav}
          getHeaderDetails={getHeaderDetails}
        ></ViewDocuments>
        <ActiveProjects index={t('project.projectAndActivities')} childIndex={t('module.projects')} rowData={projectAssociatedData} fetchData={fetchData} contactId={contactId}></ActiveProjects>
        <ActivityHistoryDetail index={t('project.projectAndActivities')} childIndex='Recent Activity' activityDetails={activityDetails} isHeaderNav={props.isHeaderNav}></ActivityHistoryDetail>
        <AthenaScore index='Athena' childIndex='Scores' data={data} />
        <AthenaDecision index='Athena' childIndex='Decision Framework' data={data} />
        <ViewExperience
          index='Fit Score'
          childIndex='Experience Score'
          isLogAnActivityOpen={isLogAnActivityOpen}
          experience_data={fitScoreData?.experience ?? []}
          project_id={project_id}
          candidate_id={candidate_id}
          getFitScoreDetails={getFitScoreDetails}
        />
        <ViewCompetencyScore
          getFitScoreDetails={getFitScoreDetails}
          index='Fit Score'
          childIndex='Competency Score'
          competency_data={fitScoreData?.competencies ?? []}
          project_id={project_id}
          candidate_id={candidate_id}
        />
      </DynamicTabLayout>
    </React.Fragment>
  );
};

ContactDetails.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  watch: PropTypes.func,
  isHeaderNav: PropTypes.string,
  handleSave: PropTypes.func,
  defaultValues: PropTypes.object,
  updateField: PropTypes.func,
  getContactDetails: PropTypes.func,
  isPopupOpen: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  onTabChange: PropTypes.func,
  popupClose: PropTypes.bool,
  activityDetails: PropTypes.object,
  getHeaderDetails: PropTypes.func,
  baseRoute: PropTypes.string,
  fetchData: PropTypes.func,
  projectAssociatedData: PropTypes.object,
  isCandidate: PropTypes.bool,
  isLogAnActivityOpen: PropTypes.bool,
  fitScoreData: PropTypes.object,
  getFitScoreDetails: PropTypes.func,
  project_id: PropTypes.string,
  candidate_id: PropTypes.string,
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func
};
export default ContactDetails;
