import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDate } from '../../../../utils/date';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import delete_icon from '../../../../assets/icons/delete_icon.svg';
import CompanyImageAvatar from '../../../../Containers/Searches/CompanyImageAvatar';

const ExperienceCard = props => {
  const {
    index,
    editItems = () => {},
    data,
    handleDeleteClick
    // children
  } = props;

  let address = [data?.job_history_city || data?.city, data?.job_history_state || data?.state?.name, data?.job_history_country || data?.country?.name];
  address = address.filter(item => item !== null && item !== undefined && item !== '').join(', ');
  let tenure = data?.is_present ? `${formatDate(data?.start_date, 'MMM YYYY')} to Present` : `${formatDate(data?.start_date, 'MMM YYYY')} - ${formatDate(data?.end_date, 'MMM YYYY')}`;

  const handleEdit = () => {
    editItems(index);
  };

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          '& > *:first-child': { borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' },
          '& > *:last-child': { borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }
        }}
      >
        <TableCell
          sx={{
            width: '30%',
            fontWeight: 'bold',
            padding: '12px 16px',
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '10%',
              right: 0,
              height: '80%',
              width: '1px',
              backgroundColor: '#e0e0e0'
            }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '4px' }}>
            <CompanyImageAvatar
              id={data?.company?.image_id}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '50%'
              }}
            />
            <Box sx={{ marginLeft: '8px' }}>
              <Box sx={{ fontWeight: 'bold' }}>{data?.title || ''}</Box>
              <Box sx={{ fontSize: '0.75rem', color: '#757575' }}>{data?.company?.name}</Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell sx={{ fontSize: '0.875rem', fontWeight: 'normal', padding: '12px 16px' }}>
          <CalendarTodayIcon fontSize='small' sx={{ marginRight: '4px', verticalAlign: 'middle' }} />
          {`${tenure}`}
        </TableCell>
        <TableCell sx={{ fontSize: '0.875rem', fontWeight: 'normal', padding: '12px 16px' }}>
          <LocationOnIcon fontSize='small' sx={{ marginRight: '4px', verticalAlign: 'middle' }} />
          {`${address}`}
        </TableCell>
        <TableCell sx={{ fontSize: '0.875rem', fontWeight: 'normal', padding: '12px 16px' }}>
          {data.board_is_present && (
            <>
              <WorkIcon fontSize='small' sx={{ marginRight: '4px', verticalAlign: 'middle' }} />
              Board Member
            </>
          )}
        </TableCell>
        <TableCell sx={{ padding: '12px 16px', display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title={data?.description?.replace(/<[^>]+>/g, '')} placement='right'>
            <IconButton size='small' sx={{ marginRight: '4px' }}>
              <InfoIcon fontSize='small' sx={{ color: '#757575' }} />
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => handleEdit(data)} className='green-bg edit-icon mr-1 p-1'>
            <EditIcon className='img'></EditIcon>
          </IconButton>
          <IconButton className='delete-icons pr-2 pt-0 mt-1' onClick={() => handleDeleteClick(data)}>
            <img src={delete_icon} alt='' />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
ExperienceCard.propTypes = {
  addIcon: PropTypes.string,
  editItems: PropTypes.func,
  deleteItem: PropTypes.func,
  data: PropTypes.object,
  styleData: PropTypes.object,
  children: PropTypes.element,
  index: PropTypes.number,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func,
  handleDeleteClick: PropTypes.func
};

export default ExperienceCard;
