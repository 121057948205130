//-----------------------------------------------------------// In-built Imports // ------------------------------

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SUCCESS_STATUS_CODE } from '../../Containers/Contacts/utils';

//-----------------------------------------------------------// External Imports // ------------------------------

import i18nData from '../../i18n';
import { getUserSelector } from '../../selectors';
import { getBackendNamespaces } from './utils';
/* import { IGN_API } from "../../services/constantService";
import httpService from "../../services/httpService"; */
export const useLanguageHooks = (namespaceList = []) => {
  const { t, i18n } = useTranslation();
  const [tReady, setTReady] = useState(false);
  const userData = useSelector(getUserSelector);
  /* lazy load namespace when component load  */
  useEffect(() => {
    const handleSetTranslation = async () => {
      try {
        const lang = 'en';
        // /* set (user or tenant) language from userData */

        /* filter unload loaded namespaces */
        let unloadNamespaces = namespaceList.filter(ele => {
          return i18nData.hasLoadedNamespace(ele, lang) ? false : true;
        });

        if (unloadNamespaces.length) {
          /* Enable below line once api is ready */
          /* const { data, status } = await httpService.post(`${IGN_API.language_translations}/resources`, { lang, namespaces: unloadNamespaces }) */

          /* Remove below line once api is ready */
          const { data, status = 200 } = getBackendNamespaces({
            lang,
            namespaces: unloadNamespaces
          });

          if (status === SUCCESS_STATUS_CODE) {
            data.forEach(element => {
              i18nData.addResourceBundle(lang, element.key, {
                key: element.value
              });
            });
          }
        }
        setTReady(true);
        /* set (user or tenant) language from userData */
        // await i18n.changeLanguage(lang);
      } catch (err) {
        console.log('error in load namespace, error:: ', err);
      }
    };
    if (userData) {
      handleSetTranslation();
    }
  }, [userData]);
  return { t, tReady };
};
