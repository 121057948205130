export const TRANS_KEYS = {
  ADD_CONTACT_DETAILS_PROFILE_TITLE: 'add-contact-details-profile-title',
  ACTIVITY: 'activity',
  CONTACT_OR: 'contact-or',
  CONTACTS_ABOUT: 'contacts-about',
  CONTACTS_ACTION: 'contacts-action',
  CONTACTS_ACTIVE: 'contacts-active',
  CONTACTS_ACTIVITY_LOG: 'contacts-activity-log',
  CONTACTS_ACTIVITY_TYPE: 'contacts-activity-type',
  CONTACTS_ADD_ANOTHER_ACTIVITY: 'contacts-add-another-activity',
  CONTACTS_ADD_ANOTHER: 'contacts-add-another',
  CONTACTS_ADD_CONTACT_TO_PROJECT: 'contact-add-contact-to-project',
  CONTACTS_EDIT: 'contact-edit',
  CONTACT_CREATE_FROM_RESUME: 'contact-create-from-resume',
  CONTACTS_ADD_CONTACT: 'contacts-add-contact',
  CONTACTS_ADD_DESCRIPTION: 'contacts-add-description',
  CONTACTS_ADD_DOCUMENT: 'contacts-add-document',
  CONTACTS_ADD_EDUCATION_DETAILS: 'contacts-add-education-experience',
  CONTACTS_ADD_EMAIL: 'contacts-add-email',
  CONTACTS_ADD_LANGUAGES: 'contacts-add-languages',
  CONTACTS_ADD_NAME: 'contacts-add-name',
  CONTACTS_ADD_PHONE: 'contacts-add-phone',
  CONTACTS_ADD_TO_LIST: 'contacts-add-to-List',
  CONTACTS_ADD_TO_PROJECT: 'contacts-add-to-project',
  CONTACTS_ADD_TO: 'contacts-add-to',
  CONTACTS_ADD_WORK_EXPERIENCE: 'contacts-add-work-experience',
  CONTACTS_ADDED_BY: 'contacts-added-by',
  CONTACTS_ADDRESS_TYPE: 'contacts-address-type',
  CONTACTS_ADDRESS: 'contacts-address',
  CONTACTS_ALL_CONTACTS: 'contacts-all-contacts',
  CONTACTS_APPROVED_TO_PUBLISH: 'contacts-approved-to-publish',
  CONTACTS_ASSIGN_TAG: 'contacts-assign-tag',
  CONTACTS_TRY_ATHENA: 'contacts-try-athena',
  CONTACTS_ATHENA_AND_ATTRIBUTE_SELECTOR: 'contacts-athena-and-attribute-selector',
  CONTACTS_PROJECTS_AND_ACTIVITIES: 'contacts-projects-and-activities',
  CONTACTS_BASE_SALARY: 'contacts-base-salary',
  CONTACTS_BASIC_INFO: 'contacts-basic-info',
  CONTACTS_BOARD_MEMBER: 'contacts-board-member',
  CONTACTS_BONUS_TYPE: 'contacts-bonus-type',
  CONTACTS_BONUS_VALUE: 'contacts-bonus-value',
  CONTACTS_BONUS: 'contacts-bonus',
  CONTACTS_CALL: 'contacts-call',
  CONTACTS_CANCEL: 'contacts-cancel',
  CONTACTS_CHOOSE_PROJECT_NUMBER: 'contact-choose-project-number',
  CONTACTS_CHOOSE_PROJECT: 'contact-choose-project',
  CONTACTS_CHOOSE_STAGE: 'contact-choose-stage',
  CONTACTS_CITY: 'contacts-city',
  CONTACTS_COMMITTEE_DETAILS: 'contacts-committee-details',
  CONTACTS_COMMUNICATION: 'contacts-communication',
  CONTACTS_COMPANY_NAME: 'contacts-company-name',
  CONTACTS_COMPANY: 'contacts-company',
  CONTACTS_COMPENSATION_INFO: 'contacts-compensation-info',
  CONTACTS_COMPENSATION_NOTES: 'contacts-compensation-notes',
  CONTACTS_COMPENSATION_RELEASE: 'contacts-compensation-release',
  CONTACTS_COMPENSATION: 'contacts-compensation',
  CONTACTS_CONCERN: 'contacts-concern',
  CONTACTS_CONTACT_DETAILS: 'contacts-contact-details',
  CONTACTS_CONTACT_NAME: 'contacts-contact-name',
  CONTACTS_COUNTRY: 'contacts-country',
  CONTACTS_CREATE_CONTACT: 'contacts-create-contact',
  CONTACTS_CREATE_FROM_RESUME: 'contacts-create-from-resume',
  CONTACTS_CURRENCY_CODE: 'contacts-currency-code',
  CONTACTS_CURRENTLY_ENROLLED: 'contacts-currently-enrolled',
  CONTACTS_DATE_OF_BIRTH: 'contacts-date-of-birth',
  CONTACTS_DESCRIPTION: 'contacts-description',
  CONTACTS_DOCUMENT_TYPE: 'contacts-document-type',
  CONTACTS_DOCUMENT: 'contacts-document',
  CONTACTS_EDUCATION_AND_LANGUAGES: 'contacts-education-and-languages',
  CONTACTS_EDUCATION: 'contacts-education',
  CONTACTS_EMAIL_TYPE: 'contacts-email-type',
  CONTACTS_EMAIL: 'contacts-email',
  CONTACTS_ENGAGEMENT: 'contacts-engagement',
  CONTACTS_EXPECTED_COMPENSATION: 'contacts-expected-compensation',
  CONTACTS_EXPORT_LIST: 'contacts-export-list',
  CONTACTS_FIELD_OF_STUDY: 'contacts-field-of-study',
  CONTACTS_FILE_NAME: 'contacts-file-name',
  CONTACTS_FILTER_BY: 'contacts-filter-by',
  CONTACTS_FILTERS: 'contacts-filter',
  CONTACTS_FIRST_NAME: 'contacts-first-name',
  CONTACTS_FROM_YEAR: 'contacts-from-year',
  CONTACTS_GENDER: 'contacts-gender',
  CONTACTS_HIGHLIGHTS: 'contacts-highlights',
  CONTACTS_INDUSTRY_AND_JOB_FUNCTION: 'contacts-industry-and-job-function',
  CONTACTS_INDUSTRY: 'contacts-industry',
  CONTACTS_INTERACTION: 'contacts-interaction',
  CONTACTS_INVITE_TO_ATHENA: 'contacts-invite-to-athena',
  CONTACTS_IS_PRIMARY: 'contacts-is-primary',
  CONTACTS_JOB_FUNCTION: 'contacts-job-function',
  CONTACTS_JOB_TITLE_AND_COMPANY: 'contacts-job-title-and-company',
  CONTACTS_LEVEL_OF_EDUCATION_AND_FIELD_OF_STUDY: 'contacts-level-of-education-and-field-of-study',
  CONTACTS_JOB_TITLE: 'contacts-job-title',
  CONTACTS_LANGUAGES: 'contacts-languages',
  CONTACTS_LANGUAGE: 'contacts-language',
  CONTACTS_LAST_CONTACT: 'contacts-last-contact',
  CONTACTS_LAST_NAME: 'contacts-las-name',
  CONTACTS_LAYOUT_DETAILS: 'contacts-layout-details',
  CONTACTS_LEVEL_OF_EDUCATION: 'contacts-level-of-education',
  CONTACTS_LEVEL_OF_EXPERTISE: 'contacts-level-of-expertise',
  CONTACTS_LOCATION: 'contacts-location',
  CONTACTS_LOG_AN_ACTIVITY: 'contacts-log-an-activity',
  CONTACTS_LTI: 'contacts-lti',
  CONTACTS_MAKE_CONFIDENTIAL: 'contacts-make-confidential',
  CONTACTS_MAKE_PRIMARY: 'contacts-make-primary',
  CONTACTS_MARK_NOTE_AS: 'contacts-mark-note-as',
  CONTACTS_MESSAGES: 'contacts-messages',
  CONTACTS_METROPOLITAN_AREA: 'contacts-metropolitan-area',
  CONTACTS_MORE_FILTERS: 'contacts-more-filters',
  CONTACTS_NAME: 'contacts-name',
  CONTACTS_NOTES: 'contacts-notes',
  CONTACTS_ADD_NOTE_FOR: 'contacts-add-note-for',
  CONTACTS_ADD_TASK_FOR: 'contacts-add-task-for',
  CONTACTS_PENDING_TASK: 'contacts-pending-task',
  CONTACTS_TASK: 'contacts-task',
  CONTACTS_OR: 'contacts-or',
  CONTACTS_OUT_OF: 'contacts-out_of',
  CONTACTS_PASTE_LINKEDIN_URL: 'contacts-paste-linkedin-url',
  CONTACTS_PERSONAL_INFO_AND_COMMUNICATION: 'contacts-personal-info-and-communication',
  CONTACTS_PERSONAL_INFO: 'contacts-personal-info',
  CONTACTS_PERSONAL_NOTE: 'contacts-personal-notes',
  CONTACTS_PERSONALITY: 'contacts-personality',
  CONTACTS_PHONE_TYPE: 'contacts-phone-type',
  CONTACTS_PHONE: 'contacts-phone',
  CONTACTS_PHONE_NUMBER: 'contacts-phone-number',
  CONTACTS_ADD_NEW_CONTACT: 'contacts-add-new-contact',
  CONTACTS_PRIVATE_CONTACT: 'contacts-private-contact',
  CONTACTS_PROFESSIONAL_EXPERIENCE: 'contacts-professional-experience',
  CONTACTS_EXPERIENCE: 'contacts-experience',
  CONTACTS_PROFESSIONAL_INFO: 'contacts-professional-info',
  CONTACTS_PROFESSIONAL: 'contacts-professional',
  CONTACTS_PROFILE: 'contacts-profile',
  CONTACTS_PROJECT_NUMBER: 'contacts-project-number',
  CONTACTS_PROJECT: 'contacts-project',
  CONTACTS_PROJECTS: 'contacts-projects',
  CONTACTS_PUBLISHED_BIO: 'contacts-published-bio',
  CONTACTS_RECENT_ENGAGEMENT: 'contacts-recent-engagement',
  CONTACTS_RECOMMENDATION: 'contacts-recommendation',
  CONTACTS_RECORD_TYPE: 'contacts-record-type',
  CONTACTS_RELATION_TYPE: 'contacts-relation-type',
  CONTACTS_RELATIONSHIP: 'contacts-relationship',
  CONTACTS_RESET_FILTERS: 'contacts-reset-filters',
  CONTACTS_SAVE_AS: 'contacts-save-as',
  CONTACTS_SAVE_FILTERS: 'contacts-save-filters',
  CONTACTS_SAVE_LIST: 'contacts-save-list',
  CONTACTS_SAVE: 'contacts-save',
  CONTACTS_SCHOOL_NAME: 'contacts-school-name',
  CONTACTS_SCREENED: 'contacts-screened',
  CONTACTS_SCREENING_NOTE: 'contacts-screening-notes',
  CONTACTS_SEARCH_CONTACT: 'contacts-search-contact',
  CONTACTS_SEARCH: 'contacts-search',
  CONTACTS_SELECT_DATA: 'contacts-select-data',
  CONTACTS_SELECT_FILE_TYPE: 'contacts-select-file-type',
  CONTACTS_SELECT_FILTERS: 'contacts-select-filters',
  CONTACTS_SELECT_PROJECT: 'contacts-select-project',
  CONTACTS_SELECT_TAG: 'contacts-select-tag',
  PROJECTS_SELECT_TAG: 'projects-select-tag',
  CONTACTS_SELECT_TYPE: 'contacts-select-type',
  CONTACTS_SELECT: 'contacts-select',
  CONTACTS_SELECTED: 'contacts-selected',
  CONTACTS_SEND_MAIL: 'contacts-send-mail',
  CONTACTS_SHARE_WITH_IGNYTE_USER: 'contacts-share-with-ignyte-user',
  CONTACTS_SHARE: 'contacts-share',
  CONTACTS_SOURCE: 'contacts-source',
  CONTACTS_STATE: 'contacts-state',
  CONTACTS_STRENGTH: 'contacts-strength',
  CONTACTS_SUBJECT: 'contacts-subject',
  CONTACTS_TAGS: 'contacts-tags',
  CONTACTS_THEY_ARE_A_BOARD_MEMBERS: 'contacts-they-are-a-board-member',
  CONTACTS_THEY_CURRENTLY_WORK_HERE: 'contacts-they-currently-work-here',
  CONTACTS_TIME_PERIOD: 'contacts-time-period',
  CONTACTS_TO_SAVE: 'to Save',
  CONTACTS_TO_YEAR: 'contacts-to-year',
  CONTACTS_TOTAL_COUNT: 'contacts-total-count',
  CONTACTS_UPDATE_DOCUMENT: 'contacts-update-document',
  CONTACTS_UPDATE_EDUCATION_DETAILS: 'contacts-update-education-experience',
  CONTACTS_UPDATE: 'contact-update',
  CONTACTS_UPDATED_BY: 'contacts-updated-by',
  CONTACTS_UPLOAD_FILE: 'contacts-upload-file',
  CONTACTS_UPLOAD_RESUME: 'contacts-upload-resume',
  CONTACTS_VIEW_ACTIVITY_LOG: 'contacts-view-activity-log',
  CONTACTS_VIEW_RESUME: 'contacts-view-resume',
  CONTACTS_DELETE_CONTACT: 'contacts-delete-contact',
  CONTACTS_VIEW_LINKEDIN: 'contacts-view-linkedin',
  CONTACTS_WORD_ADD: 'contacts-word-add',
  CONTACTS_WORD_AND: 'contacts-word-and',
  CONTACTS_WORD_FOR: 'contacts-for',
  CONTACTS_WORD_FROM: 'contacts-word-from',
  CONTACTS_WORD_ON: 'contacts-word-on',
  CONTACTS_WORD_TO: 'contacts-word-to',
  CONTACTS_WORD_YOU: 'contacts-you',
  CONTACTS_WORK_ADDRESS: 'contacts-work-address',
  CONTACTS_RESIDENTIAL_ADDRESS: 'contacts-residential-address',
  CONTACTS_WORK_EXPERIENCE: 'contacts-work-experience',
  CONTACTS_ZIP: 'contacts-zip',
  ECHO_SENSE: 'echo-sense',
  HEADERS_ADD_TO_FAVORITE: 'headers-add-to-favorite',
  HEADERS_CALENDAR: 'headers-calendar',
  HEADERS_HELP: 'headers-help',
  HEADERS_MASTER_SEARCH: 'headers-master-search',
  HEADERS_NOTIFICATIONS: 'headers-notifications',
  SIDENAV_COMPANIES: 'sidenav-companies',
  SIDENAV_CONTACTS: 'sidenav-contacts',
  SIDENAV_SEARCHES: 'sidenav-searches',
  SIDENAV_SETUP: 'sidenav-setup',
  SIDENAV_USERS: 'sidenav-users',
  SIDENAV_WORKBENCHES: 'sidenav-workbenches',
  CONTACTS_SHOW_IN: 'show-in',
  CONTACTS_RECENT_ACTIVITIES: 'recent-activities',
  CONTACTS_RECENT_ACTIVITY: 'recent-activity',
  REPORT_BUILDER_PROJECT: 'report-builder-project-number',
  REPORT_BUILDER_CANDIDATE: 'report-builder-contact',
  REPORT_BUILDER_REPORT: 'report-builder-report-type',
  REPORT_BUILDER_TEMPLATE: 'report-builder-template',
  REPORT_BUILDER_LANGUAGE: 'report-builder-language',
  REPORT_BUILDER_FILE_FORMAT: 'report-builder-file-format',
  REPORT_BUILDER_SELECT_PIPELINE: 'report-builder-select-pipeline',
  REPORT_BUILDER_GENERATE_AND_DOWNLOAD: 'report-builder-generate-download',
  REPORT_BUILDER_REPORTS: 'report-builder-reports',
  ADD_PROJECT: 'add-project',
  ADD_PROJECT_INPUT_PROJECT: 'add-project-input-project',
  ADD_PROJECT_SELECT_SERVICE_OFFERING: 'add-project-select-service-offering',
  ADD_PROJECT_SELECT_COMPANY_NAME: 'add-project-select-company-name',
  ADD_PROJECT_SELECT_JOB_TITLE: 'add-project-select-job-title',
  ADD_PROJECT_SELECT_INDUSTRY: 'add-project-select-industry',
  ADD_PROJECT_SELECT_JOB_TYPE: 'add-project-select-job-type',
  ADD_PROJECT_SELECT_LOCATION: 'add-project-select-location',
  ADD_PROJECT_INPUT_PROJECT_OWNER: 'add-project-input-project-owner',
  ADD_PROJECT_INPUT_EXPERIENCE_FROM: 'add-project-input-experience-from',
  ADD_PROJECT_INPUT_EXPERIENCE_TO: 'add-project-input-experience-to',
  ADD_PROJECT_INPUT_MIN_COMPENSATION: 'add-project-input-min-compensation',
  ADD_PROJECT_INPUT_MAX_COMPENSATION: 'add-project-input-max-compensation',
  ADD_PROJECT_INPUT_ADDITIONAL_INFORMATION: 'add-project-input-additional-information',
  ADD_PROJECT_SELECT_CLIENT_CONTACT_DETAILS: 'add-project-select-client-contact-details',
  ADD_PROJECT_SELECT_HIRING_MANAGER: 'add-project-select-hiring-manager',
  ADD_PROJECT_TITLE_SKILLS: 'add-project-title-skills',
  ADD_PROJECT_TITLE_COMPETENCIES: 'add-project-title-competencies',
  ADD_PROJECT_LABEL_TARGET_INDUSTRIES: 'add-project-label-target-industries',
  ADD_PROJECT_LABEL_TARGET_LOCATION: 'add-project-label-target-location',
  ADD_PROJECT_LABEL_TARGET_TITLE: 'add-project-label-target-title',
  ADD_PROJECT_LABEL_INDUSTRY: 'add-project-label-industry',
  ADD_PROJECT_LABEL_SUB_INDUSTRY: 'add-project-label-sub-industry',
  ADD_PROJECT_LABEL_WEIGHT: 'add-project-label-weight',
  ADD_PROJECT_BUTTON_ADD_INDUSTRY: 'add-project-button-add-industry',
  ADD_PROJECT_LABEL_COUNTRY: 'add-project-label-country',
  ADD_PROJECT_LABEL_STATE: 'add-project-label-state',
  ADD_PROJECT_LABEL_TITLE: 'add-project-label-title',
  ADD_PROJECT_LABEL_REVENUE_RANGE: 'add-project-label-revenue-range',
  ADD_PROJECT_LABEL_EMPLOYEE_RANGE: 'add-project-label-employee-range',
  ADD_PROJECT_LABEL_TARGET_COMPANIES: 'add-project-label-target-companies',
  ADD_PROJECT_LABEL_TARGET_FUNCTION: 'add-project-label-target-function',
  ADD_PROJECT_LABEL_TARGET_CAPITAL_STRUCTURE: 'add-project-label-target-capital-structure',
  ADD_PROJECT_LABEL_MIN_YEARS_OF_EXPERIENCE: 'add-project-label-min-years-of-experience',
  ADD_PROJECT_LABEL_MAX_YEARS_IN_CURRENT_COMPANY: 'add-project-label-max-years-in-current-company',
  ADD_PROJECT_BUTTON_ADD_LOCATION: 'add-project-button-add-location',
  ADD_PROJECT_BUTTON_ADD_TITLE: 'add-project-button-add-title',
  ADD_PROJECT_LABEL_HIDE_IN_JOB_POSTING: 'add-project-label-hide-in-job-posting',
  ADD_PROJECT_BUTTON_REVIEW_AND_PUBLISH: 'add-project-button-review-and-publish',
  ADD_PROJECT_BUTTON_ADD_SKILL: 'add-project-button-add-skill',
  ADD_PROJECT_BUTTON_ADD_COMPETENCY: 'add-project-button-add-competency',
  SAVE_AND_CONTINUE: 'save-and-continue',
  ADD_PROJECT_BUTTON_CREATE_PROJECT: 'add-project-button-create-project',
  UPDATE_PROJECT_BUTTON_CREATE_PROJECT: 'update-project-button-create-project',
  BACK: 'back',
  ADD_PROJECT_LABEL_COMPENSATION_FREQUENCY: 'add-project-label-compensation-frequency',
  ADD_PROJECT_LABEL_CURRENCY_TYPE: 'add-project-label-currency-type',
  ADD_PROJECT_SUCCESS_MODAL_HEADER: 'add-project-success-modal-header',
  ADD_PROJECT_SUCCESS_MODAL_SUBTITLE: 'add-project-success-modal-subtitle',
  ADD_PROJECT_SUCCESS_MODAL_SUBTITLE_SUCCESS: 'add-project-success-modal-subtitle-success',
  ADD_PROJECT_SUCCESS_MODAL_BUTTON: 'add-project-success-modal-button',
  ADD_PROJECT_GENERATE_MESSAGE: 'add-project_generate_message',
  ALL_PROJECTS: 'all-projects',
  VIEW_PROJECTS_SCOPE_HEADER_TEXT: 'view-projects_scope_header_text',
  ADD_COMPANY: 'add-company',
  POSITION_PROFILE: 'position-profile',
  CUSTOM_RECOMMENDATION_SELECTOR_HELPER: 'custom-recommendation-selector-helper',
  SHOW_MORE: 'show-more',
  SAVE_AND_NEXT: 'save-and-next',
  EXPAND_ALL: 'expand-all',
  COLLAPSE_ALL: 'collapse-all',
  MINIMAL_TITLE: 'minimal-title',
  IDEAL_TITLE: 'ideal-title',
  QUESTION_TITLE: 'question-title',
  ADD_EXPERIENCE: 'add-experience',
  ADD_ELEMENT: 'add-element',
  PUBLISHED_BUTTON: 'published-button',
  UNPUBLISHED_BUTTON: 'unpublished-button',
  SEARCH_ROAD_MAP: 'search-road-map',
  OPEN_BUTTON: 'open-button',
  NO_OF_DAYS: 'no-of-days',
  SEARCH_HEALTH: 'search-health',
  ADD_CANDIDATE: 'add-candidate',
  SEARCH_STATUS_REPORT: 'search-status-report',
  VIEW_REPORTS: 'view-reports',
  LEADERSHIP_TITLE: 'leadership-title',
  ADD_COMPETENCY: 'add-competency',
  MINIMUM: 'minimum',
  MAXIMUM: 'maximum',
  ADD_TARGET_INDUSTRIES: 'add-target-industries',
  ADD_TARGET_COMPANIES: 'add-target-companies',
  ADD_COMPANIES_TO_AVOID: 'add-companies-to-avoid',
  ADD_TARGET_TITLE: 'add_target_title',
  ADD_GEOLOCATION: 'add-geolocation',
  ADD_TARGET_FUNCTION: 'add-target-function',
  ADD_CAPITAL_STRUCTURE: 'add-capital-structure',
  EQUITY: 'equity',
  TOTAL_BASE_AND_BONUS: 'total-base-and-bonus',
  MANDATORY: 'mandatory',
  CERTIFICATION: 'certification',
  ADD_CERTIFICATION: 'add-certification',
  CONTACT_CERTIFICATION_AND_LICENSE: 'contact-certification-and-license',
  ADD_CERTIFICATION_AND_LICENSE: 'add-certification-and-license',
  CONTACT_CERTIFICATION: 'contact-certification',
  CERTIFICATION_YEARS: 'contact-certification-year',
  CERTIFICATION_TYPE: 'contact-certification-type',
  CERTIFICATION_EXPIRE_ON: 'contact-certification-expire-on',
  CERTIFICATION_TITLE: 'contact-certification-title',
  GENERATE: 'generate',
  RE_GENERATE: 're-generate',
  HELIA_BUILD_ROAD_MAP_TEXT: 'helia-building-road-map-text',
  SOMETHING_WENT_WRONG: 'something-went-wrong',
  DUPLICATE_RECORD_DETECTED: 'duplicate_record_detected',
  EXACT_MATCH_IDENTIFIED: 'exact_match_identified',
  EXACT_MATCH_IDENTIFIED_VIA: 'exact_match_identified_via',
  POTENTIAL_MATCH_IDENTIFIED: 'potential_match_identified',
  TAGS_SETUP_TAG_NAME: 'tag-name',
  TAGS_SETUP_COLOUR_LABEL: 'colour-label',
  TAGS_SETUP_MODULE: 'module',
  TAGS_SETUP_STATUS: 'status',
  OFF_LIMIT_VIEW_REASONS: 'view-reasons',
  OFF_LIMITS_CAUSE: 'off-limit-cause',
  OFF_LIMITS_REASONS: 'off-limit-reasons',
  OFF_LIMIT_DETAILS: 'off-limit-details',
  TAGS_SETUP_ADD_TAGS: 'add-tags',
  TAGS_SETUP_TAGS_SETTING: 'tags-setting',
  DELETE: 'delete',
  TAGS_SETUP_TAGS_SETUP: 'tags-setup',
  TAGS_SETUP_TAGS_CONTENT: 'tags-setup-content',
  TRANSLATE_VALUE_SETUP: 'translate-value-setup',
  TRANSLATE_VALUE_CONTENT: 'translate-value-setup-content',
  TRANSLATE_VALUE_ADD_VALUE: 'translate-value-add-translate-value',
  TRANSLATE_VALUE_ADD_VALUES: 'translate-value-add-translate-values',
  TRANSLATE_VALUE_EDIT_VALUE: 'translate-value-edit-translate-value',
  TRANSLATE_VALUE_FIELD_VALUE: 'translate-value-field-value',
  TRANSLATE_VALUE_FIELD_NAME: 'translate-value-field-name',
  TRANSLATE_VALUE_LANG_CD: 'translate-value-lang-cd',
  TRANSLATE_VALUE_LANGUAGE: 'translate-value-language',
  TRANSLATE_VALUE_LANG: 'translate-value-lang-cd',
  TRANSLATE_VALUE_SHORT_DESC: 'translate-value-short-desc',
  TRANSLATE_VALUE_LONG_DESC: 'translate-value-long-desc',
  TRANSLATE_VALUE_SAVE_CHANGES: 'translate-value-save-changes',
  TRANSLATE_VALUE_ACTIVE: 'translate-value-active',
  NEXT: 'next',
  DECISION_HEADER: 'decision_header',
  LEADERSHIP_HEADER: 'leadership_header',
  EXPERIENCE_HEADER: 'experience_header',
  BD_BASIC_INFO: 'bd-basic-info',
  BD_OPPORTUNITY: 'bd-opportunity',
  BD_STAGE: 'bd-stage',
  BD_COMPANY: 'bd-company',
  BD_CLIEN_CONTACTS: 'bd-client-contacts',
  BD_JOB_TITLE: 'bd-job-title',
  BD_PROJECTED_START_DATE: 'bd-projected-start-date',
  BD_LOCATION: 'bd-location',
  BD_CONSULTANT: 'bd-consultant',
  BD_PROBABILITY: 'bd-probability',
  BD_ESTIMATED_REVENUE: 'bd-estimated-revenue',
  BD_ADD_PROJECT: 'bd-add-project',
  BD_CREATE_NEW_PROJECT: 'bd-create-new',
  BD_LINK_EXISTING_PROJECT: 'bd-link-existing',
  BD_BD_STATUS: 'bd-bd-status',
  BD_SERVICE_OFFERING: 'bd-service-offering',
  BD_GEOGRAPHY: 'bd-geography',
  BD_COMPETITION: 'bd-competition',
  BD_TEAM_INFO: 'bd-team-info',

  BD_TEAM_PARTNER_LABEL: 'bd-team-partner-label',
  BD_TEAM_PARTNER_TITLE: 'bd-team-partner-title',
  BD_TEAM_ADMIN_LABEL: 'bd-team-admin-label',
  BD_TEAM_ADMIN_TITLE: 'bd-team-admin-title',
  BD_TEAM_RECRUITER_LABEL: 'bd-team-recruiter-label',
  BD_TEAM_RECRUITER_TITLE: 'bd-team-recruiter-title',
  BD_TEAM_RESEARCHER_LABEL: 'bd-team-researcher-label',
  BD_TEAM_RESEARCHER_TITLE: 'bd-team-researcher-title',
  BD_TEAM_NAME: 'bd-team-name',
  BD_TEAM_LEAD: 'bd-team-lead',
  BD_TEAM_ORIGINATION: 'bd-team-origination',
  BD_TEAM_SELLING: 'bd-team-selling',
  BD_TEAM_EXECUTION: 'bd-team-execution',
  BD_TEAM_TOTAL_CREDIT: 'bd-team-total-credit',
  CONTACTS_INDUSTRIES: 'contact-industries',
  HORIZONTAL_PRACTICE: 'horizontal-practice',
  VERTICAL_PRACTICE: 'vertical-practice',
  PROJECT_TYPE: 'project-type',
  RESULT: 'result',
  PROJECT_VC_PE: 'project-vc-pe',
  ENCORE_NUMBER: 'encore_number',
  SHOWING: 'Showing',
  ATHENA: 'athena',
  DECISION_FRAMEWORK: 'decision_framework',
  SCORES: 'scores',
  SAVE_GENERATE_ROADMAP: 'save-add-generate-roadmap',
  GENERATING_POSITION_PROFILE: 'generating-position-profile',
  CANDIDATES_IN_PIPELINE: 'candidate-in-pipeline',
  PROJECT_ID: 'project-id',
  DICTATION: 'dictation',
  DESCRIPTION: 'description',
  DATE_TIME: 'date-time',
  TIME: 'time',
  TIMEZONE: 'timezone',
  ATTENDEES: 'attendees',
  DUE_DATE: 'due-date',
  ALL_TIMEZONES: 'all-timezones',
  PROJECT_TEAM: 'project-team',
  CLIENT_CONTACTS: 'client-contacts',
  ALL_USERS: 'all-users',
  PIPELINE_WITH_TARGETS: 'pipeline-with-targets',
  PIPELINE_NO_TARGETS: 'pipeline-no-targets',
  FULL_PIPELINE: 'full-pipeline',
  NO_PIPELINE: 'no-pipeline',

  START_DATE: 'start-date',
  LOE_DATE: 'loe-date',
  OFFHOLD_DATE: 'offhold-date',
  END_DATE: 'end-date',
  START_RECORDING: 'start-recording',
  PLEASE_WAIT: 'please-wait',
  STOP_RECORDING: 'stop-recording',
  MAX_RECORDING_TIME: 'max-recording-time',
  BD_TEAM_CONSULTANT_TITLE: 'bd-team-consultant-title',
  LOGGED_A: 'logged-a',
  ATHENA_INVITE_SUB_HEADING: 'athena_invite_sub_heading',
  ATHENA_INVITE_HEADING: 'athena_invite_heading',
  ATHENA_INVITE_SAVE_AND_INVITE: 'athena_invite_save_and_invite',
  JOB_PORTAL_SUCCESS: 'job_portal_success',
  JOB_PORTAL_SUCCESS_WITH_RESUME: 'job_portal_success_with_resume',
  FIT_SCORE: 'fit_score',
  FIT_SCORE_EXPERIENCE_SCORE: 'fit_score_experience_score',
  FIT_SCORE_COMPETENCY_SCORE: 'fit_score_competency_score',
  CLIENT_FEEDBACK: 'client-feedback',
  MOVED_TO: 'moved-to',
  ADD_TO_WORKBENCH: 'add-to-workbench',
  CONTACTS_ADD_CONTACT_TO_WORKBENCH: 'add-contact-to-workbench',
  PROJECTS_ADD_PROJECT_TO_WORKBENCH: 'add-project-to-workbench',
  PROJECT_NAME: 'project-name',
  WORKBENCH: 'workbench',
  ADD_LIST: 'add-list',
  MY_LIST: 'my-list',
  LIST_NAME: 'list-name',
  OWNER: 'owner',
  ALL_LIST: 'all-list',
  LIST_TYPE: 'list-type',
  WORKBENCH_DELETE_HEADER: 'workbench-delete-header',
  REMOVE: 'remove',
  WELCOME_TO_WORKBENCH: 'welcome-to-workbench',
  NO_WORKBENCH_PARA: 'no-workbench-para',
  CREATED_BY: 'created-by',
  CREATED_ON: 'created-on',
  UPDATED_ON: 'updated-on',
  REMOVE_PROJECT_FROM_WORKBENCH: 'remove-project-from-workbench',
  REMOVE_CONTACT_FROM_WORKBENCH: 'remove-contact-from-workbench',
  DELETE_WORKBENCH: 'delete-workbench',
  ADD_EXISTING_PROJECT: 'add-existing-project',
  CONTACTS_LINKEDIN_URL: 'contacts-linkedin-url',
  BILLING_STATUS: 'billing_status',
  SUMMARIZE: 'summarize',
  CLIENT_INTAKE_CALL: 'client-intake-call',
  POSITION_PROFILE_HEADER: 'position-profile-header',
  DELETE_ACTIVITY: 'delete-activity',
  EDITED_BY: 'edited-by',
  CONTACTS_MARK_OFF_LIMITS: 'contacts-mark-off-limits',
  CONTACTS_DURATION: 'contacts-duration',
  CONTACTS_INDEFINITE: 'contacts-indefinite',
  CONTACTS_SPECIFIC_END_DATE: 'contacts-specific-end-date',
  CONTACTS_SEVERITY: 'contacts-severity',
  CONTACTS_HARD: 'contacts-hard',
  CONTACTS_SOFT: 'contacts-soft',
  CONTACTS_OFF_LIMITS_REASON: 'contacts-off-limits-reason',
  CONFIGURE_CANDIDATE_STATUS_TITLE: 'configure-candidate-status-title',
  PROJECT_STATUS_DRAFT: 'project-status-draft',
  PROJECT_STATUS_OPEN: 'project-status-open',
  PROJECT_STATUS_CLOSED: 'project-status-closed',
  PICK_A_DATE: 'pick-a-date',
  EDIT_OFF_LIMIT_TITLE: 'edit-off-limit-title',
  MARK_OFF_LIMIT_TITLE: 'mark-off-limit-title',
  SHOW_OFF_LIMIT_TITLE: 'show-off-limit-title',
  INHERITED_OFF_LIMIT: 'inherited-off-limit',
  DIRECT_OFF_LIMIT: 'direct-off-limit',
  PARSE: 'parse',
  RESUME_PARSE_LIMIT: 'resume-parse-limit',
  RESUME_PARSE_HELPER: 'resume-parse-helper',
  QUERY_LIST: 'query-list',
  DELETE_QUERY: 'delete-query',
  UPDATE_QUERY: 'update-query'
};
