import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomSelectionComponent from '../../CustomSelectionComponent';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const GridActionSelection = props => {
  const { handleAssignTag, toggleActivity, handleAddToProject, id = '', data = null, handleTryAthena = () => {}, handleInviteAthena = () => {} } = props;
  const [options, setOptions] = useState([]);

  let actions = useSelector(state => state.commonReducer.actions);
  const dispatch = useDispatch();
  // const [selectedOption, setSelectedOption] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!actions) {
      dispatch(fetchPickLists('INDIVIDUAL_CONTACT_ACTION', 'actions'));
    } else {
      // Temporary remove log an activity
      actions = actions?.filter(item => item.field_value !== 'log_an_activity');
      setOptions(actions);
    }
  }, [actions, dispatch]);

  const actionLabel = {
    label1: 'Add To Project',
    label1_1: t('project.addTo'),
    label2: t('actions.logActivity'),
    label3: t('actions.assignTag'),
    label4: t('actions.viewAthena'),
    label5: t('actions.inviteToAthena')
  };

  const handleMenuItemClick = option => {
    if (option?.short_desc === actionLabel.label1 || option?.short_desc === actionLabel.label1_1) handleAddToProject(id, data);
    if (option?.short_desc === actionLabel.label2) toggleActivity(id);
    if (option?.short_desc === actionLabel.label3) handleAssignTag(data);
    if (option?.short_desc === actionLabel.label4) handleTryAthena(data);
    if (option?.short_desc === actionLabel.label5) handleInviteAthena(data);
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const customButtonForAllContact = <MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />;

  return <CustomSelectionComponent options={options} handleMenuItemClick={handleMenuItemClick} component={customButtonForAllContact} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />;
};

GridActionSelection.propTypes = {
  handleAssignTag: PropTypes.func,
  handleTryAthena: PropTypes.func,
  toggleActivity: PropTypes.func,
  handleAddToProject: PropTypes.func,
  id: PropTypes.string,
  data: PropTypes.object,
  handleInviteAthena: PropTypes.func
};

export default GridActionSelection;
