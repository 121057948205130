export const interviewGridColumn = [
  {
    field: 'project.job_number',
    headerName: 'Project ID'
  },
  {
    field: 'project.job_title',
    headerName: 'Job Title',
    flex: 2
  },
  {
    field: 'company',
    headerName: 'Company',

    flex: 1
  },
  {
    field: 'Candidate_name',
    headerName: 'Candidate Name',

    flex: 1
  },
  {
    field: 'Partners',
    headerName: 'Partners Name',

    flex: 1
  },
  {
    field: 'Time_scheduled',
    headerName: 'Time Scheduled',

    flex: 1
  },
  {
    field: 'Date_scheduled',
    headerName: 'Date Scheduled',

    flex: 2
  }
];

export const generateProjectGridColumns = t => {
  return [
    {
      field: 'job_number',
      headerName: t('grid.projectId'),
      minWidth: 100,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRenderer: params => {
        return params.value;
      }
    },
    {
      field: 'job_title',
      headerName: 'Job Title',
      minWidth: 200,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'ign_companies.name',
      headerName: 'Company',
      minWidth: 150,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,

      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'creator.name',
      headerName: 'Added By',
      minWidth: 100,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'stage',
      headerName: 'Stage',
      minWidth: 90,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'created_at',
      headerName: 'Days Open',
      minWidth: 90,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRenderer: created_at => {
        return Math.floor((new Date() - new Date(created_at?.value)) / (1000 * 60 * 60 * 24));
      }
    },
    {
      field: 'location',
      headerName: 'Location',
      minWidth: 150,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'ign_industries.name',
      headerName: 'Industry',
      minWidth: 150,
      hide: true,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },

    {
      field: 'service_offering',
      headerName: 'Service Offering',
      minWidth: 150,
      hide: true,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'min_experience',
      headerName: 'Min Experience',
      minWidth: 200,
      hide: true,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'max_experience',
      headerName: 'Max Experience',
      minWidth: 200,
      hide: true,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'max_compensation',
      headerName: 'Max Compensation',
      minWidth: 200,
      hide: true,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    },
    {
      field: 'min_compensation',
      headerName: 'Min Compensation',
      minWidth: 200,
      hide: true,
      flex: 2,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      }
    }
  ];
};

export const getAllProjectApiQuery = {
  // also using in engagement tab, company module.
  query: {
    order: [['job_number', 'DESC']]
  },
  countForStages: [],
  type: 'my-projects'
};
