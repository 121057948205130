//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Container, FormControlLabel, InputLabel, Paper, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import '../../../../../src/utils/common.scss';
import '../../../../Containers/Commons/CheckboxStyle/index.scss';
import '../../../../Containers/Commons/SwitchCss/index.scss';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import Footer from '../../../common/Footer';
import AddedBySelection from '../../../common/FunctionalComponents/AddedBy/AddedBy';
import RecordTypeSelection from '../../../common/FunctionalComponents/RecordType/RecordType';
import RichText from '../../../common/RichText/index';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import { createRequiredField, CreateSchema, getProfileInfoPayload } from '../../utils';
import './index.scss';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createSchema } from '../../../../Containers/Companies/Utils';
import { getUserSelector } from '../../../../selectors';
import { ignCompanyDataApi } from '../../../../services/ApiService';
import { getAccessToken } from '../../../../services/cognitoService';
import { DEFAULT_STATUS, DUPLICATION_FIELDS, EMAIL_TYPE, ERROR, FIELDS, IGN_API, POST, REVENUE_MAX_LIMIT, SUCCESS, WARNING } from '../../../../services/constantService';
import { checkDuplicateOnBlur, customFormValidator, splitLastIfMultiple, validateLinkedinProfileURL } from '../../../../utils/common';
import { formatDate, formatDateUtcLocal } from '../../../../utils/date';
import useContactFormHook from '../../../../utils/Hooks/useContactFormHook';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import AddCompany from '../../../AddCompany';
import DuplicateContactNotifier from '../../../common/CustomPopup/DuplicateContactNotifier';
import CustomTable from '../../../common/CustomTable';
import DatePickerCommon from '../../../common/DatePicker/DatePicker';
import CompanySelectionDebounce from '../../../common/FunctionalComponents/CompanySelection/CompanySelectionDebounce';
import ContactRelationTypeSelection from '../../../common/FunctionalComponents/ContactRelationTypeSelection';
import ContactTypeSelection from '../../../common/FunctionalComponents/ContactTypeSelection';
import GenderSelection from '../../../common/FunctionalComponents/GenderSelection';
import Loader from '../../../common/Loader';
import MakeConfidential from '../../../common/MakeConfidential/MakeConfidential';
import ResumeParser from '../../../common/ResumeParser';
import { validateEmail } from '../../../MessageTemplatePopup/utils';
//import "./index.scss";

const ProfileDetails = forwardRef((props, ref) => {
  const [toggleAddress, setSwitchAddress] = useState(false);
  // const [/*selectedOption*/, setSelectedOption] = useState(null);
  const [fetchData, showFetchData] = useState(false);
  const [email, setEmail] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [isCheckApproved, setIsCheckApproved] = useState(false);
  const [isResumeParserOpen, setIsResumeParserOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [srcUrl, setSrcUrl] = useState(null);
  const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);
  const [duplicateRecords, setDuplicateRecords] = useState(null);
  const [duplicationField, setDuplicationField] = useState('');
  const { setImage, handleSaveTabData, id, onCancel } = props;
  const userData = useSelector(getUserSelector);
  const { path } = useParams();
  const [currentLoggedUser, setCurrentLoggedUser] = useState([]);
  /* Uncomment Code to use message translate */
  const { translateMessage } = useCustomMessageHook();
  const { enqueueSnackbar } = useSnackbar();
  // const [relationData, setRelationData] = useState([
  //   { searchType: "", relationType: "" },
  // ]);
  const [relationList, setRelationList] = useState([]);
  const [, /* checkboxData */ setCheckboxData] = useState(false);
  const [relationTypeData, setRelationTypeData] = useState([{ contact_id: '', contact_ref_id: '', type: '' }]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { getValues: getValuesAddCompany, setValue: setValueAddCompany, register: registerAddCompany, unregister: unregisterAddCompany, watch: watchAddCompany, reset: resetAddCompany } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const isValidated = async contact => {
    let isValid = await CreateSchema.isValid(contact);
    if (!isValid) {
      let dirtyField = customFormValidator(contact, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } else {
        return true;
      }
    }

    if (contact?.linkedin_url) {
      let isValidURL = validateLinkedinProfileURL(contact.linkedin_url);
      if (!isValidURL) {
        // conformation is required
        const message = translateMessage('LinkedinUrlStructure', '');
        enqueueSnackbar(message, { variant: WARNING });
        return false;
      }
    }
    if (contact?.contact_relationship_type) {
      const missingFields = [];
      contact?.contact_relationship_type.forEach(element => {
        const isMissingContactRefId = !element.contact_ref_id ? true : false;
        const isMissingType = !element.type ? true : false;
        if (!(isMissingContactRefId && isMissingType) && (isMissingContactRefId || isMissingType)) {
          if (isMissingContactRefId) {
            missingFields.push('Contact Name');
          }
          if (isMissingType) {
            missingFields.push('Relation Type');
          }
          return;
        }
      });
      // if(contact?.contact_relationship_type?.length < 1){
      //   return
      // }
      if (missingFields.length !== 0) {
        const uniqueMissingFields = [...new Set(missingFields)];
        const message = translateMessage('Required', ...splitLastIfMultiple(uniqueMissingFields));

        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    if (contact?.contact_emails && contact.contact_emails.length) {
      const emails = contact?.contact_emails?.find(item => item?.is_primary)?.email;
      let validateEmailSyntax = validateEmail(emails);
      if (!validateEmailSyntax) {
        const message = translateMessage('ValidFiled', false, 'Email');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      } else {
        return true;
      }
    }
    return true;
  };
  const { handleSave, register, watch, setValue, currentValues, loading, setLoading, isDirty } = useContactFormHook({
    id,
    path,
    getPayload: getProfileInfoPayload,
    handleSaveTabData,
    ref,
    formSchema: CreateSchema,
    isValidated,
    defaultFormValue: { contact_emails: [] },
    tabName: 'PROFILE_DETAILS_TAB'
  });

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_BASIC_INFO,
    TRANS_KEYS.CONTACTS_FIRST_NAME,
    TRANS_KEYS.CONTACTS_LAST_NAME,
    TRANS_KEYS.CONTACTS_ADDRESS,
    TRANS_KEYS.CONTACTS_OR,
    TRANS_KEYS.CONTACTS_CREATE_CONTACT,
    TRANS_KEYS.CONTACTS_PASTE_LINKEDIN_URL,
    TRANS_KEYS.CONTACTS_PROFESSIONAL_INFO,
    TRANS_KEYS.CONTACTS_COMPANY_NAME,
    TRANS_KEYS.CONTACTS_HIGHLIGHTS,
    TRANS_KEYS.CONTACTS_SAVE,
    TRANS_KEYS.CONTACTS_CANCEL,
    TRANS_KEYS.CONTACTS_MAKE_PRIMARY,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_RECORD_TYPE,
    TRANS_KEYS.CONTACTS_ADDED_BY,
    TRANS_KEYS.CONTACTS_JOB_TITLE,
    TRANS_KEYS.CONTACTS_EMAIL,
    TRANS_KEYS.CONTACTS_CREATE_FROM_RESUME,
    TRANS_KEYS.CONTACTS_PERSONAL_INFO,
    TRANS_KEYS.CONTACTS_GENDER,
    TRANS_KEYS.CONTACTS_DATE_OF_BIRTH,
    TRANS_KEYS.CONTACTS_MAKE_CONFIDENTIAL,
    TRANS_KEYS.CONTACTS_RELATION_TYPE,
    TRANS_KEYS.CONTACTS_SEARCH_CONTACT,
    TRANS_KEYS.CONTACTS_RELATIONSHIP,
    TRANS_KEYS.CONTACTS_RELATION_TYPE
  ]);
  //const buttonText = t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`);

  /* Detect Browser language
  const lng = navigator.language;
  */

  useEffect(() => {
    register('first_name');
    register('last_name');
    register('contact_emails');
    register('current_company_id');
    register('contact_company');
    register('contact_company_name');
    register('contact_added_by');
    register('current_job_title');
    register('contact_record_types');
    register('highlights');
    register('linkedin_url');
    register('contact_address');
    register('profile_img');
    register('gender');
    register('date_of_birth');
    register('is_confidential');
    register('contact_relationship');
    register('contact_relationship_type');
  }, [register]);

  useEffect(() => {
    if (!id) {
      const loggedUser = [];
      const userObj = {
        last_name: userData?.last_name,
        name: userData?.name,
        first_name: userData?.first_name,
        id: userData?.id
      };
      loggedUser.push({ added_user: userObj });
      setCurrentLoggedUser(loggedUser);
    }
  }, []);

  useEffect(() => {
    if (!id && !currentValues?.contact_added_by && currentLoggedUser.length > 0) {
      const result = currentLoggedUser.map(e => {
        return {
          user_id: e?.added_user?.id
        };
      });
      setValue('contact_added_by', result, { shouldDirty: true });
    }
  }, [currentLoggedUser]);

  useEffect(() => {
    setValue(
      'contact_emails',
      email.map(e => ({ ...e, email_type: e?.email_type?.field_value })),
      { shouldDirty: true }
    );
  }, [email]);
  // useEffect(() => {
  //   if (currentValues?.contact_relationship && currentValues?.contact_relationship.length) {
  //     setRelationData(currentValues?.contact_relationship)
  //   }
  // }, [currentValues])

  useEffect(() => {
    if (currentValues?.contact_relationship_type && currentValues?.contact_relationship_type.length) {
      setRelationTypeData(currentValues?.contact_relationship_type);
    }
  }, [currentValues]);

  useEffect(() => {
    const accessToken = async () => {
      let token = await getAccessToken();
      setSrcUrl(`${IGN_API.contact}/${currentValues?.id}/image?token=${token}&time=${Date.now()}`);
    };
    if (currentValues?.image_id) accessToken();
  }, [currentValues]);

  const handleChanges = event => {
    setSwitchAddress(event.target.checked);
    setCheckboxData(false);
  };
  const handleChangeDocument = () => {
    setIsResumeParserOpen(true);
  };

  const handleResumeParseClose = () => {
    setIsResumeParserOpen(false);
  };

  const title = t(`${TRANS_KEYS.CONTACTS_MAKE_CONFIDENTIAL}:key`);

  const genderHandleChange = data => {
    setValue('gender', data?.field_value ? data?.field_value : '', { shouldDirty: true });
  };
  const handleInputChange = event => {
    setValue('linkedin_url', event.target.value, { shouldDirty: true });
  };

  const onBlurLinkedInUrl = async e => {
    if (e.target.value && e.relatedTarget?.tagName !== 'BUTTON') {
      const linkedInUrlData = { linkedin_url: e.target.value };
      const { isDuplicate, duplicateRecords } = await checkDuplicateOnBlur(linkedInUrlData, setLoading);
      setDuplicationField(DUPLICATION_FIELDS.LINKEDIN);
      setDuplicateRecords(duplicateRecords);
      setIsConfirmationPopup(isDuplicate);
    }
  };

  const handleEmail = e => {
    if (id) {
      const filterEmail = currentValues.contact_emails?.filter(elem => !elem.is_primary);
      const emailID = currentValues?.contact_emails && currentValues?.contact_emails?.find(item => item.is_primary)?.id;
      const emailType = currentValues?.contact_emails && currentValues?.contact_emails?.find(item => item.is_primary)?.email_type;
      if (filterEmail.length) {
        setEmail([...filterEmail, { id: emailID, email: e.target.value, email_type: emailType, is_primary: true }]);
      } else {
        setEmail([{ id: emailID, email: e.target.value, email_type: emailType, is_primary: true }]);
      }
    } else {
      setEmail([{ email: e.target.value, email_type: EMAIL_TYPE.PERSONAL, is_primary: true }]);
    }
  };

  const onBlurEmail = async e => {
    if (e.target.value && e.relatedTarget?.tagName !== 'BUTTON') {
      const emailData = { contact_emails: [{ email: e.target.value || '' }] };
      const { isDuplicate, duplicateRecords } = await checkDuplicateOnBlur(emailData, setLoading);
      setDuplicationField(DUPLICATION_FIELDS.EMAIL);
      setDuplicateRecords(duplicateRecords);
      setIsConfirmationPopup(isDuplicate);
    }
  };

  const handleCheck = e => {
    setIsCheckApproved(e.target.checked);
    setValue('is_confidential', e.target.checked, { shouldDirty: true });
  };
  const handleProfileImage = e => {
    const file = e.target.files[0];
    if (file) {
      setImage(e.target.files);
      setValue('profile_img', e.target.files);
      const fr = new FileReader();
      fr.onload = function () {
        setNewImage(fr.result);
      };
      fr.readAsDataURL(file);
    }
  };
  const handleRelationChange = (index, field, value) => {
    const updatedRelations = relationTypeData.map((relation, i) => {
      if (i === index) {
        switch (field) {
          case 'contact_ref_id':
            return { ...relation, [field]: value?.id, contact_data: value };
          case 'type':
            return { ...relation, [field]: value?.field_value, ign_translate_value: value };
          default:
            return { ...relation, [field]: value?.field_value };
        }
      }

      return relation;
    });

    setRelationTypeData(updatedRelations);
  };
  const handleRecordTypeChange = data => {
    const recordData = data?.map(elem => {
      return {
        type: elem || elem?.field_value ? elem?.field_value || elem : null
      };
    });
    setValue('contact_record_types', recordData, { shouldDirty: true });
  };
  const handleAddedByChange = data => {
    const recordData = data?.map(elem => {
      return {
        user_id: elem.id ? elem.id : null
      };
    });
    setValue('contact_added_by', recordData, { shouldDirty: true });
  };
  const handleAddRelationNumber = () => {
    setRelationTypeData([...relationTypeData, { contact_id: '', contact_ref_id: '', type: '' }]);
  };
  const handleRemoveRelationNumber = index => {
    const updatedRelation = [...relationTypeData];
    updatedRelation.splice(index, 1);
    setRelationTypeData(updatedRelation);
  };
  useEffect(() => {
    setValue(
      'contact_relationship_type',
      relationTypeData?.map(elem => {
        return {
          ...elem,
          type: elem?.type?.field_value ? elem?.type?.field_value : elem?.type,
          contact_id: currentValues?.id
        };
      }),
      { shouldDirty: true }
    );
  }, [relationTypeData]);

  const cancelData = () => {
    onCancel(isDirty);
  };

  const handleClose = () => {
    setIsConfirmationPopup(false);
  };
  const handleInputChangeForCompany = (e, newValue) => {
    setValue('contact_company_name', newValue, { shouldDirty: true });
  };
  const handleCompanyOptionChange = (e, newValue) => {
    setValue('contact_company', newValue, { shouldDirty: true });
  };

  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags?.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries ? data?.company_industries?.map(industry => ({ industry_id: industry.id })) : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value,
      company_status: DEFAULT_STATUS
    };
    return addCompanyPayload;
  };

  const isValidatedRevenue = async formValues => {
    if (formValues.revenue_range_from >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.revenue_range_to >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_to_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_from >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_to >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_to_massage, { variant: ERROR });
      return false;
    }
    return true;
  };

  const isValidatedCompany = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };

  const saveData = async () => {
    let formValues = { ...getValuesAddCompany() };
    const requestBody = getPayload(formValues);
    const IsValidRevenueRange = await isValidatedRevenue(formValues);
    const IsValidRequestBody = await isValidatedCompany(requestBody);
    if (IsValidRequestBody && IsValidRevenueRange) {
      setIsLoading(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);

      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        setIsPopupOpen(false);
        resetAddCompany();
        setIsLoading(false);
        return { id: data.id, name: formValues.name };
      } else if (status === 409) {
        const message = translateMessage('Duplicate', false, 'Company Name');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setIsLoading(false);
    }
  };

  const closeAddCompanyPopup = () => {
    setIsPopupOpen(false);
  };

  const saveAddCompanyPopup = async () => {
    const company = await saveData();
    if (company) {
      setValue('contact_company', company, { shouldDirty: true });
      setValue('contact_company_name', company?.name, { shouldDirty: true });
    }
    setIsPopupOpen(false);
    resetAddCompany();
  };
  return (
    <>
      <Loader show={loading} />
      <AddCompany
        isPopupOpen={isPopupOpen}
        handleClose={closeAddCompanyPopup}
        handleSubmit={saveAddCompanyPopup}
        loading={isLoading}
        setIsLoading={setIsLoading}
        setValue={setValueAddCompany}
        register={registerAddCompany}
        reset={resetAddCompany}
        setIsPopupOpen={setIsPopupOpen}
        unregister={unregisterAddCompany}
        watch={watchAddCompany}
        noRedirection={true}
      ></AddCompany>
      {currentValues ? (
        <Container className='p-0' id='profileDetails'>
          <Box>
            <Footer onSubmit={handleSave} onClose={cancelData}></Footer>
          </Box>
          <Paper elevation={0} className='flex flex-wrap pt-4 row-gap column-gap'>
            <Paper elevation={0} className='left-panel pt-4'>
              <Box className='profile-img pt-4'>
                <Box className='avatar-upload'>
                  <Box className='avatar-edit'>
                    <input type='file' id='imageUpload' accept='.png, .jpg, .jpeg' onChange={e => handleProfileImage(e)} />
                    <InputLabel htmlFor='imageUpload'>
                      <Box>
                        <EditIcon className='editIcon fs-40' />
                      </Box>
                    </InputLabel>
                  </Box>
                  <Box className='avatar-preview'>
                    <Box id='imagePreview'>
                      {!(newImage || srcUrl) ? <Avatar className='avatarStyle' /> : <Avatar src={newImage ? newImage : srcUrl} alt={'Profile Image'} loading='lazy' className='avatarStyle' />}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
            <Paper elevation={0} className='right-panel pl-2'>
              {/* Basic Info */}
              <Box className='my-cards flex flex-wrap'>
                <Box className='text-label fs-16 pl-1 pb-1 header-font'>{t(`${TRANS_KEYS.CONTACTS_BASIC_INFO}:key`)}</Box>
                <Box className='form-section basic-info-section flex flex-column'>
                  <Box className='flex gap-10 flex-row flex-wrap'>
                    <Box className='section-content-data content-gap p-11 flex flex-column flex-wrap row-content'>
                      <Box className='d-flex content-gap flex-wrap'>
                        <Box className='flex flex-column row-gap column-gap'>
                          {/*  <Box className="label-text fs-14 fs-14">
                      {t(`${TRANS_KEYS.CONTACTS_FIRST_NAME}:key`)}
                      <span className="MuiInputLabel-asterisk"> *</span>
                    </Box> */}
                          <Box className='input-field-data flex'>
                            <CustomInputField
                              defaultValue={currentValues?.first_name || ''}
                              required={true}
                              label={t(`${TRANS_KEYS.CONTACTS_FIRST_NAME}:key`)}
                              onChange={e => {
                                const newValue = e.target.value;
                                setValue('first_name', newValue, { shouldDirty: true });
                              }}
                            />
                          </Box>
                        </Box>
                        <Box className='flex flex-column row-gap'>
                          {/* <Box className="label-text fs-14">
                      {t(`${TRANS_KEYS.CONTACTS_LAST_NAME}:key`)}
                      <span className="MuiInputLabel-asterisk"> *</span>
                    </Box> */}
                          <Box className='input-field-data flex justify-center align-center'>
                            <CustomInputField
                              label={t(`${TRANS_KEYS.CONTACTS_LAST_NAME}:key`)}
                              required={true}
                              defaultValue={currentValues?.last_name || ''}
                              onChange={e => {
                                setValue('last_name', e.target.value, { shouldDirty: true });
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Box className='flex flex-row'>
                          <CustomInputField
                            label={t(`${TRANS_KEYS.CONTACTS_EMAIL}:key`)}
                            defaultValue={(currentValues?.contact_emails && currentValues?.contact_emails?.find(item => item.is_primary)?.email) || ''}
                            onChange={e => {
                              handleEmail(e);
                            }}
                            onBlur={e => onBlurEmail(e)}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box className='section-content-data-external p-11 flex flex-row flex-wrap'>
                      <Box className='flex flex-column'>
                        <Box className='fileUploader'>
                          <Box sx={{ width: 300 }} className='formParent' onClick={handleChangeDocument}>
                            <Box className='font-size fs-13 d-flex align-items-center'>{t(`${TRANS_KEYS.CONTACTS_CREATE_FROM_RESUME}:key`)}</Box>
                          </Box>
                        </Box>
                        {isResumeParserOpen && <ResumeParser isPopupOpen={isResumeParserOpen} handleClose={handleResumeParseClose} />}
                        <Box className='flex p-2 justify-center align-center or-size fs-10'>{t(`${TRANS_KEYS.CONTACTS_OR}:key`)}</Box>
                        <Box className='flex pt-1 flex-column row-gap'>
                          <Box className='flex flex-column linkedin row-gap column-gap'>
                            <Box className='input-field-data flex justify-center align-center'>
                              <CustomInputField
                                label={t(`${TRANS_KEYS.CONTACTS_PASTE_LINKEDIN_URL}:key`)}
                                onClick={() => showFetchData(true)}
                                // value={inputValue}
                                onChange={handleInputChange}
                                defaultValue={currentValues?.linkedin_url || ''}
                                onBlur={e => onBlurLinkedInUrl(e)}
                              />
                            </Box>
                            <Box className={`justify-content-center position-absolute d-flex animate-fetch-data ${fetchData && inputValue ? 'visible' : ''}`}>
                              {fetchData && (
                                <Button variant='contained' className='fetch-data fs-11' disableRipple>
                                  {t(`${TRANS_KEYS.CONTACTS_CREATE_CONTACT}:key`)}
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* Basic Info End */}
              {/* Professional Info */}
              <Box className='my-cards flex flex-wrap'>
                <Box className='form-section flex flex-column'>
                  <Box className='text-label fs-16 header-font'>{t(`${TRANS_KEYS.CONTACTS_PROFESSIONAL_INFO}:key`)}</Box>
                  <Box className='content-gap professional-section-content-data p-11 flex flex-row flex-wrap'>
                    <Box className='flex flex-column row-gap content-gap '>
                      {/* <Box className="label-text fs-14">
                    {t(`${TRANS_KEYS.CONTACTS_COMPANY_NAME}:key`)}
                  </Box> */}
                      <Box className='input-field-data flex justify-center align-center'>
                        <CompanySelectionDebounce
                          freeSolo
                          forcePopupIcon
                          label={t(`${TRANS_KEYS.CONTACTS_COMPANY_NAME}:key`)}
                          defaultValue={watch('contact_company_name') || ''}
                          value={watch('contact_company') || ''}
                          onChange={(e, data) => {
                            setValue('contact_company', data, { shouldDirty: true });
                            setValue('contact_company_name', data?.name ? data?.name : e.target.value, { shouldDirty: true });
                          }}
                          addAnotherButtonOnChange={() => {
                            setIsPopupOpen(true);
                          }}
                        />
                      </Box>
                      {/* <Box className="label-text fs-14">
                    {t(`${TRANS_KEYS.CONTACTS_ADDED_BY}:key`)}
                  </Box> */}
                      <Box className='input-field-data flex justify-center align-center'>
                        <AddedBySelection
                          label={t(`${TRANS_KEYS.CONTACTS_ADDED_BY}:key`)}
                          defaultValue={currentValues?.contact_added_by ? currentValues?.contact_added_by : currentLoggedUser}
                          onChange={data => {
                            handleAddedByChange(data);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className='flex flex-column row-gap content-gap '>
                      {/* <Box className="label-text fs-14">
                    {t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
                  </Box> */}
                      <Box className='input-field-data flex justify-center align-center'>
                        <CustomInputField
                          label={t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
                          defaultValue={currentValues?.current_job_title || ''}
                          onChange={e => {
                            setValue('current_job_title', e.target.value, { shouldDirty: true });
                          }}
                        />
                      </Box>
                      {/*  <Box className="label-text fs-14">
                    {t(`${TRANS_KEYS.CONTACTS_RECORD_TYPE}:key`)}
                  </Box> */}
                      <Box className='input-field-data flex justify-center align-center'>
                        <RecordTypeSelection
                          label={t(`${TRANS_KEYS.CONTACTS_RECORD_TYPE}:key`)}
                          defaultValue={currentValues?.contact_record_types}
                          onChange={data => {
                            handleRecordTypeChange(data);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* personal info */}

              <Paper elevation={0} className='my-cards flex flex-wrap'>
                <Box className='d-flex justify-content-between'>
                  <Box variant='body1' className='d-flex header-font text-label fs-16 align-items-center'>
                    {t(`${TRANS_KEYS.CONTACTS_PERSONAL_INFO}:key`)}
                  </Box>
                  <Box className='d-flex item-center'>
                    <MakeConfidential
                      name={title}
                      //checked={isCheckApproved}
                      handleChange={handleCheck}
                      defaultChecked={isCheckApproved || watch('is_confidential')}
                    />
                  </Box>
                </Box>
                <Box className='section-content-data p-11 flex flex-row flex-wrap content-gap'>
                  <Box className='flex flex-column row-gap'>
                    {/* <span className="label-text fs-14">
              {t(`${TRANS_KEYS.CONTACTS_DATE_OF_BIRTH}:key`)}
            </span> */}
                    <Box className='input-field-data flex h-100'>
                      <DatePickerCommon
                        label={t(`${TRANS_KEYS.CONTACTS_DATE_OF_BIRTH}:key`)}
                        className='input-field-data'
                        value={formatDate(watch('birth_date'), 'DD/MM/YYYY') || null}
                        onChange={e => {
                          const selectedDate = e?.$d;
                          setValue('birth_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                        }}
                        disableFuture
                      />
                    </Box>
                  </Box>
                  <Box className='flex flex-column row-gap'>
                    {/*  <span className="label-text fs-14">
              {t(`${TRANS_KEYS.CONTACTS_GENDER}:key`)}
            </span> */}
                    <Box className='input-field-data flex justify-center align-center'>
                      <GenderSelection
                        label={t(`${TRANS_KEYS.CONTACTS_GENDER}:key`)}
                        defaultValue={currentValues?.ign_translate_value?.short_desc || ''}
                        onChange={(e, data) => {
                          genderHandleChange(data);
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
              {/* Professional Info End */}
              {/* Address */}

              <Box className={`my-cards  d-flex flex-wrap ${toggleAddress ? 'flip' : ''}`}>
                <Box className={`d-flex flex-column ${toggleAddress ? 'flip2' : ''}`}>
                  <Box className='communication-section p-11 d-flex flex-column flex-wrap'>
                    <Box className='d-flex flex-row align-items-center'>
                      <Box className='text-label mr-2 '>{t(`${TRANS_KEYS.CONTACTS_RELATIONSHIP}:key`)}</Box>
                      <Box className='pt-2 pl-2'>
                        <FormControlLabel
                          control={
                            <Switch
                              className='SwitchStyle'
                              checked={toggleAddress}
                              //{...label}
                              onChange={handleChanges}
                            ></Switch>
                          }
                        />
                      </Box>
                    </Box>
                    {toggleAddress && (
                      <Box className='head-container'>
                        <Box className='d-flex sub-head-content'>
                          <CustomTable
                            headerData={[t(`${TRANS_KEYS.CONTACTS_RELATION_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_SEARCH_CONTACT}:key`)]}
                            title={t(`${TRANS_KEYS.CONTACTS_RELATIONSHIP}:key`)}
                            handleAnotherRow={handleAddRelationNumber}
                            rows={relationTypeData}
                            dataList={relationList}
                            disable={3}
                          >
                            {relationTypeData?.map((relation, index) => {
                              return (
                                <TableRow key={relation?.id}>
                                  <TableCell className=''>
                                    <ContactRelationTypeSelection
                                      searchResult='All'
                                      value={relation?.ign_translate_value?.short_desc ? relation?.ign_translate_value?.short_desc : relation.type}
                                      selectedList={relationTypeData}
                                      onChange={(e, data) => handleRelationChange(index, 'type', data)}
                                      setRelationList={setRelationList}
                                      id={`type${relation.id}`}
                                    />
                                  </TableCell>
                                  <TableCell className=''>
                                    <ContactTypeSelection
                                      value={relation?.contact_data?.name ? relation?.contact_data?.name : relation.name}
                                      onChange={(e, data) => handleRelationChange(index, 'contact_ref_id', data)}
                                      selectedList={relationTypeData}
                                      // id={`relationType_${index}`}
                                      // variant="outlined"
                                      contactId={currentValues?.id}
                                    />
                                  </TableCell>
                                  {relationTypeData?.length > 1 && (
                                    <TableCell className='remove-another-button ' mt={2}>
                                      <CloseIcon onClick={() => handleRemoveRelationNumber(index)} className='table-close-icon' />
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </CustomTable>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* Address End */}

              {/* Professional Info End */}
              {/* Highlights */}
              <Box className='my-cards flex flex-wrap'>
                <Box className='form-section flex flex-column'>
                  <Box className='section-content-data p-11 flex flex-column flex-wrap content-gap'>
                    <Box className='flex flex-row align-center'>
                      <Box className='highLightsFont fs-14'>{t(`${TRANS_KEYS.CONTACTS_HIGHLIGHTS}:key`)}</Box>
                    </Box>
                    <RichText
                      onChange={data => {
                        setValue('highlights', data, { shouldDirty: true });
                      }}
                      defaultValue={watch('highlights')}
                    />
                  </Box>
                </Box>
              </Box>
              {/* Highlights End */}
            </Paper>
          </Paper>
        </Container>
      ) : null}
      {isConfirmationPopup && <DuplicateContactNotifier open={isConfirmationPopup} data={duplicateRecords} duplicationField={duplicationField} showAction={false} handleClose={handleClose} />}
    </>
  );
});

ProfileDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  option: PropTypes.array,
  index: PropTypes.string,
  handleSave: PropTypes.func,
  setImage: PropTypes.func,
  id: PropTypes.string,
  handleSaveTabData: PropTypes.func,
  onCancel: PropTypes.func
};

export default ProfileDetails;
