//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useMemo, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

// import AddIcon from "@mui/icons-material/Add";
// import IosShareIcon from "@mui/icons-material/IosShare";
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import {
  AppBar,
  Box,
  Button,
  // IconButton,
  // Menu,
  // MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import PropTypes from 'prop-types';
import {
  // useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import withRouter from '../../utils/withRouter';
import '../Commons/index.scss';
// import style from "./index.module.scss"
//-----------------------------------------------------------// Internal Imports // ------------------------------
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useSnackbar } from 'notistack';
import { Spinner, Stack } from 'react-bootstrap';
import DynamicTabLayout from '../../components/DynamicTabs/TabLayout';
import Loader from '../../components/common/Loader';
import { ProjectDataApi, getProjectAndBdCount } from '../../services/ApiService';
import { ERROR, GET, POST, PROJECT_SCREEN, ROUTES } from '../../services/constantService';
import HeaderWithStats from '../Commons/Header/index';
// import ExportSideNav from "../Contacts/ExportSideNav";
import { useTranslation } from 'react-i18next';
import PopUpOnClick from '../Contacts/PopUpOnClick';
import CardViewContainer from './CardViewContainer';
import GridViewContainer from './GridViewContainer';
import Search from './Search';

const MySearchPage = props => {
  // const { match } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const TabData = [
    {
      label: t('module.projects'),
      content: '',
      subTabs: []
    }
  ];
  // const { t } = useLanguageHooks([TRANS_KEYS.SOMETHING_WENT_WRONG]);

  //second part
  const GRID_VIEW = 'GRID_VIEW';
  const CARD_VIEW = 'CARD_VIEW';
  // const location = useLocation();
  const [isRowSelected, setIsRowSelected] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [listShow, setListShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchID, setSearchId] = useState('');
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [viewType, setViewType] = useState(CARD_VIEW);
  const [activeButton, setActiveButton] = useState(PROJECT_SCREEN.MY_PROJECT);
  const navigate = useNavigate();
  const [apiQuery, setApiQuery] = useState({
    query: {
      order: [['job_number', 'DESC']]
    },
    countForStages: ['Open', 'On Hold', 'Pending Acknowledgement', 'Draft'],
    type: PROJECT_SCREEN.MY_PROJECT
  });
  const [allProjects, setAllProjects] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewButton, setViewButton] = useState(PROJECT_SCREEN.PROJECT);
  const [stats, setStats] = useState([]);
  const [count, setCount] = useState();
  const searchValueRef = useRef();
  const searchIdRef = useRef();
  // const { state } = useLoca
  searchIdRef.current = searchID;
  searchValueRef.current = searchValue;

  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const listForActionSelection = [
    {
      label: 'Add To Search'
      // onClick: () => handleAddToSearch(params?.data?.id),
    },
    {
      label: 'Add To Workbench'
      // onClick: () => handleAddToWorkbench(params.data?.id),
    },
    {
      label: 'Log An Activity'
    },
    {
      label: 'Assign Tag'
      // onClick: () => handleAddAsClient(params?.data?.id),
    },
    {
      label: 'Invite To Athena'
      // onClick: async () => { }
    }
  ];

  const handleSearchChange = (e, value) => {
    if (typeof value === 'string') {
      setSearchValue(value);
    } else if (value && value.id) {
      setSearchValue(value.job_title);
      setSearchId(value.id);
    } else if (!value) {
      setSearchValue('');
      setSearchId('');
    }
  };
  const getCount = async () => {
    try {
      setLoader(true);
      const count = await getProjectAndBdCount();

      if (count?.data) {
        const data = count?.data;
        setCount(data);
      }
      setLoader(false);
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: ERROR });
    }
  };
  // useEffect(() => {
  //   fetchProjects();
  //   getCount();
  // }, [searchValue, apiQuery, state]);

  // const toggleMenu = () => {
  //   setIsMenuOpen(true);
  // };

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = (route = '') => {
    if (route.length) {
      navigate(route);
    }
    // setAnchorEl(null);
  };

  const { type, filter } = useParams();
  const handleParamsQuery = async () => {
    if (type === PROJECT_SCREEN.PROJECT) {
      if (filter === PROJECT_SCREEN.MY_PROJECT || filter === PROJECT_SCREEN.ALL_PROJECT) {
        setViewButton(type);
        setActiveButton(filter);
        setApiQuery({ ...apiQuery, type: filter });
        getCount();
      } else {
        setLoader(true);
        navigate(`${ROUTES?.mySearches}/${type}/${PROJECT_SCREEN.ALL_PROJECT}`);
      }
    } else if (type === PROJECT_SCREEN.BUSINESS_DEVELOPMENT) {
      if (filter === PROJECT_SCREEN.MY_BD || filter === PROJECT_SCREEN.ALL_BD) {
        setViewButton(type);
        setActiveButton(filter);
        setApiQuery({ ...apiQuery, type: filter });
        getCount();
      } else {
        setLoader(true);
        navigate(`${ROUTES?.mySearches}/${type}/${PROJECT_SCREEN.ALL_BD}`);
      }
    } else {
      setLoader(true);
      navigate(`${ROUTES?.mySearches}/${PROJECT_SCREEN.PROJECT}/${PROJECT_SCREEN.ALL_PROJECT}`);
    }
  };

  useEffect(() => {
    handleParamsQuery();
    fetchProjects();
  }, [type, filter]);

  useEffect(() => {
    fetchProjects();
  }, [searchValue]);

  const fetchProjects = async (update = {}) => {
    try {
      const apiKey = {
        'my-projects': 'project',
        'all-projects': 'project',
        'my-bd': 'business_development',
        'all-bd': 'business_development'
      };

      if (apiKey[filter] && type && apiKey[filter].toLowerCase() === type.toLowerCase()) {
        setLoader(true);

        let sub_route = 'all';
        let method = POST;
        if (searchValueRef.current || searchIdRef.current) {
          if (searchValueRef.current && searchIdRef.current) {
            sub_route = `?id=${searchIdRef.current}`;
          } else if (searchValueRef.current) {
            sub_route = `query?searchValue=${searchValueRef.current}&projectsType=${filter}`;
          }
          method = GET;
        }
        const projectData = await ProjectDataApi(method, '', { ...apiQuery, type: filter, ...update }, sub_route);
        if (projectData?.data) {
          setAllProjects(projectData?.data?.data?.data ? projectData?.data?.data?.data : projectData?.data?.data ? projectData?.data?.data : [projectData?.data]);
          setStats(projectData?.data?.counts ? projectData?.data?.counts : projectData?.data?.paging?.totalCount ? projectData?.data?.paging?.totalCount : 1);
          setLoader(false);
        }
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(t('common.somethingWentWrong'));
    }
  };

  const handleQuery = async update => {
    setApiQuery({ ...apiQuery, ...update });
    fetchProjects(update);
  };

  const handleViewButtonChange = value => {
    setLoader(true);

    navigate(`${ROUTES.mySearches}/${value}/${filter}`);
  };
  const handleActiveButton = value => {
    setLoader(true);
    navigate(`${ROUTES.mySearches}/${type}/${value}`);
  };
  const getCountValue = key => {
    const apiKey = {
      'my-projects': ['my', 'Project'],
      'all-projects': ['all', 'Project'],
      'my-bd': ['my', 'Business Development'],
      'all-bd': ['all', 'Business Development']
    };
    try {
      if (!count) return 0;
      let res = count?.[apiKey[key][0]]?.filter(item => item.record_type === apiKey[key][1]);
      return res?.length > 0 && res[0].count;
    } catch (err) {
      enqueueSnackbar(err?.message, { variant: ERROR });
      return 0;
    }
  };

  const viewCount = useMemo(() => {
    return allProjects?.length;
  }, [allProjects]);

  const totalCount = getCountValue(`${activeButton}`);
  const CountComponent = () => {
    return (
      <>
        {viewCount && totalCount ? (
          <>
            Showing: {viewCount} / {getCountValue(`${activeButton}`)}
          </>
        ) : (
          <Spinner />
        )}
      </>
    );
  };
  return (
    <div className='my-search d-flex flex-column mr-3 my-3'>
      <DynamicTabLayout
        navItems={TabData}
        baseRoute={'/searches/my-searches'}
        isDrawer={true}
        isDrawerCss={true}
        style={{ paddingTop: '100px', padding: '30px' }}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        <React.Fragment index='Projects'>
          <Loader show={loader} />
          <HeaderWithStats
            stats={stats}
            handleQuery={handleQuery}
            viewButton={type || PROJECT_SCREEN.PROJECT}
            setViewButton={handleViewButtonChange}
            currentQuery={apiQuery}
            activeButton={filter || activeButton}
            setActiveButton={handleActiveButton}
            {...props}
          />

          {/* second part */}
          <Box id='contact-tab-container' className='list-contacts d-flex flex-column'>
            <Box className='tool-bar'>
              <AppBar position='static' sx={{ margin: '0 1rem', width: 'calc( 100% - 2rem )' }}>
                <Toolbar sx={{ flexWrap: 'wrap' }} className='p-1 pl-2 pr-2 p-2 border shadow rounded'>
                  <Search projectType={filter} setListShow={setListShow} value={searchValue} onChange={handleSearchChange} type='quicksearches' className='search-bar' sx={{ minWidth: '33%' }} />
                  <Box className='tool-bar' />
                  {/* <div>
                    <ExportSideNav
                      setIsMenuOpen={setIsMenuOpen}
                      isMenuOpen={isMenuOpen}
                      location={location}
                      navItems={navItems}
                    ></ExportSideNav>
                  </div> */}
                  <Stack direction='row' spacing={3}>
                    {isRowSelected ? <PopUpOnClick list={listForActionSelection} title={'Action'} /> : null}
                    {/* <IconButton
                      className="export-icon m-1"
                      onClick={toggleMenu}
                      >
                      <IosShareIcon className="export-icon-size" />
                    </IconButton> */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <Typography color='primary.main'>{CountComponent()}</Typography>
                      {viewButton == PROJECT_SCREEN.PROJECT ? (
                        <Button
                          variant='contained'
                          // href="/searches/add/details"
                          className='add-button'
                          id='basic-button'
                          onClick={() => handleClose(ROUTES.addProject)}
                        >
                          {t('actions.add')} {t('module.project')}
                        </Button>
                      ) : (
                        <Button
                          variant='contained'
                          // href="/searches/add/details"
                          className='add-button'
                          id='basic-button'
                          onClick={() => navigate(ROUTES.createBd, { state: {} })}
                        >
                          Add BD
                        </Button>
                      )}
                    </div>
                    {/* {viewButton == PROJECT_SCREEN.BUSINESS_DEVELOPMENT && (
                      <Button
                        variant="contained"
                        // href="/searches/add/details"
                        className="add-button"
                        id="basic-button"
                        onClick={() => handleClose(ROUTES.addProject)}
                        //todo route with contact
                      >
                        Add BD
                      </Button>
                    )} */}
                    {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => handleClose()}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      sx={{ width: "100%" }}
                    >
                      <MenuItem onClick={() => handleClose(ROUTES.addProject)}>
                        Add Project
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleClose(ROUTES.cloneProject)}
                      >
                        Clone Project
                      </MenuItem>
                    </Menu> */}
                    {/* {isActionDropDown && <></>}s */}
                    {viewType !== CARD_VIEW && (
                      <Button variant='outlined' className='m-1' startIcon={<ViewDayOutlinedIcon className='icon-size' />} onClick={() => setViewType(CARD_VIEW)}>
                        Card View
                      </Button>
                    )}
                    {/* {viewType !== GRID_VIEW && (
                      <Button
                        variant="outlined"
                        className="m-1"
                        startIcon={
                          <GridViewOutlinedIcon className="icon-size" />
                        }
                        onClick={() => setViewType(GRID_VIEW)}
                      >
                        Grid View
                      </Button>
                    )} */}
                  </Stack>
                </Toolbar>
              </AppBar>
              {viewType === CARD_VIEW && <CardViewContainer allProjects={allProjects} viewButton={viewButton} {...props} setIsRowSelected={setIsRowSelected} />}
              {viewType === GRID_VIEW && <GridViewContainer {...props} setIsRowSelected={setIsRowSelected} />}
            </Box>
          </Box>
        </React.Fragment>
      </DynamicTabLayout>
    </div>
  );
};

MySearchPage.propTypes = {
  match: PropTypes.object
};

export default withRouter(MySearchPage);
