import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Box, Button, Divider, Grid, InputLabel, Link, Menu, MenuItem, Paper, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { withStyles } from '@mui/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyAssignTag from '../../../../Containers/Companies/CompanyAssignTag';
import { ignCompanyDataApi } from '../../../../services/ApiService';
import { DEFAULT_COMPANY_STATUS, ERROR, GET, PATCH, ROUTES, SUCCESS } from '../../../../services/constantService';
import { addHttps } from '../../../../utils/string';
import variables from '../../../../variables.scss';
import CustomPopover from '../../../common/CustomPopover';
import './index.scss';
// import contact_image from "../../../assets/images/default_contact.png";
import { enqueueSnackbar } from 'notistack';
import contact_image from '../../../../assets/images/default_contact.png';
import { convertArrayBufferToBlobUrl } from '../../../../utils/common';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import { useOfflimit } from '../../../../utils/Hooks/useOfflimit';
import MarkOffLimits from '../../../common/MarkOffLimits';
import ShowOffLimits from '../../../common/ShowOffLimits';

const AvatarStyle = withStyles({
  root: {
    height: '150px',
    width: '140px'
  }
})(Avatar);
const ViewCompanyHeader = props => {
  const {
    data,
    avatarSizeXs,
    avatarSizeMd,
    avatarSizeSm,
    mainContentWidthXs,
    mainContentWidthMd,
    mainContentWidthSm,
    buttonSizeXs,
    buttonSizeMd,
    buttonSizeSm,
    register,
    unregister,
    setValue = () => {},
    editingField,
    setEditingField = () => {},
    headerDrawer = () => {},
    getHeaderDetails = () => {},
    setIsEdited = () => {},
    setIsIconOpen = () => {},
    setIsHeaderNav = () => {},
    isHeaderNav,
    isIconOpen,
    setIsHeaderOpen = () => {},
    isHeaderOpen,
    options,
    updateField,
    companyId,
    setLoading,
    refreshImg,
    handleProjectClick
  } = props;
  // const url = useRef();
  const [anchorElType, setAnchorElType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(DEFAULT_COMPANY_STATUS);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { companyOffLimit, createCompanyOffLimit, getCompanyOffLimit, updateCompanyOffLimit, deleteCompanyOffLimit } = useOfflimit();
  const [anchorElAddByHoverPopup, setAnchorElAddByHoverPopup] = useState(null);
  const [isCompanyOffLimitPopopOpen, setIsCompanyOffLimitPopopOpen] = useState(false);
  const [showCompanyOffLimit, setShowCompanyOfflimit] = useState(false);
  const isCompanyOffLimitApplied = !!companyOffLimit.length > 0;
  const offLimitClass = useMemo(() => {
    const isHardLimitPresent = companyOffLimit?.find(offlimit => offlimit?.severity === 'hard');
    return isHardLimitPresent ? 'hard' : 'soft';
  }, [companyOffLimit]);
  const [profileUrl, setProfileUrl] = useState('');
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);

  const [isShowAddedBy, setIsShowAddedBy] = useState(true);
  const navigate = useNavigate();
  const { translateMessage } = useCustomMessageHook();

  // const [editing, setEditing] = useState(edit);
  const maxVisibleTags = 1;
  const commonLimitForOpenHeader = 30;
  // const commonLimitForOpenHeaderComments = 20;
  const commonLimitForcloseHeader = 20;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseCompanyOffLimitPopup = () => {
    setShowCompanyOfflimit(false);
    setIsCompanyOffLimitPopopOpen(false);
  };

  const handleOffLimitInfo = () => {
    setShowCompanyOfflimit(false);
  };

  const getLimitedString = (dataString, limit) => {
    if (typeof dataString === 'string' && dataString.length <= limit) {
      return dataString;
    } else if (typeof dataString === 'string') {
      const limitString = dataString.substring(0, limit) + '...';
      return <Tooltip title={dataString}>{limitString}</Tooltip>;
    } else {
      return '-';
    }
  };

  useEffect(() => {
    if (!companyId) return;
    getCompanyOffLimit(companyId);
  }, [companyId]);

  const handleAssignTag = () => {
    setIsTagPopupOpen(true);
  };
  useEffect(() => {
    register('profile_img');
    register('additionalDetails');
    register('first_name');
    register('last_name');
    register('contact_name');
    // register("company_status");
    return () => {
      unregister('profile_img');
      unregister('additionalDetails');
      unregister('first_name');
      unregister('last_name');
      unregister('contact_name');
      // unregister("company_status");
    };
  }, [register, unregister]);

  const open = Boolean(anchorEl);
  const openType = Boolean(anchorElType);

  const handleOffLimitClick = event => {
    setAnchorElType(event.currentTarget);
  };

  const handleOffLimitClose = () => {
    setAnchorElType(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  //const maxVisibleDescType = 1;
  const maxVisibleAddedBy = 1;

  const toggleHeader = () => {
    if (isHeaderOpen) {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-open');
      setIsHeaderNav('closed');
      headerDrawer('closed');
    } else {
      setIsHeaderOpen(!isHeaderOpen);
      setIsIconOpen('icon-closed');
      setIsHeaderNav('open');
      headerDrawer('open');
    }
  };
  const handleEditClick = () => {
    // setEditing((prevState) => ({ ...prevState, [type]: true }));
  };

  const openAddedByPopup = event => {
    setAnchorElAddByHoverPopup(event.currentTarget);
    if (isShowAddedBy) {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(event.currentTarget);
    } else {
      setIsShowAddedBy(!isShowAddedBy);
      setAnchorElAddByHoverPopup(null);
    }
  };

  const closeAddedByPopup = () => {
    setAnchorElAddByHoverPopup(null);
  };

  const handleMenuItemClick = item => {
    if (item === 'Business Development') {
      navigate(`/companies/${data?.name}/${companyId}/business-development/add`);
    } else {
      // navigate(`/projects/add-project`);
      console.log({ locationData: data });
      navigate(`${ROUTES.addProject}?companyId=${companyId}&companyName=${data?.name}`, { state: { ign_companies: { id: companyId, name: data?.name }, companyDropdownDisabled: true } });
      setValue('company_status', item?.field_value);
      // updateField("company_status");
      // handleOffLimitClose();
    }
  };

  const handleMenuItemClickStatus = async item => {
    const fieldValue = get(item, 'field_value');

    if (fieldValue === 'off_limits') {
      if (isCompanyOffLimitApplied) {
        setShowCompanyOfflimit(true);
      } else {
        setIsCompanyOffLimitPopopOpen(true);
      }
      handleOffLimitClose();
      return;
    }
    setSelectedItem(item?.short_desc);
    handleOffLimitClose();
    setLoading(true);
    const { status } = await ignCompanyDataApi(PATCH, { company_status: item?.field_value }, data?.id);
    if (status === 200) {
      setLoading(false);
      const message = translateMessage('Successfully', false, 'Company Status', 'updated');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      setLoading(false);
      const message = translateMessage('UnableMessage', false, 'Update', 'Company Status');
      enqueueSnackbar(message, { variant: ERROR });
    }
  };

  const handleProfileImage = e => {
    const file = e.target.files[0];
    if (file) {
      setValue('profile_img', e.target.files);
      const fr = new FileReader();
      fr.onload = function () {
        setProfileUrl(fr.result);
      };
      fr.readAsDataURL(file);
    }
  };
  const handleCloseClickProfileImg = () => {
    setEditingField('');
  };
  const openAddedbyViewMore = Boolean(anchorElAddByHoverPopup);

  useEffect(() => {
    if (companyId) {
      const fetchImages = async () => {
        const response = await ignCompanyDataApi(GET, null, companyId, 'image');
        if (response.status === 200) {
          const url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
          setProfileUrl(url);
        } else {
          setProfileUrl(contact_image);
        }
      };

      fetchImages();
    }
  }, [companyId, refreshImg]);

  const renderActions = (field, required = false, onCloseClick) => {
    return (
      <>
        <Box component='span' className='action-icon' onClick={() => updateField(field, required, onCloseClick)}>
          <DoneIcon className='fs-12' cursor='pointer' color='primary' />
        </Box>
        <Box component='span' className='action-icon' onClick={() => onCloseClick(field)}>
          <CloseIcon className='fs-12' cursor='pointer' color='error' />
        </Box>
      </>
    );
  };

  const onSubmitOffLimit = async (data, setIsLoading) => {
    const obj = { ...data, companyID: companyId };
    const isEditMode = showCompanyOffLimit;
    const companyOffLimitAction = isEditMode ? updateCompanyOffLimit : createCompanyOffLimit;
    await companyOffLimitAction(obj);
    setIsLoading(false);
    handleCloseCompanyOffLimitPopup();
  };

  const onDeleteCompanyOffLimit = async setIsLoading => {
    await deleteCompanyOffLimit(companyId);
    setIsLoading(false);
    handleCloseCompanyOffLimitPopup();
    handleOffLimitInfo();
  };

  return (
    <>
      <Box id='view-company-header-container' pb={2}>
        <Paper elevation={3} className={`activity-bars-head ${data?.disableEditing === false ? `header${isHeaderNav}` : 'logan-activity-head'}`}>
          {isHeaderNav === 'closed' ? (
            <Stack direction='row' spacing={0} className='main-sub-content-closed ml-1 d-flex align-items-center'>
              <Grid container spacing={0}>
                <Grid item xs={10} md={9} sm={9} lg={10} className='d-flex'>
                  <Box className='main-container d-flex align-items-center'>
                    <Box className={`avatar-preview ${!isCompanyOffLimitApplied && selectedItem === DEFAULT_COMPANY_STATUS ? 'border-green' : 'border-red'} mr-1`}>
                      <Box>
                        <Avatar sx={{ width: '28px', height: '28px' }} src={profileUrl} />
                      </Box>
                    </Box>
                    <Box component='span' className='header-font header-text fs-18 mr-2'>
                      {`${data?.name}`}
                    </Box>
                    <Box>
                      {data?.linkedin_url ? (
                        <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                          <Tooltip
                            title={'LinkedIn URL'}
                            placement='top'
                            TransitionComponent={Zoom}
                            arrow
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: { offset: [0, -5] }
                                  }
                                ]
                              }
                            }}
                          >
                            <LinkedInIcon className='linkedin-icon-header fs-14' />
                          </Tooltip>
                        </a>
                      ) : (
                        <Tooltip
                          title={'LinkedIn URL'}
                          placement='top'
                          TransitionComponent={Zoom}
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: { offset: [0, -5] }
                                }
                              ]
                            }
                          }}
                        >
                          {' '}
                          <LinkedInIcon className='disabled-link fs-14' />
                        </Tooltip>
                      )}
                    </Box>
                    <Box>
                      <Box display='flex' justifyContent='center' pl={1}>
                        {options && options.length > 0 && (
                          <Button
                            className={`off-limit-text-container ${isCompanyOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem?.short_desc === 'Active' ? 'button-green' : 'button-red'}`}
                            size='small'
                            aria-controls='offlimit-menu'
                            aria-haspopup='true'
                            onClick={handleOffLimitClick}
                            endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                          >
                            <Box component='span' className='off-limit-text fs-8'>
                              {selectedItem || options[0]?.short_desc}
                            </Box>
                            {isCompanyOffLimitApplied && (
                              <Box
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                                className='off-limit-indicator'
                              >
                                <Box>
                                  <WarningIcon className={`offlimit-caution ${isCompanyOffLimitApplied ? offLimitClass : ''}`} size='10px' />
                                </Box>
                              </Box>
                            )}
                          </Button>
                        )}
                      </Box>

                      {options && options.length > 1 && (
                        <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                          {options.map((item, index) => {
                            return (
                              <MenuItem key={index} onClick={() => handleMenuItemClickStatus(item)} className='fs-12'>
                                {item?.short_desc === 'Off-Limits' && isCompanyOffLimitApplied ? `View ${item?.short_desc}` : item?.short_desc}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      )}
                    </Box>
                    <Box></Box>
                    <Box>
                      <Box className='d-flex ml-2'>
                        {data?.additionalContent &&
                          Object.keys(data?.additionalContent)
                            .slice(0, 2)
                            .map(contentKey => (
                              <Box key={contentKey} className='mr-1'>
                                {data?.additionalContent[contentKey]?.icon || null}
                                <Box component='span'>{getLimitedString(data?.additionalContent[contentKey]?.text, commonLimitForcloseHeader)}</Box>
                              </Box>
                            ))}
                      </Box>
                    </Box>
                    <Box></Box>
                  </Box>
                </Grid>
                <Grid item xs={2} md={3} sm={3} lg={2} className='d-flex justify-content-center align-items-center'>
                  <Box>
                    <Button
                      variant='outlined'
                      className='add-to-button'
                      size='small'
                      aria-controls='simple-menu'
                      aria-haspopup='true'
                      onClick={handleClick}
                      endIcon={<ExpandMoreIcon className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                    >
                      <Typography>{data?.addButtonLabel || ''}</Typography>
                    </Button>
                    {data?.actionItems ? (
                      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                        {data?.actionItems &&
                          data?.actionItems.map((item, index) => (
                            <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                              {item}
                            </MenuItem>
                          ))}
                      </Menu>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          ) : (
            <Stack direction='row' spacing={0} className='main-sub-content ml-1'>
              <Grid container spacing={0}>
                {/* **************profile-pic action button***********start: expand ***/}
                <Grid item xs={avatarSizeXs} md={avatarSizeMd} sm={avatarSizeSm} className='d-flex'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {data?.avatar && data?.disableEditing ? (
                      <Box className={`avatar-preview ${!isCompanyOffLimitApplied && selectedItem === 'Active' ? 'border-green' : 'border-red'} mr-1`}>
                        <Box id='imagePreview'>
                          <AvatarStyle className='log-an-activity-profile-image' src={profileUrl} />
                        </Box>
                      </Box>
                    ) : (
                      <Box className='avatar-upload'>
                        <Box className='avatar-edit'>
                          <input
                            type='file'
                            id='imageUpload'
                            accept='.png, .jpg, .jpeg'
                            // onClick={(e) => {
                            //   e.target.value = null;
                            //   setNewProfilePic(null)
                            // }}
                            onClick={() => {
                              setEditingField('profile_img');
                            }}
                            onChange={e => handleProfileImage(e)}
                            className='d-none'
                          />
                          <InputLabel htmlFor='imageUpload'>
                            <Box>
                              <EditIcon className='editIcon fs-40' />
                            </Box>
                          </InputLabel>
                        </Box>
                        <Box position={'relative'}>
                          <Box className={`avatar-preview ${!isCompanyOffLimitApplied && selectedItem === 'Active' ? 'border-green' : 'border-red'} mr-1`}>
                            <Box id='imagePreview'>
                              <AvatarStyle className='image-size' src={profileUrl} />
                            </Box>
                          </Box>
                          {editingField === 'profile_img' && (
                            <Box position={'absolute'} top={0} right={-12}>
                              {renderActions('profile_img', true, handleCloseClickProfileImg)}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}

                    <Box display='flex' justifyContent='center' pt={1}>
                      {options && options.length > 0 && (
                        <Button
                          className={`off-limit-text-container-collapse ${isCompanyOffLimitApplied ? `offLimit-border-${offLimitClass}` : selectedItem === 'Active' ? 'button-green' : 'button-red'}`}
                          size='small'
                          aria-controls='offlimit-menu'
                          aria-haspopup='true'
                          onClick={handleOffLimitClick}
                          endIcon={<ExpandMoreIcon className={`type-icon ${openType ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                        >
                          <Box component='span' className='off-limit-text fs-8'>
                            {selectedItem || options[0]?.short_desc}
                          </Box>
                          {isCompanyOffLimitApplied && (
                            <Box
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              className='off-limit-indicator'
                            >
                              <Box>
                                <WarningIcon className={`offlimit-caution ${isCompanyOffLimitApplied ? offLimitClass : ''}`} size='10px' />
                              </Box>
                            </Box>
                          )}
                        </Button>
                      )}
                    </Box>

                    {options && options.length > 1 && (
                      <Menu id='offlimit-menu' anchorEl={anchorElType} keepMounted open={openType} onClose={handleOffLimitClose}>
                        {options.map((item, index) => (
                          <MenuItem key={index} onClick={() => handleMenuItemClickStatus(item)} className='fs-12'>
                            {item?.short_desc === 'Off-Limits' && isCompanyOffLimitApplied ? `View ${item?.short_desc}` : item?.short_desc}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </div>
                </Grid>
                {/* **************profile-pic action button**********end: expand ****/}
                {/* **************middle-content**********start: expand ****/}
                <Grid item xs={mainContentWidthXs} md={mainContentWidthMd} sm={mainContentWidthSm} pl={4} pt={2}>
                  {/* **************Name-linkedin-delete-icon**********start: expand ****/}
                  <Box className='d-flex align-items-center'>
                    <Box component='span' className='header-font header-text fs-18 mr-2' onDoubleClick={() => handleEditClick('first_name')}>
                      {`${data?.name}`}
                    </Box>
                    {data?.linkedin_url ? (
                      <a target='_blank' rel='noopener noreferrer' href={addHttps(data?.linkedin_url)}>
                        <Tooltip
                          title={'LinkedIn URL'}
                          placement='top'
                          TransitionComponent={Zoom}
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: { offset: [0, -5] }
                                }
                              ]
                            }
                          }}
                        >
                          <LinkedInIcon className='linkedin-icon-header fs-14' />
                        </Tooltip>
                      </a>
                    ) : (
                      <Tooltip
                        title={'LinkedIn Url'}
                        placement='top'
                        TransitionComponent={Zoom}
                        arrow
                        slotProps={{
                          popper: {
                            modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                          }
                        }}
                      >
                        <LinkedInIcon className='disabled-link fs-14' />
                      </Tooltip>
                    )}
                    <Tooltip
                      title={'Edit Company'}
                      placement='top'
                      TransitionComponent={Zoom}
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                        }
                      }}
                    >
                      {data?.editIcon || null}
                    </Tooltip>

                    <Tooltip
                      title={'Delete Company'}
                      placement='top'
                      TransitionComponent={Zoom}
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [{ name: 'offset', options: { offset: [0, -5] } }]
                        }
                      }}
                    >
                      {data?.deleteIcon || null}
                    </Tooltip>
                  </Box>
                  {/* ***************Name-linkedin-delete-icon**********end: expand ****/}
                  <Divider />
                  <Box className='d-flex fs-12 mt-6'>
                    <Grid container spacing={0}>
                      {/* ***************FIRST COLUMN DATA (additional content)**********start: expand ****/}
                      <Grid item xs={6} md={4.5} sm={4} className={`${data?.additionalContentClass?.className}`}>
                        {data?.additionalContent &&
                          Object.keys(data?.additionalContent).map(contentKey => (
                            <Box key={contentKey} className='mt-2'>
                              <>
                                {data?.additionalContent[contentKey]?.icon || null}
                                <Box component='span' className='content-color'>
                                  {contentKey !== 'content3' ? (
                                    getLimitedString(data?.additionalContent[contentKey]?.text, commonLimitForOpenHeader)
                                  ) : data?.additionalContent[contentKey]?.text ? (
                                    <Link target='_blank' rel='noopener noreferrer' href={addHttps(data?.additionalContent[contentKey]?.text)} className='link'>
                                      {' '}
                                      <Box component='span' className='fs-12 content-color'>
                                        {getLimitedString(data?.additionalContent[contentKey]?.text, commonLimitForOpenHeader)}
                                      </Box>
                                    </Link>
                                  ) : (
                                    '-'
                                  )}
                                </Box>
                              </>
                            </Box>
                          ))}
                      </Grid>
                      {/* ***************FIRST COLUMN DATA**********end: expand ****/}
                      {data?.mainContent ? null : (
                        <>
                          {/* ***************SECOND COLUMN DATA (recent- engagement -tag)**********start: expand ****/}
                          <Grid item xs={6} md={3.5} sm={6}>
                            <Box className='mt-2 d-flex'>
                              <Box component='span' className='content-title fs-12 mr-2'>
                                {data?.tagsTitle || ''}
                              </Box>
                              <Box>
                                <Box className='tag-container'>
                                  {data?.company_tags.length !== 0 &&
                                    data?.company_tags.slice(0, maxVisibleTags).map((tag, index) => (
                                      <Box key={index} sx={{ color: tag?.ign_tag?.color }} className={'tag-border mr-2 '}>
                                        {tag?.ign_tag?.name}
                                      </Box>
                                    ))}
                                  <Box className='d-flex align-items-center justify-content-center font-weight-bold fs-10'>
                                    <Link
                                      component='button'
                                      onClick={() => {
                                        handleAssignTag();
                                      }}
                                      className='add-tags-btn fs-10'
                                    >
                                      +Tags
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            {data?.addedByTitle && (
                              <Box className='mt-2 d-flex'>
                                <Box component='span' className='content-title fs-12 mr-2'>
                                  {data?.addedByTitle || ''}
                                </Box>

                                {data?.contact_added_by?.length !== 0 ? (
                                  <Box className='d-flex'>
                                    {data?.company_added_by.slice(0, maxVisibleAddedBy).map((addedBy, index) => (
                                      <Box key={index} component='span' className='fs-12 mr-2'>
                                        {/* <Box component="span" pr={1}>
                                          {data.addedBy?.icon
                                            ? data.addedBy?.icon
                                            : ""}
                                        </Box> */}
                                        {addedBy?.added_user?.name || ''}
                                      </Box>
                                    ))}
                                    {data.company_added_by.length > maxVisibleAddedBy && (
                                      <Box className='fs-12 d-flex align-items-center'>
                                        <Box
                                          aria-owns={openAddedbyViewMore ? 'mouse-over-popover-added-by' : undefined}
                                          aria-haspopup='true'
                                          onClick={openAddedByPopup}
                                          sx={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer'
                                          }}
                                        >
                                          {`+${data?.company_added_by.length - maxVisibleAddedBy}`}
                                          <CustomPopover
                                            id='mouse-over-popover-added-by'
                                            open={openAddedbyViewMore}
                                            anchorEl={anchorElAddByHoverPopup}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'left'
                                            }}
                                            onClose={closeAddedByPopup}
                                          >
                                            <Box onClick={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
                                              {data?.company_added_by && (
                                                <Box
                                                  className='d-flex flex-column align-items-center overflow-auto'
                                                  sx={{
                                                    maxHeight: '75px',
                                                    height: 'fit-content'
                                                  }}
                                                >
                                                  {data?.company_added_by.slice(maxVisibleAddedBy).map((addedBy, index) => (
                                                    <Box
                                                      key={index}
                                                      component='span'
                                                      className='fs-12 p-1 m-1 rectangle-blue'
                                                      sx={{
                                                        borderRadius: '4px',
                                                        backgroundColor: variables.heading,
                                                        boxSizing: 'border-box',
                                                        color: variables.titleBarBackground
                                                      }}
                                                    >
                                                      <Box component='span' pr={1}>
                                                        {data?.addedBy?.icon ? data?.addedBy?.icon : ''}
                                                      </Box>
                                                      {addedBy?.added_user?.name || ''}
                                                    </Box>
                                                  ))}
                                                </Box>
                                              )}
                                            </Box>
                                          </CustomPopover>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  '-'
                                )}
                              </Box>
                            )}

                            <CompanyAssignTag
                              tagPopUp={data?.company_tags}
                              companyId={data?.id}
                              setIsPopupOpen={setIsTagPopupOpen}
                              isPopupOpen={isTagPopupOpen}
                              label={'select Tag'}
                              getHeaderDetails={getHeaderDetails}
                              setIsEdited={setIsEdited}
                            />
                          </Grid>
                          {/* ***************SECOND COLUMN DATA (recent- engagement -tag)**********end: expand ****/}
                          {/* ***************THIRD COLUMN DATA (added_by and project)**********start: expand ****/}
                          <Grid item xs={12} md={4} sm={12}>
                            <Box className='mt-2 d-flex'>
                              <Box component='span' className='content-title fs-12 mr-2'>
                                {data?.businessDevelopment.title || ''}
                              </Box>
                              <Box component='span' pr={1}>
                                {data?.businessDevelopment.text || ''}
                              </Box>
                            </Box>
                            <Box className='mt-2 d-flex'>
                              <Box component='span' className='content-title fs-12 mr-2'>
                                {data?.projects.title || ''}
                              </Box>
                              <Box component='span' pr={1}>
                                <Link component='button' variant='body2' onClick={handleProjectClick} className='project-count mr-2' sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                  {data?.projects.text || ''}
                                </Link>
                              </Box>
                            </Box>

                            {/*
                           <Box className="mt-2 d-flex">
                            <Box
                              component="span"
                              className="content-title fs-12 mr-2"
                            >
                              {data?.lastProject.title || ""}
                            </Box>
                            <Box component="span" pr={1}>
                              {data?.lastProject.text || ""}
                            </Box>
                          </Box> */}
                          </Grid>
                          {/* ***************THIRD COLUMN DATA (added_by and project)**********end: expand ****/}
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                {/* **************middle-content**********end: expand ****/}

                <Grid
                  item
                  xs={buttonSizeXs}
                  md={buttonSizeMd}
                  sm={buttonSizeSm}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Box>
                    <Button
                      variant='outlined'
                      className='add-to-button'
                      size='small'
                      aria-controls='simple-menu'
                      aria-haspopup='true'
                      onClick={handleClick}
                      endIcon={<ExpandMoreIcon className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} />}
                    >
                      <Typography>{data?.addButtonLabel || ''}</Typography>
                    </Button>
                    {data?.actionItems ? (
                      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                        {data?.actionItems &&
                          data?.actionItems.map((item, index) => (
                            <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                              {item}
                            </MenuItem>
                          ))}
                      </Menu>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Paper>
      </Box>

      {showCompanyOffLimit && (
        <ShowOffLimits
          showInherited={true}
          companyOnly={true}
          isPopupOpen={true}
          handleClose={handleOffLimitInfo}
          onEdit={() => {
            setIsCompanyOffLimitPopopOpen(true);
          }}
          data={companyOffLimit.map(entry => ({ ...entry, type: 'Inherited' }))}
        />
      )}

      {isCompanyOffLimitPopopOpen && (
        <MarkOffLimits
          data={companyOffLimit}
          isPopupOpen={true}
          editMode={showCompanyOffLimit}
          handleClose={handleCloseCompanyOffLimitPopup}
          onSubmit={onSubmitOffLimit}
          onDelete={onDeleteCompanyOffLimit}
        />
      )}

      {data?.disableEditing === false && (
        <Box id='view-header-icon' className='toggle-header' onClick={toggleHeader}>
          <ExpandMoreRoundedIcon cursor='pointer' className={`header-arrow-icon fs-20 ${isIconOpen}`} />
        </Box>
      )}
    </>
  );
};
ViewCompanyHeader.propTypes = {
  data: PropTypes.object,
  refreshImg: PropTypes.bool,
  avatarSizeXs: PropTypes.number,
  avatarSizeMd: PropTypes.number,
  avatarSizeSm: PropTypes.number,
  mainContentWidthXs: PropTypes.number,
  mainContentWidthMd: PropTypes.number,
  mainContentWidthSm: PropTypes.number,
  buttonSizeXs: PropTypes.number,
  buttonSizeMd: PropTypes.number,
  buttonSizeSm: PropTypes.number,
  updateField: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  headerDrawer: PropTypes.func,
  watch: PropTypes.func,
  editingField: PropTypes.string,
  setEditingField: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  setIsEdited: PropTypes.func,
  setIsHeaderBar: PropTypes.func,
  isHeaderBar: PropTypes.string,
  setIsIconOpen: PropTypes.func,
  isIconOpen: PropTypes.string,
  setIsHeaderOpen: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
  setIsHeaderNav: PropTypes.func,
  isHeaderNav: PropTypes.string,
  options: PropTypes.object,
  companyId: PropTypes.string,
  setLoading: PropTypes.func,
  handleProjectClick: PropTypes.func
};

export default ViewCompanyHeader;
