import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, Switch, TextField } from '@mui/material';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import RichText from '../../../../src/components/common/RichText/index.js';
import AddDocuments from '../../../components/AddContact/Components/Documents/AddDocuments.js';
import CustomButton from '../../../components/common/CustomButton/index.js';
import CustomPopup from '../../../components/common/CustomPopup/index.js';
import DatePickerCommon from '../../../components/common/DatePicker/DatePicker.js';
import Footer from '../../../components/common/Footer/index.js';
import ActivityTypeSelection from '../../../components/common/FunctionalComponents/ActivityTypeSelection/index.js';
import MarkNoteAsSelection from '../../../components/common/FunctionalComponents/MarkNoteAsSelection/MarkNoteAsSelection.js';
import ProjectTypeSelection from '../../../components/common/FunctionalComponents/ProjectTypeSelection/index.js';
import Loader from '../../../components/common/Loader/index.js';
import QuickAddContact from '../../../components/common/QuickAddContact/v1/QuickAddContact.js';
import { QUICK_ADD_CONTACT_COMMON_FIELD } from '../../../components/common/QuickAddContact/v1/utils/Constants.js';
// import useTranscribe from '../../../components/common/Transcribe/index.js';
import DateTimeTimezonePickers from '../../../components/common/DateTimeTimezonePickers/DateTimeTimezonePickers.js';
import { getUserSelector, logAnActivitySelector } from '../../../selectors/index.js';
import { ignContactDataApi, logAnActivityApi } from '../../../services/ApiService.js';
import { ERROR, GET, POST, SUCCESS } from '../../../services/constantService.js';
import { logAnActivityActions } from '../../../store/logAnActivitySlice.js';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook.js';
import { TRANS_KEYS } from '../../../utils/languageNamespaces.js';
import MultipleSelect from './MultiSelectDropdown/AttendeesDropdown.js';

export default function LogAnActivityTab(props) {
  const {
    register = () => {},
    unregister = () => {},
    setValue = () => {},
    getValues = () => {},
    onHandleSave = () => {},
    handleSubmit = () => {},
    enqueueSnackbar,
    onClose = () => {},
    isViewCloseIcon,
    getContact = () => {},
    watch = () => {},
    reset = () => {},
    ifForCompany = false,
    gotoRecentActivity = () => {},
    control = () => {},
    project_id
  } = props;
  const userData = useSelector(getUserSelector);
  const logData = useSelector(logAnActivitySelector);
  const dispatch = useDispatch();

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_ACTIVITY_TYPE,
    TRANS_KEYS.CONTACTS_PROJECT_NUMBER,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER_ACTIVITY,
    TRANS_KEYS.CONTACTS_NOTES,
    TRANS_KEYS.CONTACTS_UPDATE_DOCUMENT,
    TRANS_KEYS.CONTACTS_ADD_DOCUMENT,
    TRANS_KEYS.CONTACTS_FILE_NAME,
    TRANS_KEYS.CONTACTS_SELECT_TYPE
  ]);

  useEffect(() => {
    register('activity_type');
    register('attachment');
    register('activity_added_at');
    register('project_id');
    register('notes_type');
    register('notes');
    register('created_by');
    register('updated_by');
    register('internal_attendees');
    register('client_attendees');
    register('user_attendees');
    register('interview_date');
    register('interview_date_timezone');
    register('send_ical');

    setValue('send_ical', false);

    return () => {
      unregister('activity_type');
      unregister('attachment');
      unregister('activity_added_at');
      unregister('project_id');
      unregister('notes_type');
      unregister('notes');
      unregister('created_by');
      unregister('updated_by');
      unregister('internal_attendees');
      unregister('user_attendees');
      unregister('interview_date');
      unregister('interview_date_timezone');
      unregister('send_ical');
    };
  }, [register, unregister]);

  const [editActivity, setEditActivity] = useState(null);
  const [, setNotes] = useState(null);
  const [projectNumber, setProjectNumber] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [doc, setDoc] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const date = moment.utc().local().format();
    setDateTime(date);
    setValue('activity_added_at', date);
    setValue('created_by', userData.id);
    setValue('updated_by', userData.id);
    const timezone = editActivity?.interview_date_timezone || moment.tz.guess();
    setValue('interview_date_timezone', timezone);

    return () => {
      dispatch(logAnActivityActions.resetEditId());
    };
  }, []);

  useEffect(() => {
    setValue('attachment', attachment);
  }, [attachment]);

  useEffect(() => {
    if (logData) {
      logAnActivityApi(GET, null, null, `activity/${logData}?singleActivity=true`).then(data => {
        const activity = data?.data?.activity;

        if (!activity?.activity_type) {
          return;
        }
        console.log('🚀 ~ logAnActivityApi ~ activity:', activity);
        setEditActivity(activity);
        const timezone = activity?.interview_date_timezone || moment.tz.guess();
        setValue('interview_date_timezone', timezone);
        let bodyFormData = new FormData();
        let fileNameSplit = activity?.attachment?.file_name?.split('.') || [];
        bodyFormData.append('is_confidential', activity.attachment?.is_confidential);
        bodyFormData.append('file_name', `${activity.attachment?.file_name}.${fileNameSplit[fileNameSplit?.length - 1]}`);
        bodyFormData.append('file_type', activity.attachment?.file_type);
        let emptyBlob = new Blob([''], { type: 'application/octet-stream' });
        setDoc(activity.attachment?.file_name);
        bodyFormData.append('file', emptyBlob);

        setValue('attachment', bodyFormData);
      });
    }
  }, [logData]);

  const uploadDocuments = async document => {
    if (!document) {
      enqueueSnackbar('Attachment data not fill', { variant: ERROR });
      return;
    }

    if (!document.file_type || !document.file_name) {
      enqueueSnackbar('Select Document type And Name', { variant: ERROR });
      return;
    }

    if (!isEdit) {
      if (!document.file) {
        enqueueSnackbar('Select File', { variant: ERROR });
        return;
      } else {
        setDoc(document.file.name);
      }
      let fileNameSplit = document.file?.name?.split('.');
      let bodyFormData = new FormData();
      bodyFormData.append('is_confidential', document.is_confidential);
      bodyFormData.append('file_name', `${document.file_name}.${fileNameSplit[fileNameSplit.length - 1]}`);
      bodyFormData.append('file_type', document.file_type);
      bodyFormData.append('file', document.file);
      bodyFormData.append('ign_contact_attachment_visibilities', JSON.stringify(document.ign_contact_attachment_visibilities));
      setAttachment(bodyFormData);
      setValue('attachment', bodyFormData);
      setIsPopupOpen(false);
    } else {
      enqueueSnackbar('Attachment', { variant: ERROR });
    }
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const validateLogAnActivityFields = () => {
    // Validating all the mandatory field.
    const logAnActivityData = getValues();
    return logAnActivityData?.activity_type?.fields?.some(field => {
      if (field.mandatory) {
        if (field.field === 'project-id') {
          if (!logAnActivityData.project_id) {
            enqueueSnackbar('ProjectId is a required field', { variant: 'error' });
            return true;
          }
        } else if (field.field === 'dictation') {
          if (!logAnActivityData.notes) {
            enqueueSnackbar('Notes is a required field', { variant: 'error' });
            return true;
          }
        } else if (field.field === 'description') {
          if (!logAnActivityData.description) {
            enqueueSnackbar('Description is a required field', { variant: 'error' });
            return true;
          }
        } else if (field.field === 'date-time') {
          if (!logAnActivityData.interview_date || !logAnActivityData.interview_date_timezone) {
            enqueueSnackbar('Interview Date and Timezone is a required field', { variant: 'error' });
            return true;
          }
        } else if (field.field === 'attendees') {
          var attendeesList = logAnActivityData.client_attendees + logAnActivityData.internal_attendees + logAnActivityData.user_attendees;
          if (!attendeesList || attendeesList.length === 0) {
            enqueueSnackbar('Attendees is a required field', { variant: 'error' });
            return true;
          }
        } else if (field.field === 'due-date') {
          if (!logAnActivityData.due_date) {
            enqueueSnackbar('Due Date is a required field', { variant: 'error' });
            return true;
          }
        } else if (field.field === 'send-ical') {
          if (logAnActivityData.send_ical !== true && logAnActivityData.send_ical !== false) {
            enqueueSnackbar('Send iCal is a required field', { variant: 'error' });
            return true;
          }
        }
      }

      return false;
    });
  };

  const onSave = async () => {
    if (validateLogAnActivityFields()) {
      return;
    }
    let result;
    if (isChecked === true) {
      result = await onHandleSave('continue');
    } else {
      result = await onHandleSave('save');
    }
    if (result) {
      setNotes(null);
      if (isChecked !== true) {
        setProjectNumber(null);
      }
      setIsChecked(false);
      setSelectedOption(null);
      setDoc(null);
      const date = moment.utc().local().format();
      setDateTime(date);
      getContact();
    }
  };

  return (
    <Box id='activity-tab'>
      {isPopupOpen && (
        <AddDocuments
          title={`${isEdit ? `${t(`${TRANS_KEYS.CONTACTS_UPDATE_DOCUMENT}:key`)}` : `${t(`${TRANS_KEYS.CONTACTS_ADD_DOCUMENT}:key`)}`} `}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          register={register}
          unregister={unregister}
          handleSave={uploadDocuments}
          setValue={setValue}
          data={null}
          className='file-uploader'
        ></AddDocuments>
      )}

      <CustomPopup
        title={'Are you sure '}
        open={isCancelPopupOpen}
        onClose={() => {
          setIsCancelPopupOpen(false);
        }}
        cancelText={'No'}
        width={'300px'}
        handleSubmit={() => {
          setNotes(null);
          setProjectNumber(null);
          setIsChecked(false);
          setSelectedOption(null);
          setDoc(null);
          const date = moment.utc().local().format();
          setDateTime(date);
          getContact();
          reset();
          gotoRecentActivity();
          dispatch(logAnActivityActions.resetEditId());
          setIsCancelPopupOpen(false);
        }}
        confirmText={'Yes'}
        showAction={true}
      ></CustomPopup>

      <form>
        {/*TODO: AYAN */}
        <Stack spacing={2}>
          <Box className='box-width w-100'>
            <ActivityTypeSelection
              editActivity={editActivity}
              label={t(`${TRANS_KEYS.CONTACTS_SELECT_TYPE}:key`)}
              onChange={(e, data) => {
                // setActivityTypes(data?.field_value);
                setValue('activity_type', data);
              }}
              defaultValue={watch('activity_type')}
              className={`w-100 ${isViewCloseIcon ? 'autocomplete-outside' : 'autocomplete-inside'}`}
            />
          </Box>

          {watch('activity_type') && (
            <>
              <RenderActivityTypes
                ifForCompany={ifForCompany}
                projectState={[projectNumber, setProjectNumber]}
                formState={{
                  setValue,
                  getValues,
                  handleSubmit,
                  watch,
                  register,
                  control
                }}
                selectedOptionState={[selectedOption, setSelectedOption]}
                isViewCloseIcon={isViewCloseIcon}
                dateTimeState={[dateTime, setDateTime]}
                docState={[doc, setDoc]}
                onSave={onSave}
                onClose={onClose}
                setNotes={setNotes}
                handleOpenPopup={handleOpenPopup}
                isChecked={isChecked}
                setIsCancelPopupOpen={setIsCancelPopupOpen}
                project_id={project_id}
                editActivity={editActivity}
              />
              <Box>
                {isChecked === true ? (
                  <Footer onSubmit={handleSubmit(onSave)} submitText={'Save & Continue'} onClose={onClose}></Footer>
                ) : (
                  <Footer
                    onSubmit={handleSubmit(onSave)}
                    onClose={() => {
                      setIsCancelPopupOpen(true);
                    }}
                  ></Footer>
                )}
              </Box>
            </>
          )}
        </Stack>
      </form>
    </Box>
  );
}

export const RenderActivityTypes = ({ ifForCompany, projectState, formState, isViewCloseIcon, selectedOptionState, dateTimeState, docState, setNotes, handleOpenPopup, project_id, editActivity }) => {
  const [projectNumber, setProjectNumber] = projectState;
  const { setValue, getValues, watch } = formState;
  const { register: registerQAC, handleSubmit: handleSubmitQAC, control: controlQAC, setValue: setValueQAC, getValues: getValuesQAC } = useForm();
  const [selectedOption, setSelectedOption] = selectedOptionState;
  const [dateTime, setDateTime] = dateTimeState;
  const [doc, setDoc] = docState;
  const [open, setOpen] = useState(false);
  const [openQuickAddContact, setOpenQuickAddContact] = useState();
  const [selectedProjectCompany, setSelectedProjectCompany] = useState();
  const [disabled, setDisabled] = useState(false);
  const [quickAddContactProcessing, setQuickAddContactProcessing] = useState(false);
  const [personName, setPersonName] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_ACTIVITY_TYPE,
    TRANS_KEYS.CONTACTS_PROJECT_NUMBER,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER_ACTIVITY,
    TRANS_KEYS.CONTACTS_NOTES,
    TRANS_KEYS.CONTACTS_UPDATE_DOCUMENT,
    TRANS_KEYS.CONTACTS_ADD_DOCUMENT,
    TRANS_KEYS.CONTACTS_FILE_NAME,
    TRANS_KEYS.CONTACTS_SELECT_TYPE
  ]);
  // const { TranscribeButton, SummariseButton, ViewTranscribedText, TranscribePopup, transcribedText, setTranscribedText } = useTranscribe({
  //   defaultTranscribedText: editActivity?.original_text ?? ''
  // });

  // useEffect(() => {
  //   setValue('notes', transcribedText);
  // }, [transcribedText]);

  useEffect(() => {
    if (editActivity) {
      handleChangeRichText(editActivity?.notes);

      if (editActivity?.attendees?.length > 0) {
        const internal_attendees = [];
        const client_attendees = [];
        const user_attendees = [];

        const personNames = [];
        for (const attendee of editActivity.attendees) {
          if (attendee.contact_user_id !== null) {
            user_attendees.push({ id: attendee.contact_user_id, title: attendee.contact_user?.name, type: 'all-users' });
            personNames.push(items.find(item => item.id === attendee.contact_user_id));
          }
          if (attendee.client_contact_id !== null) {
            client_attendees.push({ id: attendee.client_contact_id, title: attendee.client_contacts?.name, type: 'client-team' });
            personNames.push(items.find(item => item.id === attendee.client_contact_id));
          }
          if (attendee.contact_id !== null) {
            internal_attendees.push({ id: attendee.contact_id, title: attendee.client_contacts?.name, type: 'project-team' });
            personNames.push(items.find(item => item.id === attendee.contact_id));
          }
        }

        setPersonName(personNames);
        setValue('internal_attendees', internal_attendees);
        setValue('client_attendees', client_attendees);
        setValue('user_attendees', user_attendees);
      }
    }
  }, [editActivity, items]);

  const handleChangeRichText = data => {
    setNotes(data);
    // setTranscribedText('body', data);
    setValue('description', data);
    setValue('notes', data);
  };

  const timeZoneAbbrivation = () => {
    const userTimeZone = moment.tz.guess();
    const timeZoneAbbr = moment.tz(userTimeZone).zoneAbbr();
    const offset = moment.tz(userTimeZone).utcOffset();
    const offsetHours = offset / 60;
    const offsetSign = offsetHours >= 0 ? '+' : '-';
    const offsetHoursAbs = Math.floor(Math.abs(offsetHours));
    const offsetMinutes = offset % 60;
    const offsetString = `${timeZoneAbbr} (UTC${offsetSign}${offsetHoursAbs}:${offsetMinutes}), `;
    return offsetString;
  };

  const isFieldActive = fieldName => {
    return watch('activity_type')?.fields.some(fieldData => fieldData.field === fieldName);
  };

  const isFieldMandatory = fieldName => {
    const field = watch('activity_type')?.fields.find(fieldData => fieldData.field === fieldName);
    console.log('...))', field);
    return field?.mandatory ? field?.mandatory : false;
  };

  const addQuickContact = () => {
    setOpenQuickAddContact(true);
  };

  const handleClose = () => {
    setOpenQuickAddContact(false);
  };

  // Variable to extra fields in QuickAddContact
  const newFields = () => {
    return (
      <>
        <TextField
          // {...register(QUICK_ADD_CONTACT_COMMON_FIELD.company, { required: true })}
          required
          variant='outlined'
          placeholder='Company'
          defaultValue={selectedProjectCompany?.name}
          value={selectedProjectCompany?.name}
          label={'Company'}
          // onChange={e => {
          //   setValue(QUICK_ADD_CONTACT_COMMON_FIELD.company, e.target.value);
          // }}
          disabled={true}
        />
      </>
    );
  };

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().optional(),
    linkedin: yup.string().url().optional(),
    phonenumber: yup
      .string()
      .transform((value, originalValue) => {
        // Coerce empty string or null to null
        if (originalValue === '' || originalValue === null) return null;
        return value;
      })
      .matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid')
      .nullable(),
    jobtitle: yup.string().optional(),
    company: yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required()
    })
  });

  const clearAddContactInputs = () => {
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.firstName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.firstName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.last_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.lastName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_phones, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.email, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_emails, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin_url, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.jobtitle, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title, null);
    setSelectedProjectCompany(null);
  };

  const onAddSubmit = (contact, allowDuplicate = false) => {
    schema
      .validate(contact)
      .then(async () => {
        try {
          if (!contact?.email && !contact?.linkedin && !contact?.phonenumber) {
            enqueueSnackbar('Email/LinkedIn/Phone number is required.', { variant: ERROR });
          } else if (!contact?.company || !contact?.company?.id || !contact?.company?.name) {
            enqueueSnackbar('Company is required', { variant: ERROR });
          } else {
            setDisabled(true);
            setQuickAddContactProcessing(true);
            contact.current_company_id = selectedProjectCompany.id;
            const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
            const updatedEmail = contact.contact_emails?.map(emailObj => ({
              ...emailObj,
              email_type: emailObj.email_type?.field_value || 'Personal'
            }));
            const updatedPhone = contact.contact_phones?.map(phoneObj => ({
              ...phoneObj,
              phone_type: phoneObj.phone_type?.field_value || 'Personal'
            }));
            const updatedContact = { ...contact, contact_emails: updatedEmail, contact_phones: updatedPhone };
            const final = updatedContact;
            const { status, data } = await ignContactDataApi(POST, '', final, sub_route);
            if (status === 500) {
              enqueueSnackbar(data.message, { variant: ERROR });
            } else {
              let fullName = `${final[QUICK_ADD_CONTACT_COMMON_FIELD.first_name]} ${final[QUICK_ADD_CONTACT_COMMON_FIELD.last_name]}`;
              setPersonName([...personName, { id: data.id, title: fullName, type: 'contact-team' }]);
              setItems([...items, { id: data.id, title: fullName, type: 'contact-team' }]);
              let contact_attendees = getValues()['contact_attendees'] || [];
              setValue('contact_attendees', [...contact_attendees, { id: data.id, title: fullName, type: 'contact-team' }]);
              enqueueSnackbar('Contact added successfully', { variant: SUCCESS });
            }
          }
        } catch (err) {
          enqueueSnackbar(err, { variant: ERROR });
        } finally {
          setDisabled(false);
          clearAddContactInputs();
          setOpenQuickAddContact(false);
          setQuickAddContactProcessing(false);
          // clearInputs();
        }
      })
      .catch(err => {
        enqueueSnackbar(err, { variant: ERROR });
        setDisabled(false);
      });
  };

  return (
    <Stack spacing={2}>
      <Loader show={quickAddContactProcessing} />
      {!ifForCompany && isFieldActive('project-id') && (
        <Box className='box-width w-100'>
          <ProjectTypeSelection
            editActivity={editActivity}
            project_id={editActivity?.project_id ?? project_id}
            defaultValue={projectNumber}
            label={t('project.projectNumber')}
            onChange={(e, data) => {
              setProjectNumber(data?.name);
              setValue('project_id', data?.id);
              setSelectedProjectCompany(data?.ign_companies);
              setValueQAC(QUICK_ADD_CONTACT_COMMON_FIELD.company, data?.ign_companies);
            }}
            className={`w-100 ${isViewCloseIcon ? 'autocomplete-outside' : 'autocomplete-inside'}`}
            required={isFieldMandatory('project-id')}
          />
        </Box>
      )}

      {isFieldActive('due-date') && ( //TODO: AYAN
        <DatePickerCommon
          label='Due Date'
          value={editActivity?.due_date ?? null}
          format={'YYYY/MM/DD'}
          onChange={date => {
            console.log('🚀 ~ RenderActivityTypes ~ date:', date);
            setValue('due_date', date);
          }}
          className='w-100'
          required={isFieldMandatory('due-date')}
        />
      )}

      {isFieldActive('date-time') && (
        <DateTimeTimezonePickers
          value={watch('interview_date') || editActivity?.interview_date}
          defaultValue={watch('interview_date') || editActivity?.interview_date}
          timezone={watch('interview_date_timezone') || editActivity?.interview_date_timezone}
          timezoneOptions={moment.tz.names()}
          onChange={date => {
            setValue('interview_date', date);
          }}
          handleTimezoneChange={timezone => {
            setValue('interview_date_timezone', timezone.target.value);
          }}
          required={isFieldMandatory('date-time')}
          timezoneRequired={isFieldMandatory('date-time')}
          label={'Interview Date'}
        />
      )}
      {isFieldActive('attendees') && (
        <>
          <MultipleSelect
            label='Attendees'
            className='w-100'
            projectId={getValues('project_id')}
            type={watch('activity_type')?.fields.find(field => field.field === 'attendees')}
            addQuickContact={addQuickContact}
            isQuickAddContact={true}
            onChange={e => {
              // setValue("attendees", e.target.value);
              if (e.target.value) {
                const internalAttendees = e.target.value.filter(item => item?.type === 'project-team');
                const clientAttendees = e.target.value.filter(item => item?.type === 'client-team');
                const userAttendees = e.target.value.filter(item => item?.type === 'all-users');
                setValue('internal_attendees', internalAttendees);
                setValue('client_attendees', clientAttendees);
                setValue('user_attendees', userAttendees);
              }
            }}
            personName={personName}
            setPersonName={setPersonName}
            items={items}
            setItems={setItems}
            size='small'
            required={isFieldMandatory('attendees')}
          />
          <QuickAddContact
            useFormController={{ register: registerQAC, handleSubmit: handleSubmitQAC, control: controlQAC, setValue: setValueQAC, getValues: getValuesQAC }}
            open={openQuickAddContact}
            setOpen={setOpenQuickAddContact}
            handleClose={handleClose}
            onAddSubmit={onAddSubmit}
            newFields={newFields}
            isAddDisabled={disabled}
          />
        </>
      )}

      {isFieldActive('send-ical') && (
        <Box className='my-3 fs-14 align-items-center'>
          <Switch
            checked={watch('send_ical')}
            onChange={e => {
              setValue('send_ical', e.target.checked);
            }}
          />{' '}
          Send iCal
        </Box>
      )}

      {isFieldActive('dictation') && (
        <Box className='activity-tab-container'>
          <Box className={'fs-14 note-label mb-0'}>
            {t(`${TRANS_KEYS.CONTACTS_NOTES}:key`)}{' '}
            {isFieldMandatory('dictation') && (
              <Box component={'span'} className={'text-danger'}>
                {' '}
                *{' '}
              </Box>
            )}
          </Box>
          <Box className='d-flex flex-row align-items-center'>
            {!doc ? (
              <CustomButton
                variant='outlined'
                type={'secondary'}
                size={'small'}
                width={32}
                customWidth={32}
                onClick={handleOpenPopup}
                iconLeft={
                  <AttachmentOutlinedIcon
                    sx={{
                      width: 18,
                      height: 18,
                      transform: 'rotate(135deg)'
                    }}
                  />
                }
              />
            ) : (
              <Box className='notes-header'>
                <Box component='div' className='fs-14 m-2 color-primary-main'>
                  {t(`${TRANS_KEYS.CONTACTS_FILE_NAME}:key`)}:{doc}
                </Box>
                <CloseIcon
                  onClick={() => {
                    setDoc(null);
                  }}
                  className='close-icon-style fs-20'
                />
              </Box>
            )}

            <MarkNoteAsSelection
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              editActivityNote={editActivity?.notes_type}
              size={'small'}
              onChange={noteType => {
                setValue('notes_type', noteType);
              }}
            />
          </Box>
        </Box>
      )}
      {isFieldActive('dictation') && (
        <RichText
          defaultValue={editActivity?.notes ?? ''}
          onChange={data => {
            handleChangeRichText(data);
          }}
          editActivity={editActivity}
          required={isFieldMandatory('dictation')}
        />
      )}
      {/* <Box className='d-flex align-items-center'>
        <Box component='div' className='fs-12'>
          {!open ? (
            <Box>
              {timeZoneAbbrivation() + moment(dateTime).utc().local().format(DATE_TIME_FORMAT)}
              <EditIcon
                onClick={() => {
                  setDateTime(null);
                  setOpen(true);
                }}
                className='ml-2 fs-14 cursor-pointer'
              />
            </Box>
          ) : (
            <DateTimePickers
              defaultValue={formatDate(moment(new Date()).utc().local(), 'YYYY-MM-DDTHH:mm')}
              onChange={date => {
                if (date) {
                  const originalDate = new Date(date.$d);
                  const dateData = moment(originalDate).utc().local().format();
                  setDateTime(dateData);
                  setValue('activity_added_at', dateData);
                  setOpen(false);
                }
              }}
            />
          )}
        </Box>
      </Box> */}
    </Stack>
  );
};
LogAnActivityTab.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  onHandleSave: PropTypes.func,
  handleSave: PropTypes.func,
  handleSubmit: PropTypes.func,
  control: PropTypes.func,
  getContact: PropTypes.func,
  contactId: PropTypes.string,
  currentValues: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  onClose: PropTypes.func,
  isViewCloseIcon: PropTypes.bool,
  watch: PropTypes.func,
  ifForCompany: PropTypes.bool,
  reset: PropTypes.func,
  gotoRecentActivity: PropTypes.func,
  project_id: PropTypes.string
};

RenderActivityTypes.propTypes = {
  ifForCompany: PropTypes.bool,
  projectState: PropTypes.array,
  formState: PropTypes.object,
  isViewCloseIcon: PropTypes.bool,
  selectedOptionState: PropTypes.array,
  dateTimeState: PropTypes.array,
  docState: PropTypes.array,
  setNotes: PropTypes.func,
  handleOpenPopup: PropTypes.func,
  project_id: PropTypes.string,
  editActivity: PropTypes.object
};
