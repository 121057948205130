import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ProjectTypeSelection from '../../../components/common/FunctionalComponents/ProjectTypeSelection/index.js';
import { BulkCandidateCreateApi, ProjectCandidateDataApi } from '../../../services/ApiService';
import { ERROR, POST, SUCCESS, WARNING } from '../../../services/constantService';
import { successMessage } from '../../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../../utils/common';
import '../../../utils/common.scss';
import { useOfflimit } from '../../../utils/Hooks/useOfflimit/index.js';
import { getOffLimitFromSummary } from '../../../utils/Hooks/useOfflimit/useOfflimitHelpers.js';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomButton from '../CustomButton';
import CustomPopup from '../CustomPopup';
import StageSelection from '../FunctionalComponents/StageSelection';
import Loader from '../Loader';
import OffLimitWarning from '../OffLimitWarning/index.js';
import './index.scss';
import { CONSANTS_MARK_OFF_LIMITS } from '../MarkOffLimits/constant.js';

const AddContactToProject = props => {
  const { data, isPopupOpen, handleClose = () => {}, multiple = false, fetchOffLimit } = props;
  const { register, setValue, getValues, watch } = useForm();
  const { getOfflimitBasedOnStatus } = useOfflimit();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [offLimitData, setOffLimitData] = useState([]);
  const [isModaOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    register('stage');
    register('project');
  }, [register]);

  const checkOffLimits = async () => {
    setIsLoading(true);
    try {
      const offLimitResults = await Promise.all(data.map(contact_id => getOffLimitFromSummary(contact_id)));
      const isOffLimitApplied = offLimitResults.some(offlimit => offlimit.some(off => off.severity === CONSANTS_MARK_OFF_LIMITS.HARD));

      if (isOffLimitApplied) {
        handleClose();
        enqueueSnackbar('Off-limits detected for one or more contacts. Unable to add to project.', { variant: WARNING });
      }
    } catch (error) {
      handleClose();
      enqueueSnackbar('Error checking off limits', { variant: ERROR });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (multiple) {
      checkOffLimits();
    }
  }, [multiple]);
  useEffect(() => {
    if (!fetchOffLimit) return;
    if (!data?.id) return;
    async function getData() {
      setIsLoading(true);
      const res = await getOfflimitBasedOnStatus(data?.id);
      setIsLoading(false);
      setOffLimitData(res);
    }
    getData();
  }, [fetchOffLimit, data?.id]);

  const CreateSchema = yup.object().shape({
    project: yup.object().required(),
    stage: yup.string().required()
  });
  const cleanupPayload = formValue => {
    if (multiple) {
      const payload = data?.map(id => ({
        contact_id: id,
        project_id: formValue?.project?.id,
        stage: formValue?.stage
      }));

      return payload;
    }
    let payload = { ...formValue };
    payload['contact_id'] = data?.id;

    if (formValue.project) {
      payload['project_id'] = formValue?.project?.id;
    }
    return payload;
  };

  const handleCopyContactToProject = async () => {
    try {
      const formValue = getValues();
      let isValid = await CreateSchema.isValid(formValue);
      if (!isValid) {
        let requiredField = [
          { fieldName: 'project', label: 'Project', type: {} },
          { fieldName: 'stage', label: 'Stage', type: String }
        ];
        let dirtyField = customFormValidator(formValue, requiredField);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
      setIsLoading(true);
      const payload = cleanupPayload(formValue);
      const { status, data } = multiple
        ? await BulkCandidateCreateApi({
            candidates: payload
          })
        : await ProjectCandidateDataApi(POST, null, payload);
      if (status && status === 200) {
        const message = successMessage('Contacts added to project', `(${formValue?.project?.name})`);
        enqueueSnackbar(message, { variant: SUCCESS });
        setIsLoading(false);
        handleClose();
      } else {
        enqueueSnackbar(data.message || 'Failed to add to project', { variant: ERROR });
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSubmit = () => {
    if (!offLimitData?.length) {
      handleCopyContactToProject();
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseAll = () => {
    setIsModalOpen(false);
    handleClose();
  };

  return (
    <>
      <CustomPopup
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={isPopupOpen}
        title={t('project.addContactToProject')}
        // handleSubmit={handleSubmit}
      >
        <Loader show={isLoading} />
        <Box className='d-flex flex flex-column' gap={4} id='add-contact-project'>
          <Box className='head-content flex flex-column py-4'>
            <Box>
              <ProjectTypeSelection
                className='w-100'
                defaultValue={watch('project') || ''}
                label={t('project.chooseProject')}
                onChange={(e, data) => {
                  setValue('project', data ? data : null);
                }}
                required={true}
              />
            </Box>
            <Box>
              <Box className='input-data d-flex flex align-center'>
                <StageSelection
                  defaultValue={watch('stage') || ''}
                  onChange={(e, data) => {
                    setValue('project', data ? data : null);
                  }}
                  label={t('project.chooseStage')}
                  required={true}
                />
              </Box>
              <Box>
                <Box className='input-data d-flex flex align-center'>
                  <StageSelection
                    defaultValue={watch('stage') || ''}
                    onChange={(e, data) => {
                      setValue('stage', data?.stage_name ? data.stage_name : null);
                    }}
                    label={t(`${TRANS_KEYS.CONTACTS_CHOOSE_STAGE}:key`)}
                    required={true}
                  />
                </Box>
              </Box>
            </Box>
            <Box className='d-flex justify-content-end'>
              <CustomButton type={'primary'} size={'small'} buttonText={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)} customWidth={96} variant='contained' onClick={handleSubmit} />
            </Box>
          </Box>
          <Box className='d-flex justify-content-end'>
            <CustomButton type={'primary'} size={'small'} buttonText={t('actions.add')} customWidth={96} variant='contained' onClick={handleCopyContactToProject} />
          </Box>
        </Box>
      </CustomPopup>
      {isModaOpen && <OffLimitWarning open onClose={handleCloseAll} onConfirm={handleCopyContactToProject} offlimits={offLimitData ?? []} />}
    </>
  );
};

AddContactToProject.propTypes = {
  isPopupOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  multiple: PropTypes.bool,
  fetchOffLimit: PropTypes.bool
};
export default AddContactToProject;
