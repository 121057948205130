import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserSelector } from '../../selectors';
import { useEnforceSecurity } from '../../utils/Hooks/useEnforceSecurity';
import { useNavItems } from '../../utils/Hooks/useNavItems';
import withRouter from '../../utils/withRouter';
import './index.scss';

function SideNav(props) {
  // const location = useLocation()
  const navItems = useNavItems();
  const { location /* clientSuite */ } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isIconOpen, setIsIconOpen] = useState('rotate-icon-closed');
  const [isSideNav, setIsSideNav] = useState('closed');
  /* const [isAdmin, setIsAdmin] = useState(false); */
  const [selected, setSelected] = useState(null);
  // const isShow = true;

  const userData = useSelector(getUserSelector);

  const { enforceSecurity } = useEnforceSecurity({ app: 'sideNav' });

  const setSelectedTab = tab => {
    setSelected(tab);
  };

  const findSelectedTab = useCallback(
    path => {
      const tab = navItems.find(item => {
        return item.route === '/' + path;
      });
      return tab ? tab.key : null;
    },
    [navItems]
  );

  useEffect(() => {
    /* if (userData && userData.isAdmin) {
      setIsAdmin(true);
    } */
    const path = location?.pathname.split('/')[1];
    setSelected(findSelectedTab(path));
  }, [userData, location, findSelectedTab]);

  const getNavItems = () => {
    /* After integrate security module and uncomment and change the code accordingly 
    if (isAdmin === false) {
      const deleteditem = { label: "Setup", route: "/productSetUp", key: "productSetUp", logo: SettingsOutlinedIcon };
      navItems.splice(navItems.findIndex((a) => a.key === deleteditem.key), 1);
    } */
    return navItems
      .filter(item => {
        if (!item.clientSuite) {
          return enforceSecurity(item.label, null, 'menuItems');
        } else {
          return true;
        }
      })
      .map(item => {
        if (isSideNav === 'open') {
          return (
            <Link key={item.key} to={item.route} target={item?.target ?? ''}>
              <div className={`nav-item mb-13 py-6  d-flex align-items-center ${selected === item.key ? 'active' : ''}`} onClick={() => setSelectedTab(item.key)}>
                <Typography>
                  <item.logo />
                </Typography>
                <div className='label-space p-1'>{item?.label?.toUpperCase()}</div>
              </div>
            </Link>
          );
        } else {
          return (
            <Link key={item.key} to={item.route} target={item?.target ?? ''}>
              <Tooltip title={item.label}>
                <div className={`nav-item mb-13 py-6  d-flex align-items-center ${selected === item.key ? 'active' : ''}`} onClick={() => setSelectedTab(item.key)}>
                  <Typography>
                    <item.logo />
                  </Typography>
                </div>
              </Tooltip>
            </Link>
          );
        }
      });
  };

  const toggleMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(!isMenuOpen);
      setIsIconOpen('rotate-icon-closed');
      setIsSideNav('closed');
    } else {
      setIsMenuOpen(!isMenuOpen);
      setIsIconOpen('rotate-icon-open');
      setIsSideNav('open');
    }
  };
  return (
    <>
      <div className={`side-nav-container ${isSideNav}`}>
        {/* <div className="side-nav-bar d-flex flex-column"> */}
        <div className='side-nav-items m-auto pt-10 flex-column flex-grow-1'>{getNavItems(navItems)}</div>
        {/* </div> */}
      </div>
      <div className='hamburger-span' onClick={toggleMenu}>
        <ExpandMoreRoundedIcon cursor='pointer' className={`material-icons-round arrow-icon fs-20 ${isIconOpen}`} />
      </div>
    </>
  );
}

SideNav.propTypes = {
  location: PropTypes.object,
  clientSuite: PropTypes.bool
};

export default withRouter(SideNav);
