import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ignWorkbenchDataApi } from '../../../services/ApiService.js';
import { ERROR, GET, POST, SUCCESS } from '../../../services/constantService.js';
import { unregister } from '../../../serviceWorker.js';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook.js';
import { TRANS_KEYS } from '../../../utils/languageNamespaces.js';

import CustomButton from '../CustomButton/index.js';
import CustomPopup from '../CustomPopup/index.js';
import Loader from '../Loader/index.js';

import '../../../utils/common.scss';
import './index.scss';

const AddToWorkbench = props => {
  const { moduleIds, isPopupOpen, handleClose = () => {}, isProject } = props;
  const { register, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [listData, setListData] = useState({
    allList: [],
    myList: []
  });

  const [selectedList, setSelectedList] = useState([]);
  const [selectedType, setSelectedType] = useState('my-list');
  const [selectedWorkbenches, setSelectedWorkbenches] = useState(null);

  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_WORD_ADD, TRANS_KEYS.CONTACTS_ADD_CONTACT_TO_WORKBENCH, TRANS_KEYS.PROJECTS_ADD_PROJECT_TO_WORKBENCH]);

  const fetchWorkbenches = async () => {
    try {
      const res = await ignWorkbenchDataApi(GET, '', '', 'all');
      if (res && res.data) {
        const { data } = res;
        setListData(data);
        setSelectedList(data?.myList);
      }
    } catch (error) {
      enqueueSnackbar(`Failed to fetch workbenches: ${error?.message}`, { variant: ERROR });
    }
  };

  useEffect(() => {
    fetchWorkbenches();
  }, []);

  useEffect(() => {
    register('workbenches');
    register('type');

    setValue('type', 'my-list');

    return () => {
      unregister('workbenches');
      unregister('type');
    };
  }, [register, unregister]);

  const handleAddToWorkBench = async () => {
    try {
      setIsLoading(true);

      const payload = moduleIds?.map(id => ({
        contact_id: isProject ? null : id,
        project_id: isProject ? id : null,
        workbench_id: selectedWorkbenches?.id
      }));

      await ignWorkbenchDataApi(POST, '', payload, 'bulk_add_record');
      enqueueSnackbar(`Contacts added to workbench ${selectedWorkbenches?.name}`, { variant: SUCCESS });
      setSelectedWorkbenches(null);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTypeChange = (_e, val) => {
    setValue('type', val);
    setValue('workbenches', []);

    setSelectedType(val);
    setSelectedWorkbenches(null);

    // Update the options in the Autocomplete based on the selected radio button
    if (val === 'my-list') {
      setSelectedList(listData.myList);
    } else {
      setSelectedList(listData.allList);
    }
  };

  return (
    <CustomPopup
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={isPopupOpen}
      title={t(`${isProject ? TRANS_KEYS.PROJECTS_ADD_PROJECT_TO_WORKBENCH : TRANS_KEYS.CONTACTS_ADD_CONTACT_TO_WORKBENCH}:key`)}
    >
      <Loader show={isLoading} />
      <Box width={'50vw'} className='d-flex flex flex-column' gap={2}>
        <Box className='flex flex-column'>
          <RadioGroup aria-label='list' name='list-selection' value={selectedType} onChange={handleTypeChange} row>
            <FormControlLabel
              value='my-list'
              control={<Radio size='small' />}
              label={
                <Typography fontWeight={600} fontSize={14}>
                  My Lists
                </Typography>
              }
            />
            <FormControlLabel
              value='all-list'
              control={<Radio size='small' />}
              label={
                <Typography fontWeight={600} fontSize={14}>
                  All Lists
                </Typography>
              }
            />
          </RadioGroup>

          {/* Autocomplete for selecting items from the list */}
          <Autocomplete
            size='small'
            options={selectedList}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)} placeholder='Select items' />}
            onChange={(event, value) => {
              setSelectedWorkbenches(value);
              setValue('workbenches', value);
            }}
            value={selectedWorkbenches}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>

        <Box className='d-flex justify-content-end'>
          <CustomButton
            type={'primary'}
            size={'small'}
            disabled={selectedWorkbenches === null}
            buttonText={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)}
            customWidth={96}
            variant='contained'
            onClick={handleAddToWorkBench}
          />
        </Box>
      </Box>
    </CustomPopup>
  );
};

AddToWorkbench.propTypes = {
  isPopupOpen: PropTypes.bool,
  isProject: PropTypes.bool,
  handleClose: PropTypes.func,
  moduleIds: PropTypes.array
};

export default AddToWorkbench;
