import { Box, List, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalSearchSelector } from '../../../../selectors';
import { globalSearchApi } from '../../../../services/ApiService';
import { ERROR } from '../../../../services/constantService';
import { updateAllTabData, updateGlobalSearchData } from '../../../../store/globalSearchSlice';
import CustomSkeletonCard from '../../Components/CustomSkeletonCard';
import { SUCCESS_STATUS_CODE } from '../../helper/columnDefs';

// Lazy import card components
const ContactCard = lazy(() => import('./Components/ContactCard'));
const ProjectCard = lazy(() => import('./Components/ProjectCard'));
const CompanyCard = lazy(() => import('./Components/CompanyCard'));
const LogItem = lazy(() => import('./Components/LogItem'));

export const All = () => {
  const { data: globalSearchData, limit, loading, page, refetch } = useSelector(globalSearchSelector);
  const [error, setError] = useState(false);
  const { search: searchValue } = useParams();
  const observerRef = useRef();
  const [visibleItems, setVisibleItems] = useState(7);
  const selectedTab = globalSearchData?.find(tab => tab?.model === 'all');
  const list = selectedTab?.data?.hits?.hits || [];
  const dispatch = useDispatch();

  const fetchData = async (page = 0) => {
    const models = [];
    const payload = {
      keyword: searchValue,
      size: limit,
      from: page * limit,
      models,
      filters: {}
    };
    dispatch(updateGlobalSearchData({ key: 'loading', data: true }));

    try {
      const { status, data } = await globalSearchApi(payload);

      if (status === SUCCESS_STATUS_CODE) {
        if (page === 0) {
          dispatch(updateGlobalSearchData({ key: 'data', data: data }));
        } else {
          const allTabData = data?.find(tab => tab?.model === 'all');
          dispatch(updateAllTabData({ data: allTabData }));
        }
      } else {
        enqueueSnackbar(ERROR, { variant: 'error' });
        setError(true);
      }
    } catch (error) {
      enqueueSnackbar(ERROR, { variant: 'error' });
      setError(true);
    } finally {
      dispatch(updateGlobalSearchData({ key: 'loading', data: false }));
    }
  };

  const handleObserver = useCallback(
    entries => {
      const target = entries[0];
      if (error) return;

      if (page === -1 && searchValue) {
        fetchData(0);
        dispatch(updateGlobalSearchData({ key: 'page', data: page + 1 }));
        return;
      }
      if (page === -1 || !searchValue) return;
      if (target.isIntersecting && !loading) {
        setVisibleItems(prev => {
          const updatedVisibleItems = prev + 7;
          setVisibleItems(updatedVisibleItems);
          if (updatedVisibleItems >= list?.length) {
            fetchData(page + 1);
            dispatch(updateGlobalSearchData({ key: 'page', data: page + 1 }));
          }
          return updatedVisibleItems;
        });
      }
    },
    [loading, searchValue]
  );

  const timerRef = useRef(null);

  useEffect(() => {
    if (refetch !== 0 && !loading) {
      timerRef.current = setTimeout(() => {
        fetchData(0);
      }, 0);
    }

    return () => {
      clearTimeout(timerRef);
    };
  }, [refetch]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    });

    if (observerRef.current) observer.observe(observerRef.current);

    return () => observer.disconnect();
  }, [handleObserver]);

  const NoResultsFound = (
    <Box display={'flex'} minHeight={'10vh'} alignItems={'center'} justifyContent={'center'}>
      <Typography align='center' color={'#959fb7'} fontWeight={600} fontSize={12}>
        No results found!!
      </Typography>
    </Box>
  );

  const Loader = Skeleton => (
    <Stack spacing={2}>
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton key={index} />
      ))}
    </Stack>
  );

  const getCardComponent = index => {
    switch (index) {
      case 'idx-sgcorp-sandbox-ign-contacts':
        return ContactCard;
      case 'idx-sgcorp-sandbox-ign-projects':
        return ProjectCard;
      case 'idx-sgcorp-sandbox-ign-companies':
        return CompanyCard;
      case 'idx-sgcorp-sandbox-ign-contact-notes-all':
        return LogItem;
      default:
        return ProjectCard;
    }
  };

  const renderItem = item => {
    const CardComponent = getCardComponent(item?._index);
    return (
      <Suspense key={item?._id} fallback={<CustomSkeletonCard />}>
        <CardComponent item={item} />
      </Suspense>
    );
  };

  return (
    <Box width={'85vw'}>
      <Box display={'flex'} borderRadius={1} minHeight={'70vh'} flexDirection={'column'} padding={2} bgcolor={'Background'}>
        <Typography fontWeight={'bolder'} fontSize={14} color={'black'}>
          All
        </Typography>
        <List sx={{ marginTop: 1 }}>
          {list?.length > 0 && list?.slice?.(0, visibleItems)?.map(item => renderItem(item))}
          {!loading && list && list.length === 0 && NoResultsFound}
          {loading && Loader(CustomSkeletonCard)}
          <div ref={observerRef} /> {/* Observer reference */}
        </List>
      </Box>
    </Box>
  );
};
