/* eslint-disable no-unused-vars */
//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import { withStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { Divider, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../selectors';
import { notificationsApi, productOneApi } from '../../services/ApiService';
import { ERROR, GET, NOTIFICATION, PATCH, SUCCESS } from '../../services/constantService';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT, formatDate } from '../../utils/date';
import './index.scss';

const StyledBadge = withStyles(() => ({
  badge: {
    top: 8,
    fontSize: 10
  }
}))(Badge);

const UserNotification = () => {
  const [notificationsItems, setNotificationItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const userData = useSelector(getUserSelector);
  const { enqueueSnackbar } = useSnackbar();
  const [isAnimating, setAnimating] = useState(false);
  const socket = useSelector(state => state.rootReducer?.socket);
  const navigate = useNavigate();

  const getNotifications = async (shake = false) => {
    try {
      const { status, data } = await notificationsApi(GET, null, 'all');
      if (status === 200) {
        shake && setAnimating(true);
        setConvertedNotification(data.notifications);
        shake &&
          setTimeout(() => {
            setAnimating(false);
          }, NOTIFICATION.SHAKE_TIME);
        return data.notifications;
      } else {
        enqueueSnackbar(data.message, { variant: ERROR });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const setConvertedNotification = async data => {
    const notifications = data?.map(ele => {
      return {
        ...ele,
        visited: ele.status === NOTIFICATION.READ,
        userName: userData.name
      };
    });
    setNotificationItems(notifications);
  };

  useEffect(() => {
    getNotifications();
    // setConvertedNotification(userData.notifications);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on(NOTIFICATION.EXTENDED_SEARCH_COMPLETED, msg => {
        enqueueSnackbar(msg, { variant: SUCCESS });
        setTimeout(() => {
          getNotifications(true);
        }, 500);
      });
    }
  }, [socket]);

  const handleClickItem = async index => {
    setSelectedNotification(notificationsItems[index]);
    handleShowPopup(true);
    if (!notificationsItems[index].visited) {
      readNotification({
        id: notificationsItems[index].id,
        status: NOTIFICATION.READ
      });
    }
  };

  const getMenuItems = () => {
    return notificationsItems?.length ? (
      notificationsItems.map((item, index) => {
        return (
          <div className='menuContainer cursor-pointer' key={index} onClick={handleClose}>
            {/* {!item.visited && <span className="view-status"></span>} */}
            <div
              onClick={() => {
                navigate(item.redirect_url);
              }}
              className={'notification-container px-4'}
            >
              <span className={`notification-label ${item.visited ? 'viewed-notification-label' : ''} `}>{item.notification_content}</span>
              <div className='notification-sub-label'>
                <span>
                  <strong>{item.userName}</strong> | {formatDate(item.created_at, DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT)}
                </span>
              </div>
            </div>
            <Divider />
          </div>
        );
      })
    ) : (
      <div className='empty-notification'>{NOTIFICATION.EMPTY_LABEL}</div>
    );
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleConfirmPopup = () => {
    setShowPopup(false);
    if (selectedNotification.notification_content.status === 'COMPLETED') {
      window.open(selectedNotification.notification_content.redirect_url);
    }
    setSelectedNotification(null);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedNotification(null);
  };

  const readNotification = async payload => {
    try {
      const subRoute = 'notification-status';
      await productOneApi(PATCH, payload, subRoute);
      return getNotifications();
    } catch (err) {
      console.log('error in readNotification::', err);
    }
  };

  const getUnreadNotification = () => {
    return notificationsItems?.filter(ele => !ele.visited)?.length;
  };

  return (
    <div>
      <Tooltip title='Notifications'>
        <IconButton
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <StyledBadge color='error' className={`notification-badge ${isAnimating ? 'badge-animation' : ''}`} badgeContent={getUnreadNotification()} max={99}>
            <NotificationsRoundedIcon className='notification-icon' />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        style={{
          maxHeight: '60vh'
        }}
        className='mt-1 right-0'
        anchorEl={anchorEl}
      >
        {getMenuItems()}
      </Menu>
    </div>
  );
};

export default UserNotification;
