import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { separator } from '../../../../Containers/Commons/Utils';
import { THOUSAND_SEPARATOR } from '../../../../services/constantService';

export const CustomNumbericField = ({ name, color, variant, onKeyDown = () => {}, error = false, InputProps = {}, disabled = false, value = '', label = '', onChange = () => {}, sx = null }) => {
  return (
    <NumericFormat
      value={value}
      customInput={TextField}
      error={error}
      disabled={disabled}
      fullWidth
      InputProps={InputProps}
      label={label}
      variant={variant}
      name={name}
      color={color}
      onKeyDown={onKeyDown}
      thousandSeparator={separator(value, THOUSAND_SEPARATOR)}
      sx={
        sx ?? {
          '& .MuiInputLabel-root': {
            margin: 0,
            top: '-10px'
          },
          '& .MuiInputLabel-shrink': {
            margin: 0,
            top: '-10px'
          },
          '& .Mui-focused .MuiInputLabel-root': {
            margin: 0,
            top: '-10px'
          },
          '& .Mui-focused .MuiInputLabel-shrink': {
            margin: 0,
            top: '-10px'
          }
        }
      }
      size='small'
      InputLabelProps={{ focused: true }}
      decimalScale={2}
      isNumericString={true}
      onValueChange={(values, sourceInfo) => {
        const { value } = values;
        onChange?.(value, sourceInfo.event);
      }}
    />
  );
};

export default CustomNumbericField;

CustomNumbericField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  InputProps: PropTypes.object,
  onKeyDown: PropTypes.func,
  name: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string
};
