import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { globalSearchSelector } from '../../../selectors';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import { getModuleName } from '../helper/utils';

const Tabs = () => {
  const { data, tabs, loading, search } = useSelector(globalSearchSelector);
  const { selectedTabId, search: searchValue } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const module = getModuleName(selectedTabId);

  const changeTab = tabId => {
    if (loading) return;
    dispatch(updateGlobalSearchData({ key: 'selectedTabId', data: tabId }));
    dispatch(updateGlobalSearchData({ key: 'filters', data: {} }));

    if (selectedTabId !== tabId) {
      dispatch(updateGlobalSearchData({ key: 'subTabs', data: [] }));
    }
    if (selectedTabId === 'all') {
      dispatch(updateGlobalSearchData({ key: 'page', data: -1 }));
    }
    navigate(`/global-search/${tabId}/${search || ''}`);
  };

  return (
    <Box width={'85vw'} paddingY={2.5} paddingX={4} display={'flex'} alignItems={'center'} gap={6} boxShadow={1} borderRadius={1} bgcolor={'Background'}>
      {tabs.map(tab => {
        const list = data?.find(item => item?.model === tab?.model);

        return (
          <Box key={tab?.model} sx={{ cursor: 'pointer' }} onClick={() => changeTab(tab?.model)}>
            <Typography color={tab?.model === selectedTabId ? '#009d81' : ''} fontSize={12} fontWeight={900}>
              {tab?.label} {list?.data?.hits?.total?.value && list.model !== 'all' ? `(${list?.data?.hits?.total?.value})` : ''}
            </Typography>
          </Box>
        );
      })}

      {searchValue && (
        <Typography sx={{ fontFamily: 'roboto !important' }} fontWeight={700} fontSize={15}>
          Results for keyword{' '}
          <span style={{ fontStyle: 'italic' }}>
            &quot;{searchValue}&quot;&nbsp;in {module} Records
          </span>{' '}
        </Typography>
      )}
      {!searchValue && (
        <Typography sx={{ fontFamily: 'roboto !important' }} fontWeight={700} fontSize={15}>
          <span style={{ fontStyle: 'italic' }}>Please enter a keyword to see results</span>{' '}
        </Typography>
      )}
    </Box>
  );
};

export default Tabs;
