import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubTabs } from './SubTabs';
import Tabs from './TabHeader';
import { All } from './Tabs/All';
import { TabContent } from './Tabs/TabContent';

export const GlobalSearch = () => {
  const { selectedTabId, search } = useParams();

  const [tabId, setTabId] = useState(null);

  useEffect(() => {
    setTabId(null);

    setTimeout(() => {
      setTabId(selectedTabId);
    }, 0);
  }, [selectedTabId, search]);

  return (
    <Box paddingTop={2} paddingLeft={4}>
      <Tabs />
      <SubTabs />
      {tabId === 'all' && <All />}
      {tabId === 'ign-contacts' && <TabContent />}
      {tabId === 'ign-projects' && <TabContent />}
      {tabId === 'ign-companies' && <TabContent />}
      {tabId === 'ign-contact-notes-all' && <TabContent />}
    </Box>
  );
};
