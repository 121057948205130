import { Box } from '@mui/material';
import { AwsClient } from 'aws4fetch';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../services/constantService';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import './index.scss';

const aws = new AwsClient(
  { accessKeyId: 'AKIA4WTQK7NES3YK6BF6', 
    secretAccessKey: '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',
    service: 'execute-api',
    region: 'us-east-2' 
  }
);

const LAMBDA_FN_API = 'https://api.sandbox.ignyte.app/productone/sgcorp/api/v1/complete-extended-search';
// const reqHeaders = {
//   'Origin': 'https://sgcorp.sandbox.ignyte.app'
// };
async function invokeMyLambda(url, method, body) {
  try {
    const res = await aws.fetch(LAMBDA_FN_API, { body: JSON.stringify(body), method: method });

    // `res` is a standard Response object: https://developer.mozilla.org/en-US/docs/Web/API/Response
    return res.json();
  } catch (error) {
    console.log('Error in invoking lambda', error);
  }
}

const SetupPage = () => {
  const navigate = useNavigate();
  const { t } = useLanguageHooks([TRANS_KEYS.TRANSLATE_VALUE_SETUP]);

  useEffect(() => {
    const event = {
      task_id: '2425547b-db95-475b-a2df-d9a222b36cf9'
    };
    invokeMyLambda(LAMBDA_FN_API, 'POST', event).then(json => console.log(json));
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          padding: '20px',
          gap: '20px',
          flexWrap: 'wrap'
        }}
        id='setup-page'
      >
        <Box onClick={() => navigate('/message-templates')} className='setup-page__card font-weight-bold'>
          Message Templates
        </Box>
        <Box onClick={() => navigate('/tag-setup')} className='setup-page__card font-weight-bold'>
          Tags Setup
        </Box>
        <Box onClick={() => navigate('/activity-types-setup')} className='setup-page__card font-weight-bold'>
          Activity Types Setup
        </Box>
        <Box onClick={() => navigate(ROUTES.translateValueSetup)} className='setup-page__card font-weight-bold'>
          {t(`${TRANS_KEYS.TRANSLATE_VALUE_SETUP}:key`)}
        </Box>
        <Box onClick={() => navigate('/stage-setup')} className='setup-page__card font-weight-bold'>
          Stage Setup
        </Box>
        <Box onClick={() => navigate('/candidate-project-status-setup')} className='setup-page__card font-weight-bold'>
          Candidate - Project Status Off-limits Setup
        </Box>
        <Box onClick={() => navigate('/i18n-label-config')} className='setup-page__card font-weight-bold'>
          Label Config
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SetupPage;
