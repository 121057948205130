import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TotalCount = props => {
  const { t } = useTranslation();
  const { selectedRowsCount, rowCount, isRowSelected, loadedContactCount, showTotalCount = true } = props;

  return (
    <Box component='div' className='d-flex align-items-center fs-14'>
      {isRowSelected ? (
        <>
          {t('common.count.selected')}
          <Box component='span' className='color-primary-main mx-1'>
            {selectedRowsCount}
          </Box>
          {t('common.count.outOf')} {rowCount}
        </>
      ) : (
        <>
          {' '}
          {showTotalCount
            ? `${t('common.count.totalCount')} : ${rowCount}`
            : loadedContactCount
              ? `${t('common.count.showing')} ${loadedContactCount} / ${rowCount}`
              : `${t('common.count.totalCount')} : ${rowCount}`}
        </>
      )}
    </Box>
  );
};

TotalCount.propTypes = {
  rowCount: PropTypes.number,
  isRowSelected: PropTypes.bool,
  selectedRowsCount: PropTypes.number,
  loadedContactCount: PropTypes.number,
  showTotalCount: PropTypes.bool
};

export default TotalCount;
