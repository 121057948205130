//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import Link from '@material-ui/core/Link';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { Box, Checkbox, DialogContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogAnActivityTypes } from '../../../../../actions';
import delete_icon from '../../../../../assets/icons/delete_icon.svg';
import Search from '../../../../../Containers/Contacts/Search';
import { getUserSelector } from '../../../../../selectors';
import { logAnActivityApi } from '../../../../../services/ApiService';
import * as AttachmentsService from '../../../../../services/AttachmentsService';
import { DELETE, ERROR } from '../../../../../services/constantService';
import { logAnActivityActions } from '../../../../../store/logAnActivitySlice';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import CustomPopup from '../../../../common/CustomPopup';
import '../../index.scss';
//----------------------------------------------// Internal Imports // -------------------------------------------------

const ActivityHistoryDetail = props => {
  console.log(props);
  const { activityDetails, setActivityComponent = () => {}, getActivityDetails = () => {} } = props;
  const activityData = activityDetails?.activity;
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_WORD_FOR,
    TRANS_KEYS.CONTACTS_SUBJECT,
    TRANS_KEYS.CONTACTS_NOTES,
    TRANS_KEYS.CONTACTS_WORD_YOU,
    TRANS_KEYS.CONTACTS_FILTER_BY,
    TRANS_KEYS.CONTACTS_FILTERS,
    TRANS_KEYS.LOGGED_A,
    TRANS_KEYS.CLIENT_FEEDBACK,
    TRANS_KEYS.MOVED_TO,
    TRANS_KEYS.CONTACTS_WORD_ON,
    TRANS_KEYS.DELETE_ACTIVITY,
    TRANS_KEYS.EDITED_BY,
    TRANS_KEYS.CONTACTS_FILE_NAME
  ]);
  const { t: tv2 } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(getUserSelector);
  const activityType = useSelector(state => state.commonReducer.activityType);
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!activityType) {
      dispatch(fetchLogAnActivityTypes('ACTIVITY_TYPE', 'activityType'));
    } else {
      setOptions(activityType);
    }
  }, [activityType, dispatch]);

  const [isFilterOpen, setFilterPopup] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [isDeletePopup, setDeletePopup] = useState(false);
  const [notes, setNotes] = useState('');
  const labels = ['Calls', 'Emails', 'Messages', 'Documents', 'Date'];

  const OnViewMore = notes => {
    setIsViewMore(true);
    setNotes(notes);
  };

  const handleClose = () => {
    setIsViewMore(false);
  };

  // const onFilterClick = () => {
  //   setFilterPopup(true);
  // };

  const onFilterClose = () => {
    setFilterPopup(false);
  };
  const getIconClass = activityType => {
    if (activityType === 'Call') {
      return 'call-icon';
    } else if (activityType === 'Note') {
      return 'note-icon';
    } else if (activityType === 'Screening') {
      return 'screening-icon';
    }
    return 'default-icon';
  };

  const getActivityType = (activityType, item) => {
    let returnString = '';

    if (activityType === 'candidate_stage_change') {
      returnString = t(`${TRANS_KEYS.MOVED_TO}:key`) + ' ' + item?.to_stage;
    } else if (activityType === 'client_feedback') {
      returnString = t(`${TRANS_KEYS.LOGGED_A}:key`) + ' ' + t(`${TRANS_KEYS.CLIENT_FEEDBACK}:key`);
    } else if (options.length > 0) {
      returnString = options.find(item => item.activity_type_code === activityType)?.labels?.find(item => item.language_code === navigator.language)?.label ?? '';

      if (!returnString) {
        returnString = options.find(item => item.activity_type_code === activityType)?.labels?.find(item => item.language_code === 'en-US')?.label ?? '';
      }

      if (item?.notes_type) {
        returnString = returnString.concat(` as a ${item?.ign_translate_value?.short_desc ? item?.ign_translate_value?.short_desc : item?.notes_type}`);
      }

      return t(`${TRANS_KEYS.LOGGED_A}:key`) + ' ' + returnString;
    }
    return returnString;
  };

  const handleDelete = async id => {
    try {
      await logAnActivityApi(DELETE, id, null, 'activity/remove');
      enqueueSnackbar(t(`${TRANS_KEYS.DELETE_ACTIVITY}:key`), { variant: 'success' });
      await getActivityDetails();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      console.log('error', error);
    }
  };
  const downloadAttachment = async id => {
    let attachmentId = id;
    try {
      setLoadingAttachments(true);
      const url = await AttachmentsService.documentDownload(attachmentId);
      setLoadingAttachments(false);
      // const url = await ignContactDataApi(GET, null, null, sub_route)
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);

        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
      } else {
        enqueueSnackbar('Unable to download attachment', { variant: ERROR });
      }
    } catch (e) {
      setLoadingAttachments(false);
      console.log('Error found in downloadAttachment::', e);
    }
  };
  return (
    <>
      <Box id='ActivityLogs_container'>
        <Box id='ActivityHistoryDetails' className='custom-scrollbar'>
          <Box className={props.isHeaderNav === 'closed' ? 'activity-details-collapse' : 'activity-details'}>
            {activityData?.map((item, index) => (
              <Grid container key={index} className='activity-history-head' m={1}>
                <Grid item xs={2} md={1.5} lg={1}>
                  <Box ml={1} className={`activity-icons ${getIconClass(item?.activity_type)}`}>
                    <DescriptionOutlinedIcon />
                  </Box>
                </Grid>
                <Grid item xs={10} md={10.5} lg={8}>
                  <Box className='activity-content ml-2 mt-2'>
                    {item?.notes_type !== 'Call' && (
                      <Box className='activity-head mb-2'>
                        <Box className='fs-12 mr-1 w-auto'>
                          {item?.activity_type === 'candidate_stage_change' ? '' : <strong>{item?.created_user?.name}</strong>} {getActivityType(item?.activity_type, item)}
                        </Box>
                      </Box>
                    )}
                    {(item?.project?.job_number || item?.project?.job_title) && (
                      <Box className='activity-head mb-2'>
                        <Box className='label-text-activity fs-12 mr-2' variant='body1'>
                          {item?.activity_type === 'candidate_stage_change' ? `${t(`${TRANS_KEYS.CONTACTS_WORD_ON}:key`)}:` : `${t(`${TRANS_KEYS.CONTACTS_WORD_FOR}:key`)}:`}
                        </Box>
                        <Box className='value-title fs-12'>{item?.project?.job_number || item?.project?.job_title ? `${item?.project?.job_number} - ${item?.project?.job_title}` : '-'}</Box>
                      </Box>
                    )}
                    {(item?.interview_date || item?.interview_date_timezone) && (
                      <Box className='activity-head mb-2'>
                        <Box className='label-text-activity fs-12 mr-2' variant='body1'>
                          {tv2('common.date')}
                        </Box>
                        <Box className='fs-12'>
                          {item?.interview_date || item?.interview_date_timezone ? `${moment(item?.interview_date).utc().local().format('HH:mm, DD/MM/YYYY')} [${item?.interview_date_timezone}]` : '-'}
                        </Box>
                      </Box>
                    )}
                    {item?.notes && (
                      <Box className='activity-head' mb={1}>
                        <Box className='label-text-activity fs-12 mr-2' variant='body1'>
                          {`${t(`${TRANS_KEYS.CONTACTS_NOTES}:key`)}:`}
                        </Box>
                        <Box className='value-notes fs-12'>
                          {item?.notes ? (
                            item.notes.length <= 40 ? (
                              <Box dangerouslySetInnerHTML={{ __html: item.notes || '-' }} />
                            ) : (
                              <Box className='d-flex flex-wrap'>
                                <Box className='text-break' dangerouslySetInnerHTML={{ __html: item.notes.replace(/<[^>]*>/g, '').substring(0, 40) || '-' }} />
                                <Link
                                  component='button'
                                  variant='body2'
                                  onClick={() => {
                                    OnViewMore(item.notes);
                                  }}
                                  className='show-more-link ml-1'
                                  // sx={{display: "flex"}}
                                >
                                  Show More
                                </Link>
                              </Box>
                            )
                          ) : (
                            '-'
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={3} className='d-flex justify-content-end'>
                  <Box mr={1}>
                    <Box className='d-flex gap-2 justify-content-end'>
                      {item?.attachment && (
                        <Tooltip title={item?.attachment?.file_name}>
                          <IconButton
                            className='mr-2'
                            onClick={() => {
                              console.log('item?.attachment?.id', item?.attachment?.id);
                              downloadAttachment(item?.attachment?.id);
                            }}
                          >
                            <SaveAltOutlinedIcon
                              className='img'
                              sx={{
                                fill: 'black'
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <IconButton
                        className='mr-2'
                        onClick={() => {
                          dispatch(logAnActivityActions.updateEditId(item?.id));
                          setActivityComponent('add_activity');
                        }}
                      >
                        <EditIcon
                          className='img'
                          sx={{
                            fill: 'black'
                          }}
                        ></EditIcon>
                      </IconButton>
                      {item.created_by === userData?.id && (
                        <IconButton
                          className='delete-icons'
                          onClick={() => {
                            setDeletePopup(item?.id);
                          }}
                        >
                          <img src={delete_icon} alt='' />
                        </IconButton>
                      )}
                    </Box>
                    <Box className='activity-time-content mt-2'>
                      <Box component='span' className='d-flex fs-8'>
                        {/* {item?.time} */}
                        {item?.updated_at ? moment(item?.updated_at).utc().local().format('HH:mm, DD/MM/YYYY') : ''}
                      </Box>
                    </Box>
                    <Box className='activity-time-content fs-8 mt-2'>
                      {item?.created_user?.name && (
                        <Box component='span' className='edited-by fs-8'>
                          {t(`${TRANS_KEYS.EDITED_BY}:key`)}
                          <Box component='span' className='names ml-1 fs-8 '>
                            {item?.updated_user?.name ? item?.updated_user?.name : item?.created_user?.name}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
          <CustomPopup title={t(`${TRANS_KEYS.CONTACTS_FILTERS}:key`)} open={isFilterOpen} onClose={onFilterClose} showAction={true} confirmText={'Apply'} cancelText={'Close'}>
            <form>
              <Stack className='filter-popup'>
                <Box className='fs-12 filter-text-heading pb-2'> {t(`${TRANS_KEYS.CONTACTS_FILTER_BY}:key`)}</Box>
                <Box className='search-bar flex'>
                  <Search placeholder='Calls' isAddContactToProject={true} />
                </Box>
                {labels.map((label, index) => (
                  <Box key={index} className='d-flex align-items-center'>
                    <Checkbox className='checkboxStyle' size='small' name={`checkbox_${label}`} />
                    <Box component='span' className='fs-12 filter-text'>
                      {label}
                    </Box>
                  </Box>
                ))}
              </Stack>
            </form>
          </CustomPopup>
        </Box>
        {isViewMore && (
          <CustomPopup onClose={handleClose} aria-labelledby='customized-dialog-title' open={isViewMore} title='Notes'>
            <DialogContent className='content-value'>
              <Typography variant='body2' className='card-containt view-text' dangerouslySetInnerHTML={{ __html: notes || '-' }}></Typography>
            </DialogContent>
          </CustomPopup>
        )}
      </Box>
      <CustomPopup
        open={isDeletePopup}
        handleSubmit={() => {
          handleDelete(isDeletePopup);
          setDeletePopup(false);
        }}
        onClose={() => setDeletePopup(false)}
        title='Are you sure you want to delete this activity?'
        confirmText='Delete'
        cancelText='Cancel'
        showAction={true}
      />
    </>
  );
};

ActivityHistoryDetail.propTypes = {
  activityDetails: PropTypes.object,
  isHeaderNav: PropTypes.string,
  setActivityComponent: PropTypes.func,
  getActivityDetails: PropTypes.func
};
export default ActivityHistoryDetail;
