import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Done, Edit } from '@mui/icons-material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';

import { withStyles } from '@mui/styles';
import { Link, /* useNavigate, */ useParams /* useLocation */ } from 'react-router-dom';
import ViewContactDrawer from '../../../../components/ViewContactDrawer';
import CustomPopup from '../../../../components/common/CustomPopup';
import Loader from '../../../../components/common/Loader';
import RichText from '../../../../components/common/RichText';
import { GetCandidateTagsApi } from '../../../../services/ApiService';
import { SCORES_DESCRIPTION } from '../../../../services/constantService';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { RejectReason, filterType, headerType } from '../Constant';
import style from '../productOne.module.scss';
import MultiSelect from './MultiSelect';

const EditLabelComponent = ({ index, keyValue, label, description, isSelected, onChange = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box className='mb-1'>
      <Stack direction='row' alignItems={'center'}>
        <Typography variant='subtitle2' sx={{ textDecoration: 'underline' }}>
          {label}
        </Typography>
        <Edit
          sx={{
            color: isSelected ? 'titleBarBackground.main' : 'secondary.main',
            zIndex: '10',
            cursor: 'pointer'
          }}
          className='p-1 mr-1'
          onClick={() => setModalOpen(true)}
        />
      </Stack>
      <Typography variant='caption'>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>
      <EditModal index={index} keyValue={keyValue} isOpen={modalOpen} onClose={() => setModalOpen(false)} label={label} defaultValue={description} onChange={onChange}></EditModal>
    </Box>
  );
};

const EditModal = ({ label, onClose = () => {}, elementIndex, isOpen, defaultValue = '', onChange = () => {}, index, keyValue }) => {
  const [richTextValue, setRichTextValue] = useState('');
  const handleRichTextChange = value => {
    setRichTextValue(value);
  };

  const handleCloseWithValueSave = () => {
    onChange(keyValue, index, elementIndex, richTextValue);
    onClose();
  };
  useEffect(() => {
    setRichTextValue(defaultValue);
  }, [defaultValue]);
  return (
    <CustomModalWithHeader label={label} isOpen={isOpen} onClose={() => {}} style={{ paddingBottom: '2vh' }}>
      <RichText style={{ height: '30vh', maxHeight: '35vh', maxWeight: '30vw' }} defaultValue={richTextValue} onChange={handleRichTextChange} />

      <Stack direction={'row'} justifyContent={'flex-end'} className='mt-5'>
        <CustomButton type='tertiary' variant={'text'} buttonText={'Cancel'} onClick={() => onClose('CANCEL')} />
        <CustomButton buttonText={'Save'} onClick={handleCloseWithValueSave} />
      </Stack>
    </CustomModalWithHeader>
  );
};

const EditLabelInlineComponent = ({ index, keyValue, label, description, isSelected, onChange = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box className='mb-1'>
      <Stack direction='row' alignItems={'center'}>
        <Typography variant='subtitle2' sx={{ textDecoration: 'underline' }}>
          {label}
        </Typography>
        {!modalOpen ? (
          <Edit
            sx={{
              color: isSelected ? 'titleBarBackground.main' : 'secondary.main',
              zIndex: '10',
              cursor: 'pointer'
            }}
            className='p-1 mr-1'
            onClick={() => setModalOpen(!modalOpen)}
          />
        ) : (
          <Done
            sx={{
              color: isSelected ? 'titleBarBackground.main' : 'secondary.main',
              zIndex: '10',
              cursor: 'pointer'
            }}
            className='p-1 mr-1'
            onClick={() => setModalOpen(!modalOpen)}
          />
        )}
      </Stack>
      {!modalOpen ? (
        <Typography variant='caption'>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      ) : (
        <RichText style={{}} defaultValue={description} onChange={value => onChange(keyValue, index, value)} />
      )}
    </Box>
  );
};
const RenderName = props => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const navigate = useNavigate()
  const params = useParams();
  // const location = useLocation()

  // const openDrawer = () => {
  //   console.log("***************contact_id",props.data.contact_id)
  //   console.log("***************cparams",params)
  //   navigate(`/projects/view-project/${params.id}/candidates/product-one/${props.data.contact_id}/details/profile`);
  // }
  // useEffect(()=>{
  //   const isContactView = location.pathname.includes(props.data.contact_id);
  //   if (isContactView && !isDrawerOpen) {
  //     setIsDrawerOpen(true);
  //   }
  // }, [location?.pathname]);

  return (
    <Stack className='w-100 p-2 fs-28' direction='row' justifyContent={'flex-start'} alignItems={'center'}>
      <Link className='flex mr-1' to={props?.data?.contact?.linkedin_url?.includes('http') ? props?.data?.contact?.linkedin_url : `https://${props?.data?.contact?.linkedin_url}`} target='_blank'>
        <LinkedInIcon fontSize='inherit' className={`${style.blue} fs-16`} />
      </Link>

      <Stack direction='row' className='w-100 fs-14' alignItems={'center'}>
        <Typography className={'fs-12'} onClick={() => setIsDrawerOpen(true)}>
          {props?.data?.contact?.first_name} {props?.data?.contact?.last_name}
        </Typography>
      </Stack>
      {isDrawerOpen && (
        <ViewContactDrawer
          isCandidate={true}
          project_id={params.id}
          candidate_id={props.data.id}
          navigateToAllContacts={false}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          id={props.data.contact_id}
          baseRoute={`/projects/view-project/${params.id}`}
        />
      )}
    </Stack>
  );
};
const RenderCompanyName = props => {
  return (
    <Typography className='fs-12'>
      {/* {props?.value} */}
      <ReadMoreHTML
        htmlContent={<Typography className='fs-12'>{props?.value && props?.value?.length > 50 ? props?.value.slice(0, 30) + '....' : props?.value} </Typography>}
        allText={props?.value}
        showMoreText={
          props?.value &&
          props?.value?.length > 50 && (
            <Typography
              className='fs-12'
              style={{
                textDecoration: 'underline'
              }}
            >
              Read More
            </Typography>
          )
        }
      />
    </Typography>
  );
};
const RenderRejectReasons = props => {
  return (
    <Stack className='w-100 p-2' direction='column'>
      {/* <WorkIcon fontSize="inherit" className={`${style.gray}`} /> */}
      {props?.value?.map((item, index) => (
        <Typography key={index} className='fs-10'>
          {item}
          {index !== props?.value?.length - 1 ? ',' : ''}
        </Typography>
      ))}
    </Stack>
  );
};
const RenderOffLimits = props => {
  return (
    <>
      {props?.value?.length > 0 && (
        <Stack direction='row' justifyContent={'center'} alignItems={'center'}>
          <div className={style.offlimit_circle}></div>
        </Stack>
      )}
    </>
  );
};

const RenderLiffer = props => {
  if (props?.value === '1.00') {
    return <FlagOutlinedIcon color={'primary.main'} />;
  }
  return <></>;
};
const RenderCheckbox = props => {
  return props?.value && <CheckIcon color={'primary.main'} />;
};
const AppliedAt = props => {
  const date = new Date(props?.value).toLocaleDateString();
  return <Typography className={'fs-10 h-4'}>{date}</Typography>;
};

const ColorBasedscore = props => {
  return (
    <Stack direction='row' className='fs-12'>
      {props.value >= 0.85 ? (
        <Typography className={style.green}> {Math.floor(props?.value * 100)}</Typography>
      ) : props?.value >= 0.65 ? (
        <Typography className={style.yellow}> {Math.floor(props?.value * 100)}</Typography>
      ) : (
        <Typography className={style.gray}> {Math.floor(props?.value * 100)}</Typography>
      )}
    </Stack>
  );
};
const LocationScore = props => {
  return (
    <Stack direction='row' className='fs-12'>
      {parseInt(props.value) === 0 ? (
        <Typography>State</Typography>
      ) : parseInt(props?.value) === 1 ? (
        <Typography>Country</Typography>
      ) : parseInt(props?.value) === 2 ? (
        <Typography>Both</Typography>
      ) : (
        <Typography></Typography>
      )}
    </Stack>
  );
};

const RenderActionProject = props => {
  console.log('props.valueAction', props.value);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const buttonLabel = 'Add to Pipeline';
  const popupLabel = 'Add to Pipeline';
  const buttonStyle = (props.value = style.green_bg);
  const handleChange = () => {
    console.log('change');
  };
  return (
    <div>
      <Button aria-describedby={anchorEl ? 'simple-popper' : undefined} type='button' variant='contained' onClick={handleClick} className={buttonStyle}>
        <Typography className='fs-10 h-4'>{buttonLabel}</Typography>
        {anchorEl ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
      </Button>
      <BasePopup id={anchorEl ? 'simple-popper' : undefined} open={Boolean(anchorEl)} anchor={anchorEl} className={style.base_popup}>
        <Typography className='fs-12 h-4' onClick={handleChange}>
          {popupLabel}
        </Typography>
      </BasePopup>
    </div>
  );
};
function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Button variant='text' className={style.btn_prop + ' fs-12'} onClick={handleClickOpen}>
        Score Definition
      </Button>
      <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle className='fs-12 font-weight-bold' id='customized-dialog-title'>
          Score Definition
        </DialogTitle>
        <IconButton aria-label='close' onClick={handleClose} className={style.close_option}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <React.Fragment>
            <Stack className={style.popup_body}>
              <Stack className={`${style.w_80} ${style.popup_bg}`}>
                {SCORES_DESCRIPTION.map((category, index) => (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={3}>
                      <Typography variant='subtitle1' className='fs-10 font-weight-bold' gutterBottom>
                        {category.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant='body2' className='fs-10' gutterBottom>
                        {category.description}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
              <Stack className={`${style.w_20} ${style.popup_bg}`}>
                <Stack className={style.stack_row}>
                  <span className={`${style.greenDot} ${style.circle}`}></span> <Typography className='fs-12'>85-100</Typography>
                </Stack>
                <Stack className={style.stack_row}>
                  <span className={`${style.yellowDot} ${style.circle}`}></span> <Typography className='fs-12'>65-84</Typography>
                </Stack>
                <Stack className={style.stack_row}>
                  <span className={`${style.gray_bg} ${style.circle}`}></span> <Typography className='fs-12'>0-64</Typography>
                </Stack>
              </Stack>
            </Stack>
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
function RejectPopup(props) {
  const { open, setOpen, value, setValue, onSave } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Button variant='text' className={`${style.btn_reject} fs-12`} onClick={handleClickOpen}>
        Reject
      </Button>
      <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle className='fs-12 font-weight-bold' id='customized-dialog-title'>
          Reject Reason
        </DialogTitle>
        <IconButton aria-label='close' onClick={handleClose} className={style.close_option}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <React.Fragment>
            <MultiSelect value={value} setValue={setValue} listOfOption={RejectReason} />
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button autoFocus onClick={onSave} variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default function SelectSmall(props) {
  const { value, setValue, listOfOption, label } = props;

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }} size='small'>
      <Select
        labelId='demo-select-small-label'
        id='demo-select-small'
        variant='outlined'
        value={value}
        label={label ? label : undefined}
        onChange={handleChange}
        defaultValue={listOfOption[0].id}
        fullWidth
      >
        {listOfOption.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
function RenderTags(props) {
  return (
    <ReadMoreHTML
      htmlContent={
        <Stack direction='row' className='fs-12'>
          {props.value &&
            props.value
              ?.slice(0, 1)
              .map((item, index) => (
                <Chip key={index} label={item.tag_value} sx={{ backgroundColor: item.tag_type === 'positive' ? 'success.main' : 'error.main', color: 'white', margin: '4px', fontSize: '12px' }} />
              ))}
        </Stack>
      }
      allText={
        <Stack direction='row' flexWrap={'wrap'} className='fs-12' maxWidth='500px' width={'500px'}>
          {props.value &&
            props.value?.map((item, index) => (
              <Chip key={index} label={item.tag_value} sx={{ backgroundColor: item.tag_type === 'positive' ? 'success.main' : 'error.main', color: 'white', margin: '4px', fontSize: '12px' }} />
            ))}
        </Stack>
      }
      showMoreText={props.value.length > 1 ? <Chip label={`+ ${props.value.length - 1}`} sx={{ margin: '4px' }} /> : ''}
    />
  );
}
function RenderSummary(props) {
  const [popup, showPopup] = useState(false);
  const handleClickOpen = () => {
    showPopup(true);
  };
  return (
    <Stack direction='row' className='fs-12'>
      {/* <DynamicTypography text={props.value} /> */}
      <ReadMoreHTML
        htmlContent={
          <div
            dangerouslySetInnerHTML={{ __html: props.value }}
            style={{
              display: '-webkit-box',
              maxWidth: '500px',
              width: '100%',
              '-webkit-line-clamp': '2',
              '-webkit-box-orient': 'vertical',
              overflow: 'hidden',
              fontSize: '12px'
            }}
          />
        }
        allText={<div dangerouslySetInnerHTML={{ __html: props.value }} />}
        showMoreText={
          props?.value?.length > 50 ? (
            <div
              style={{
                textDecoration: 'underline',
                fontSize: '12px'
              }}
            >
              Read More
            </div>
          ) : (
            ''
          )
        }
        onClick={handleClickOpen}
      />
      {popup && (
        <CustomPopup open={popup} onClose={() => showPopup(false)} title={'Summary'} showAction={false} loading={false} dropdown={false} overFlowClass={style.overflow} width={'500px'}>
          <Stack className={style.popup_body}>
            <div dangerouslySetInnerHTML={{ __html: props.value }} />
          </Stack>
        </CustomPopup>
      )}
    </Stack>
  );
}
const ReadMoreHTML = ({ htmlContent, allText, showMoreText, onClick }) => {
  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 700,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      boxShadow: 'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;'
    }
  }))(Tooltip);
  const handleMore = () => {
    if (onClick) onClick();
  };
  return (
    <Typography
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '500px',
        width: '100%'
      }}
    >
      {htmlContent}
      <HtmlTooltip title={allText} placement='top'>
        <span style={{ fontSize: '12px' }} className={'fs-12 text-blue'} onClick={handleMore}>
          {showMoreText}
        </span>
      </HtmlTooltip>
    </Typography>
  );
};
function FilterOption(props) {
  const { open, setOpen, filterValue, setFilterValue, onSave, type, setType, reset } = props;
  const [tags, setTags] = useState([]);
  const [loader, setLoader] = useState(false);
  const dropdownOption = async () =>
    await GetCandidateTagsApi().then(res => {
      if (res?.data?.length > 0)
        setTags(
          res?.data?.reduce((acc, item) => {
            acc.push(item.tag_value);
            return acc;
          }, [])
        );
    });
  useEffect(() => {
    dropdownOption();
  }, []);
  const handleClose = () => {
    // setInitialValue();
    setOpen(false);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };
  const setFilterParams = (id, type, value) => {
    setFilterValue(prev => {
      return prev.map(item => {
        if (item.id === id) {
          return { ...item, value: value, type: type };
        }
        return item;
      });
    });
  };
  const setValueToAll = (type, value) => {
    setFilterValue(prev => {
      return prev.map(item => {
        return { ...item, value: value, type: type };
      });
    });
  };

  return (
    <React.Fragment>
      <Dialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} fullWidth>
        <Loader show={loader} />
        <DialogTitle
          className='fs-12 font-weight-bold'
          id='customized-dialog-title'
          sx={{
            minWidth: '500px',
            padding: '10px 20px'
          }}
        >
          Advanced Filter
        </DialogTitle>
        <IconButton aria-label='close' onClick={handleClose} className={style.close_option}>
          <CloseIcon />
        </IconButton>

        <DialogContent
          dividers
          fullWidth
          className={style.filter_popup}
          sx={{
            minWidth: '500px',
            padding: '10px 20px'
          }}
        >
          <React.Fragment>
            <Stack>
              <Stack direction='row' className='fs-12 w-40' alignItems={'center'}>
                <Stack className='fs-12'>
                  <SelectSmall
                    value={type}
                    setValue={e => {
                      reset();
                      setType(e);
                    }}
                    listOfOption={headerType}
                    label={''}
                  />
                </Stack>

                {type === 'all' && (
                  <>
                    <SelectSmall
                      value={filterValue[0].type}
                      setValue={e => {
                        setValueToAll(e, filterValue[0].value);
                      }}
                      listOfOption={filterType}
                      label={''}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Enter Score'
                      variant='outlined'
                      size='small'
                      fullWidth
                      value={filterValue[0].value}
                      onChange={e => setValueToAll(filterValue[0].type, e.target.value)}
                    />
                  </>
                )}
              </Stack>
              {type !== 'all' && (
                <Stack className='fs-12'>
                  {filterValue.map((item, index) => (
                    <Grid container spacing={2} key={index} alignItems={'center'}>
                      <Grid item xs={5} gutterBottom>
                        <Typography className='fs-14 text-right font-weight-bold'>{item.title}</Typography>
                      </Grid>
                      {item.variant === 'text' && (
                        <Grid item xs={3} className='fs-12 w-100'>
                          <SelectSmall
                            value={item.type}
                            setValue={e => {
                              setFilterParams(item.id, e, item.value);
                            }}
                            listOfOption={filterType}
                            label={''}
                          />
                        </Grid>
                      )}
                      <Grid item xs={item.variant === 'text' ? 4 : 10} className='fs-12 w-100' gutterBottom>
                        {item.variant === 'text' ? (
                          <TextField
                            id='outlined-basic'
                            label='Enter Score'
                            variant='outlined'
                            size='small'
                            fullWidth
                            value={item.value}
                            onChange={e => setFilterParams(item.id, item.type, e.target.value)}
                          />
                        ) : (
                          <>
                            <Select
                              fullWidth
                              size='small'
                              labelId='demo-multiple-chip-label'
                              id='demo-multiple-chip'
                              multiple
                              value={item.value}
                              onChange={e => setFilterParams(item.id, item.type, e.target.value)}
                              input={<OutlinedInput id='select-multiple-chip' label='Tags' />}
                              renderValue={selected => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map(value => (
                                    <Stack key={value} className='fs-12 mr-1'>
                                      <Chip label={value} />
                                    </Stack>
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {tags?.map(name => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Stack>
              )}
            </Stack>
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={async () => {
              setLoader(true);
              await onSave(filterValue);
              setLoader(false);
            }}
            variant='contained'
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
function TagFilter(props) {
  const { tagValue, setTagValue, refetch } = props;
  const [tags, setTags] = useState([]);
  const [loader, setLoader] = useState(false);
  const handleChange = event => {
    setTagValue(event.target.value);
  };
  const handleBlur = async () => {
    setLoader(true);
    await refetch();
    setLoader(false);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };
  const dropdownOption = async () =>
    await GetCandidateTagsApi().then(res => {
      setTags(
        res.data.reduce((acc, item) => {
          acc.push(item.tag_value);
          return acc;
        }, [])
      );
    });
  useEffect(() => {
    dropdownOption();
  }, []);
  return (
    <FormControl sx={{ minWidth: 200 }} size='small'>
      <InputLabel
        id='demo-simple-select-label'
        sx={{
          fontSize: '12px'
        }}
      >
        Select Tags
      </InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        minWidth={'300px'}
        // size='small'
        label='Select Tags'
        multiple
        value={tagValue}
        onChange={handleChange}
        onBlur={handleBlur}
        input={<OutlinedInput id='select-multiple-chip' labe label='Select Tags' />}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected &&
              selected?.length > 0 &&
              selected.slice(0, 1)?.map((value, index) => (
                <Stack key={index} className='fs-12 mr-1'>
                  <Chip label={value} />
                </Stack>
              ))}
            {selected && selected?.length > 1 && (
              <Stack className='fs-12 mr-1'>
                <Chip label={`+ ${selected.length - 1}`} />
              </Stack>
            )}
          </Box>
        )}
        MenuProps={MenuProps}
        endAdornment={<Loader show={loader} />}
      >
        {tags?.map(name => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const Search = ({ search, setSearch }) => {
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <FormControl variant='outlined'>
        <TextField
          id='input-with-icon-textfield'
          label='Search'
          minWidth={'200px'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchOutlinedIcon />
              </InputAdornment>
            )
          }}
          value={search}
          onChange={e => setSearch(e.target.value)}
          variant='filled'
        />
      </FormControl>
    </Box>
  );
};
function RenderBookmark(props) {
  return <div>RenderBookmark</div>;
}
Search.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func
};
const tablePrope = {
  value: PropTypes.string,
  data: PropTypes.object
};
RenderTags.propTypes = tablePrope;
RenderSummary.propTypes = tablePrope;

RenderCompanyName.propTypes = tablePrope;
RenderName.propTypes = tablePrope;
RenderRejectReasons.propTypes = tablePrope;
RenderOffLimits.propTypes = tablePrope;
RenderLiffer.propTypes = tablePrope;
ColorBasedscore.propTypes = tablePrope;
RenderActionProject.propTypes = tablePrope;
AppliedAt.propTypes = tablePrope;
LocationScore.propTypes = tablePrope;
RenderCheckbox.propTypes = tablePrope;
SelectSmall.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  listOfOption: PropTypes.array,
  label: PropTypes.string
};
RejectPopup.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
  onSave: PropTypes.func
};
FilterOption.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  filterValue: PropTypes.array,
  setFilterValue: PropTypes.func,
  type: PropTypes.string,
  setType: PropTypes.func,
  onSave: PropTypes.func,
  reset: PropTypes.func
};

EditLabelInlineComponent.propTypes = {
  index: PropTypes.string,
  keyValue: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.description,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func
};

EditLabelComponent.propTypes = {
  index: PropTypes.string,
  keyValue: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.description,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func
};
EditModal.propTypes = {
  elementIndex: PropTypes.string,
  index: PropTypes.string,
  keyValue: PropTypes.string,
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string
};
ReadMoreHTML.propTypes = {
  htmlContent: PropTypes.string,
  showMoreText: PropTypes.node,
  allText: PropTypes.string,
  onClick: PropTypes.func
};

TagFilter.propTypes = {
  tagValue: PropTypes.string,
  setTagValue: PropTypes.func,
  refetch: PropTypes.func
};
export {
  AppliedAt,
  ColorBasedscore,
  CustomizedDialogs,
  EditLabelComponent,
  EditLabelInlineComponent,
  EditModal,
  FilterOption,
  LocationScore,
  RejectPopup,
  RenderActionProject,
  RenderCheckbox,
  RenderCompanyName,
  RenderLiffer,
  RenderName,
  RenderOffLimits,
  RenderRejectReasons,
  RenderSummary,
  RenderTags,
  Search,
  TagFilter
};
//
