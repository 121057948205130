import { TRANS_KEYS } from '../languageNamespaces';
/* DB JSON Response */
const LANGUAGE_TRANSLATIONS = [
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_CONTACT_DETAILS_PROFILE_TITLE,
    value: 'Basic Info'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACT_OR, value: 'or' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ABOUT, value: 'About' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ACTION, value: 'Action' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ACTIVE, value: 'Active' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ACTIVITY_LOG, value: 'Activity Log' },
  {
    lang: 'en',
    key: TRANS_KEYS.ACTIVITY,
    value: 'Activity'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ACTIVITY_TYPE,
    value: 'Activity Type'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_ANOTHER_ACTIVITY,
    value: 'Add Another Activity'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_ANOTHER, value: 'Add Another' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_CONTACT_TO_PROJECT,
    value: 'Add Contact to Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_CONTACT_TO_WORKBENCH,
    value: 'Add Contacts to Workbench List'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.PROJECTS_ADD_PROJECT_TO_WORKBENCH,
    value: 'Add Projects to Workbench List'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_EDIT,
    value: 'Update Contact'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_CONTACT, value: 'Add Contact' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_DESCRIPTION,
    value: 'Add Description'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_DOCUMENT, value: 'Add Document' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_EDUCATION_DETAILS,
    value: 'Add Education Details'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_EMAIL, value: 'Add Email' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_LANGUAGES,
    value: 'Add Languages'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_NAME, value: 'Add Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_PHONE, value: 'Add Phone' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PHONE_NUMBER, value: 'Phone Number' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_NEW_CONTACT,
    value: 'Add New Contact'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_TO_LIST, value: 'Add To List' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_TO_PROJECT,
    value: 'Add To Project'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_TO, value: 'Add To' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ADD_WORK_EXPERIENCE,
    value: 'Add Work Experience'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_EXPERIENCE,
    value: 'Experience'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADDED_BY, value: 'Added By' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADDRESS_TYPE, value: 'Address Type' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADDRESS, value: 'Address' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ALL_CONTACTS, value: 'Contacts' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH,
    value: 'Approved to Publish'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ASSIGN_TAG, value: 'Assign Tag' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_TRY_ATHENA, value: 'View Athena' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_ATHENA_AND_ATTRIBUTE_SELECTOR,
    value: 'Athena & Attribute Selector'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PROJECTS_AND_ACTIVITIES,
    value: 'Projects and Activities'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_BASE_SALARY, value: 'Base Salary' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_BASIC_INFO, value: 'Basic Info' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_BOARD_MEMBER, value: 'Board Member' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_BONUS_TYPE, value: 'Bonus Type' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_BONUS_VALUE, value: 'Bonus Value' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_BONUS, value: 'Bonus' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_CALL, value: 'Call' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_CANCEL, value: 'Cancel' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_CHOOSE_PROJECT_NUMBER,
    value: 'Project Number'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_CHOOSE_PROJECT,
    value: 'Choose Project to add'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_CHOOSE_STAGE, value: 'Choose Stage' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_CITY, value: 'City' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_COMMITTEE_DETAILS,
    value: 'Committee Details'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_COMMUNICATION,
    value: 'Communication'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_COMPANY_NAME, value: 'Company Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_COMPANY, value: 'Company' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_COMPENSATION_INFO,
    value: 'Compensation Info'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_COMPENSATION_NOTES,
    value: 'Compensation Notes'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_COMPENSATION_RELEASE,
    value: 'Compensation Release'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_COMPENSATION, value: 'Compensation' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_CONCERN, value: 'Concern' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_CONTACT_DETAILS,
    value: 'Contact Details'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_CONTACT_NAME, value: 'Contact Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_COUNTRY, value: 'Country' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_CREATE_CONTACT,
    value: 'Create Contact'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_CREATE_FROM_RESUME,
    value: 'Create From Resume'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_CURRENCY_CODE,
    value: 'Currency Code'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_CURRENTLY_ENROLLED,
    value: 'Currently Enrolled'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_DATE_OF_BIRTH,
    value: 'Date Of Birth'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_DESCRIPTION, value: 'Description' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_DOCUMENT_TYPE,
    value: 'Document Type'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_DOCUMENT, value: 'Documents' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES,
    value: 'Education and Languages'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_EDUCATION, value: 'Education' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_EMAIL_TYPE, value: 'Email Type' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_EMAIL, value: 'Email' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ENGAGEMENT, value: 'Engagement' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_EXPECTED_COMPENSATION,
    value: 'Expected Compensation'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_EXPORT_LIST, value: 'Export List' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_FIELD_OF_STUDY,
    value: 'Field of study'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_FILE_NAME, value: 'File Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_FILTER_BY, value: 'Filter By' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_FILTERS, value: 'Filters' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_FIRST_NAME, value: 'First Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_FROM_YEAR, value: 'From Year' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_GENDER, value: 'Gender' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_HIGHLIGHTS, value: 'Highlights' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_INDUSTRY_AND_JOB_FUNCTION,
    value: 'Industry and Job Function'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_INDUSTRY, value: 'Industry' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_INTERACTION, value: 'Interaction' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_INVITE_TO_ATHENA,
    value: 'Invite To Athena'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_IS_PRIMARY, value: 'Is Primary' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_JOB_FUNCTION, value: 'Job Function' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_JOB_TITLE_AND_COMPANY,
    value: 'Job Title And Company'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_LEVEL_OF_EDUCATION_AND_FIELD_OF_STUDY,
    value: 'Level Of Education and Field Of Study'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_JOB_TITLE, value: 'Job Title' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_LANGUAGES, value: 'Languages' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_LANGUAGE, value: 'Language' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_LAST_CONTACT, value: 'Last Contact' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_LAST_NAME, value: 'Last Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_LAYOUT_DETAILS, value: 'Details' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_LEVEL_OF_EDUCATION,
    value: 'Level Of Education'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE,
    value: 'Level Of Expertise'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_LOCATION, value: 'Location' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY,
    value: 'Log An Activity'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RECENT_ACTIVITY,
    value: 'Recent Activity'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RECENT_ACTIVITIES,
    value: 'Recent Activities'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_LTI, value: 'LTI' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_MAKE_CONFIDENTIAL,
    value: 'Make Confidential'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_MAKE_PRIMARY, value: 'Make Primary' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_MARK_NOTE_AS, value: 'Mark Note As' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_MESSAGES, value: 'Messages' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_METROPOLITAN_AREA,
    value: 'Metropolitan Area'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_MORE_FILTERS, value: 'More Filters' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_NAME, value: 'Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_NOTES, value: 'Notes' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_NOTE_FOR, value: 'Add Note For' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ADD_TASK_FOR, value: 'Add Task For' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_TASK, value: 'Task' },
  { lang: 'en', key: TRANS_KEYS.ECHO_SENSE, value: 'Echo Sense' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PENDING_TASK, value: 'Pending Task' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_OR, value: 'or' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_OUT_OF, value: 'out of' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PASTE_LINKEDIN_URL,
    value: 'Paste LinkedIn Url'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_LINKEDIN_URL,
    value: 'LinkedIn Url'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PERSONAL_INFO_AND_COMMUNICATION,
    value: 'Communication'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PERSONAL_INFO,
    value: 'Personal Info'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PERSONAL_NOTE,
    value: 'Personal Notes'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PERSONALITY, value: 'Personality' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PHONE_TYPE, value: 'Phone Type' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PHONE, value: 'Phone' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PRIVATE_CONTACT,
    value: 'Private Contact'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE,
    value: 'Professional Experience'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PROFESSIONAL_INFO,
    value: 'Professional Info'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PROFESSIONAL,
    value: 'Professional Notes'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PROFILE, value: 'Profile' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PROJECT_NUMBER,
    value: 'Project Number'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PROJECT, value: 'Project' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_PROJECTS, value: 'Projects' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_PUBLISHED_BIO,
    value: 'Published Bio'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RECENT_ENGAGEMENT,
    value: 'Recent Engagement'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RECENT_ACTIVITIES,
    value: 'Recent Activities'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RECENT_ACTIVITY,
    value: 'Recent Activity'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RECOMMENDATION,
    value: 'Recommendation'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_RECORD_TYPE, value: 'Record Type' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RELATION_TYPE,
    value: 'Relation Type'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_RELATIONSHIP, value: 'Relationship' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RESET_FILTERS,
    value: 'Reset Filters'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SAVE_AS, value: 'Save As' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SAVE_FILTERS, value: 'Save Filters' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SAVE_LIST, value: 'Save List' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SAVE, value: 'Save' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SCHOOL_NAME, value: 'School Name' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SCREENED, value: 'Screened' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_SCREENING_NOTE,
    value: 'Screening Notes'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_SEARCH_CONTACT,
    value: 'Search Contact'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SEARCH, value: 'Search' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SELECT_DATA, value: 'Select Data' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_SELECT_FILE_TYPE,
    value: 'Select File Type'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_SELECT_FILTERS,
    value: 'Select Filters'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_SELECT_PROJECT,
    value: 'Select Project'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SELECT_TAG, value: 'Select Tag' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SELECT_TYPE, value: 'Select Type' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SELECT, value: 'Select' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SELECTED, value: 'Selected' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SEND_MAIL, value: 'Send Mail' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_SHARE_WITH_IGNYTE_USER,
    value: 'Share with Ignyte User'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SHARE, value: 'Share' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SOURCE, value: 'Source' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_STATE, value: 'State' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_STRENGTH, value: 'Strength' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SUBJECT, value: 'Subject' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_TAGS, value: 'Tags' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_THEY_ARE_A_BOARD_MEMBERS,
    value: 'They are a Board Member'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_THEY_CURRENTLY_WORK_HERE,
    value: 'They currently work here'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_TIME_PERIOD, value: 'Time Period' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_TO_SAVE, value: 'to save' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_TO_YEAR, value: 'To Year' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_TOTAL_COUNT, value: 'Total Count' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_UPDATE_DOCUMENT,
    value: 'Update Document'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_UPDATE_EDUCATION_DETAILS,
    value: 'Update Education Details'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_UPDATE, value: 'Update' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_UPDATED_BY, value: 'Updated by' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_UPLOAD_FILE, value: 'Upload File' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_UPLOAD_RESUME,
    value: 'Add Resume'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_VIEW_ACTIVITY_LOG,
    value: 'View Activity Log'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_VIEW_RESUME, value: 'View Resume' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_DELETE_CONTACT,
    value: 'Delete Contact'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_VIEW_LINKEDIN,
    value: 'View LinkedIn'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORD_ADD, value: 'Add' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORD_AND, value: 'and' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORD_FOR, value: 'For' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORD_FROM, value: 'From' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORD_ON, value: 'on' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORD_TO, value: 'To' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORD_YOU, value: 'You' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_WORK_ADDRESS, value: 'Work Address' },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_RESIDENTIAL_ADDRESS,
    value: 'Residential Address'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACTS_WORK_EXPERIENCE,
    value: 'Work Experience'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_ZIP, value: 'Zip' },
  {
    lang: 'en',
    key: TRANS_KEYS.HEADERS_ADD_TO_FAVORITE,
    value: 'Add to Favorite'
  },
  { lang: 'en', key: TRANS_KEYS.HEADERS_CALENDAR, value: 'Calendar' },
  { lang: 'en', key: TRANS_KEYS.HEADERS_HELP, value: 'Help' },
  { lang: 'en', key: TRANS_KEYS.HEADERS_MASTER_SEARCH, value: 'Search' },
  { lang: 'en', key: TRANS_KEYS.HEADERS_NOTIFICATIONS, value: 'Notifications' },
  { lang: 'en', key: TRANS_KEYS.SIDENAV_COMPANIES, value: 'Companies' },
  { lang: 'en', key: TRANS_KEYS.SIDENAV_CONTACTS, value: 'Contacts' },
  { lang: 'en', key: TRANS_KEYS.SIDENAV_SEARCHES, value: 'Searches' },
  { lang: 'en', key: TRANS_KEYS.SIDENAV_SETUP, value: 'Setup' },
  { lang: 'en', key: TRANS_KEYS.SIDENAV_USERS, value: 'Users' },
  { lang: 'en', key: TRANS_KEYS.SIDENAV_WORKBENCHES, value: 'Workbenches' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SHOW_IN, value: 'Show In' },
  { lang: 'en', key: TRANS_KEYS.ADD_PROJECT, value: 'Add Project' },
  { lang: 'en', key: TRANS_KEYS.ADD_PROJECT_INPUT_PROJECT, value: 'Project#' },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_SERVICE_OFFERING,
    value: 'Service Offering'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_COMPANY_NAME,
    value: 'Company Name'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_JOB_TITLE,
    value: 'Job Title'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_INDUSTRY,
    value: 'Industry'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_JOB_TYPE,
    value: 'Job Type'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_LOCATION,
    value: 'Location'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_INPUT_PROJECT_OWNER,
    value: 'Project Owner'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_INPUT_EXPERIENCE_FROM,
    value: 'Experience From (Years)'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_INPUT_EXPERIENCE_TO,
    value: 'Experience To (Years)'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_INPUT_MIN_COMPENSATION,
    value: 'Min Compensation'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_INPUT_MAX_COMPENSATION,
    value: 'Max Compensation'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_INPUT_ADDITIONAL_INFORMATION,
    value: 'Additional Information'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_CLIENT_CONTACT_DETAILS,
    value: 'Client Contact'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SELECT_HIRING_MANAGER,
    value: 'Hiring Manager'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_TITLE_SKILLS,
    value: 'Skills'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_TITLE_COMPETENCIES,
    value: 'Competencies'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_INDUSTRIES,
    value: 'Target Industries'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_LOCATION,
    value: 'Target Location'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_TITLE,
    value: 'Target Title'
  },
  { lang: 'en', key: TRANS_KEYS.ADD_PROJECT_LABEL_INDUSTRY, value: 'Industry' },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_SUB_INDUSTRY,
    value: 'Sub Industry'
  },
  { lang: 'en', key: TRANS_KEYS.ADD_PROJECT_LABEL_WEIGHT, value: 'Weight' },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_INDUSTRY,
    value: 'Add Industry'
  },
  { lang: 'en', key: TRANS_KEYS.ADD_PROJECT_LABEL_COUNTRY, value: 'Country' },
  { lang: 'en', key: TRANS_KEYS.ADD_PROJECT_LABEL_STATE, value: 'State' },
  { lang: 'en', key: TRANS_KEYS.ADD_PROJECT_LABEL_TITLE, value: 'Title' },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_REVENUE_RANGE,
    value: 'Revenue Range'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_EMPLOYEE_RANGE,
    value: 'Employee Range'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_COMPANIES,
    value: 'Target Companies'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_FUNCTION,
    value: 'Target Function'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_CAPITAL_STRUCTURE,
    value: 'Target Capital Structure'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_MIN_YEARS_OF_EXPERIENCE,
    value: 'Min Years Of Experience'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_MAX_YEARS_IN_CURRENT_COMPANY,
    value: 'Max Years In Current Company'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_LOCATION,
    value: 'Add Location'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_TITLE,
    value: 'Add Title'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_HIDE_IN_JOB_POSTING,
    value: 'Hide In Job Posting'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_BUTTON_REVIEW_AND_PUBLISH,
    value: 'Review And Publish'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_SKILL,
    value: 'Add Skill'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_COMPETENCY,
    value: 'Add Competency'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SAVE_AND_CONTINUE,
    value: 'Save And Continue'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_BUTTON_CREATE_PROJECT,
    value: 'Create Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.UPDATE_PROJECT_BUTTON_CREATE_PROJECT,
    value: 'Update Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BACK,
    value: 'Back'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_COMPENSATION_FREQUENCY,
    value: 'Compensation Frequency'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_LABEL_CURRENCY_TYPE,
    value: 'Currency'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_HEADER,
    value: 'Project Created'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_SUBTITLE,
    value: 'HELIA is building your project roadmap...'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_SUBTITLE_SUCCESS,
    value: 'HELIA has build your project roadmap.'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_BUTTON,
    value: 'Review Search Roadmap'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_PROJECT_GENERATE_MESSAGE,
    value: 'Generating Position Profile...'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ALL_PROJECTS,
    value: 'All Projects'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.VIEW_PROJECTS_SCOPE_HEADER_TEXT,
    value: 'Here are a few Suggestions  from our Helia for you to create a perfect Search Roadmap to help you  find the right candidates, Please select your requirements...'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_COMPANY,
    value: 'Add Company'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.POSITION_PROFILE,
    value: 'Position Profile'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CUSTOM_RECOMMENDATION_SELECTOR_HELPER,
    value: 'Thumps Up the one’s You want to add'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SHOW_MORE,
    value: 'Show More'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SAVE_AND_NEXT,
    value: 'Save And Next'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.EXPAND_ALL,
    value: 'Expand All'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.COLLAPSE_ALL,
    value: 'Collapse All'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.MINIMAL_TITLE,
    value: 'Minimal'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.QUESTION_TITLE,
    value: 'Question'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.IDEAL_TITLE,
    value: 'Ideal'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_EXPERIENCE,
    value: 'Add Experience'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_ELEMENT,
    value: 'Add Element'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.PUBLISHED_BUTTON,
    value: 'published'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.UNPUBLISHED_BUTTON,
    value: 'unpublished'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SEARCH_ROAD_MAP,
    value: 'Search Roadmap'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.OPEN_BUTTON,
    value: 'Open'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.NO_OF_DAYS,
    value: 'Days open'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SEARCH_HEALTH,
    value: 'Search Health'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_CANDIDATE,
    value: 'Add Candidate'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SEARCH_STATUS_REPORT,
    value: 'Search Status Report'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.VIEW_REPORTS,
    value: 'View Reports'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.LEADERSHIP_TITLE,
    value: 'Leadership'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_COMPETENCY,
    value: 'Add Competency'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.MINIMUM,
    value: 'Minimum'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.MAXIMUM,
    value: 'Maximum'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_TARGET_COMPANIES,
    value: 'Add Target Companies'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_TARGET_INDUSTRIES,
    value: 'Add Target Industries'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_COMPANIES_TO_AVOID,
    value: 'Add Companies to Avoid'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_TARGET_TITLE,
    value: 'Add Target Title'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_GEOLOCATION,
    value: 'Add Geolocation'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_TARGET_FUNCTION,
    value: 'Add Target Function'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_CAPITAL_STRUCTURE,
    value: 'Add Capital Structure'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.EQUITY,
    value: 'Equity'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.MANDATORY,
    value: 'Mandatory'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CERTIFICATION,
    value: 'Certification'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_CERTIFICATION,
    value: 'Add Certification'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TOTAL_BASE_AND_BONUS,
    value: 'Total base and bonus'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE,
    value: 'Certification & License'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE,
    value: 'Add Certification & License'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CERTIFICATION_TYPE,
    value: 'Type'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CERTIFICATION_EXPIRE_ON,
    value: 'Expires on'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CERTIFICATION_TITLE,
    value: 'Title'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.GENERATE,
    value: 'Generate'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.RE_GENERATE,
    value: 'Re-Generate'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.HELIA_BUILD_ROAD_MAP_TEXT,
    value: 'HELIA built Roadmap, customize by adding or removing items as needed'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SOMETHING_WENT_WRONG,
    value: 'Something Went Wrong'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CONTACT_CREATE_FROM_RESUME,
    value: 'Create from Resume'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.DUPLICATE_RECORD_DETECTED,
    value: 'Duplicate Record Detected'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.EXACT_MATCH_IDENTIFIED,
    value: ' Exact match identified via LinkedIn/Email/Phone:'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.EXACT_MATCH_IDENTIFIED_VIA,
    value: ' Exact match identified via '
  },
  {
    lang: 'en',
    key: TRANS_KEYS.POTENTIAL_MATCH_IDENTIFIED,
    value: '  Potential match found for:'
  },
  { lang: 'en', key: TRANS_KEYS.OFF_LIMIT_VIEW_REASONS, value: 'View Reasons' },
  { lang: 'en', key: TRANS_KEYS.OFF_LIMITS_CAUSE, value: 'Off Limits Cause' },
  {
    lang: 'en',
    key: TRANS_KEYS.OFF_LIMITS_REASONS,
    value: 'Off Limits Reasons'
  },
  { lang: 'en', key: TRANS_KEYS.OFF_LIMIT_DETAILS, value: 'Details' },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_TAG_NAME,
    value: 'Tag Name'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_COLOUR_LABEL,
    value: 'Colour Label'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_MODULE,
    value: 'Module'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_STATUS,
    value: 'Status'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_ADD_TAGS,
    value: 'Add Tags'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_TAGS_SETTING,
    value: 'Tags Setting'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.DELETE,
    value: 'Delete'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_TAGS_SETUP,
    value: 'Tags Setup'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TAGS_SETUP_TAGS_CONTENT,
    value: 'Content For Profile'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_SETUP,
    value: 'Translate Values Setup'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_CONTENT,
    value: 'Content For Translate Value Setup'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUE,
    value: 'Add Translate Value'
  },

  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUES,
    value: 'Add Translate Values'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_ACTIVE,
    value: 'Active'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_LANGUAGE,
    value: 'Language'
  },

  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_EDIT_VALUE,
    value: 'Edit Translate Value'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_SAVE_CHANGES,
    value: 'Save Changes'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_FIELD_VALUE,
    value: 'Field Value'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_FIELD_NAME,
    value: 'Field Name'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_LANG_CD,
    value: 'Language Code'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_SHORT_DESC,
    value: 'Short Description'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.TRANSLATE_VALUE_LONG_DESC,
    value: 'Long Description'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.NEXT,
    value: 'Next'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.DECISION_HEADER,
    value:
      'Decision environments can be characterized along two key dimensions. First, does the client company emphasize growth or optimization in its strategies and operations. Second, clients differ in the way people are expected to work together.<br>  Do they collaborate intensively, <br> or do they focus on personal accountability? <br> Once these two core dimensions are established, there are 5 sub-dimensions that can provide further insight into the company’s decision environment.'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.LEADERSHIP_HEADER,
    value: 'DUMMY LEADERSHIP TEXT'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.EXPERIENCE_HEADER,
    value: 'DUMMY EXPERIENCE TEXT'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_BASIC_INFO,
    value: 'Basic Info'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_OPPORTUNITY,
    value: 'BD'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_STAGE,
    value: 'Stage'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_COMPANY,
    value: 'Company'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_CLIEN_CONTACTS,
    value: 'Client Contacts'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_JOB_TITLE,
    value: 'Job Title'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_PROJECTED_START_DATE,
    value: 'Projected Start Date'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_LOCATION,
    value: 'Location'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_CONSULTANT,
    value: 'Consultant'
  },
  { lang: 'en', key: TRANS_KEYS.PROJECTS_SELECT_TAG, value: 'Select Tag' },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_CONSULTANT_TITLE,
    value: 'Consultant'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_PROBABILITY,
    value: 'Probability'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_ESTIMATED_REVENUE,
    value: 'Estimated Revenue'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_ADD_PROJECT,
    value: 'Add Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_CREATE_NEW_PROJECT,
    value: 'Create New Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_LINK_EXISTING_PROJECT,
    value: 'Link Existing Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_BD_STATUS,
    value: 'BD Status'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_SERVICE_OFFERING,
    value: 'Service Offering'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_GEOGRAPHY,
    value: 'Geography'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_COMPETITION,
    value: 'Competition'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_LOCATION,
    value: 'Location'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_INFO,
    value: 'Team Information'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_PARTNER_LABEL,
    value: 'Partner'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_PARTNER_TITLE,
    value: 'Partner Information'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_ADMIN_LABEL,
    value: 'Admin'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_ADMIN_TITLE,
    value: 'Admin'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_RECRUITER_LABEL,
    value: 'Recruiter'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_RECRUITER_TITLE,
    value: 'Recruiter'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_RESEARCHER_LABEL,
    value: 'Researcher'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_RESEARCHER_TITLE,
    value: 'Researcher'
  },

  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_NAME,
    value: 'Name'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_LEAD,
    value: 'Lead'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_ORIGINATION,
    value: 'Origination Credit'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_SELLING,
    value: 'Selling Credit'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_EXECUTION,
    value: 'Execution Credit'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BD_TEAM_TOTAL_CREDIT,
    value: 'Total Credit'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_INDUSTRIES, value: 'Industries' },
  { lang: 'en', key: TRANS_KEYS.HORIZONTAL_PRACTICE, value: 'Horizontal Practice' },
  { lang: 'en', key: TRANS_KEYS.VERTICAL_PRACTICE, value: 'Vertical Practice' },
  { lang: 'en', key: TRANS_KEYS.RESULT, value: 'Result' },
  { lang: 'en', key: TRANS_KEYS.PROJECT_TYPE, value: 'Project Type' },
  { lang: 'en', key: TRANS_KEYS.PROJECT_VC_PE, value: 'Project VC/PE' },
  { lang: 'en', key: TRANS_KEYS.ENCORE_NUMBER, value: 'Encore Number' },
  { lang: 'en', key: TRANS_KEYS.SHOWING, value: 'Showing' },
  {
    lang: 'en',
    key: TRANS_KEYS.SAVE_GENERATE_ROADMAP,
    value: 'Save And Generate Roadmap'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.GENERATING_POSITION_PROFILE,
    value: 'Generating Position Profile ...'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.GENERATING_POSITION_PROFILE,
    value: 'Generating Position Profile ...'
  },
  { lang: 'en', key: TRANS_KEYS.ATHENA, value: 'Athena' },
  { lang: 'en', key: TRANS_KEYS.SCORES, value: 'Scores' },
  { lang: 'en', key: TRANS_KEYS.DECISION_FRAMEWORK, value: 'Decision Framework' },
  { lang: 'en', key: TRANS_KEYS.CANDIDATES_IN_PIPELINE, value: 'Candidates in Pipeline' },
  { lang: 'en', key: TRANS_KEYS.PROJECT_ID, value: 'Project Id' },
  { lang: 'en', key: TRANS_KEYS.DICTATION, value: 'Dictation' },
  { lang: 'en', key: TRANS_KEYS.DESCRIPTION, value: 'Description' },
  { lang: 'en', key: TRANS_KEYS.DATE_TIME, value: 'Date & Time' },
  { lang: 'en', key: TRANS_KEYS.TIME, value: 'Time' },
  { lang: 'en', key: TRANS_KEYS.TIMEZONE, value: 'Time Zone' },
  { lang: 'en', key: TRANS_KEYS.ATTENDEES, value: 'Attendees' },
  { lang: 'en', key: TRANS_KEYS.DUE_DATE, value: 'Due Date' },
  { lang: 'en', key: TRANS_KEYS.ALL_TIMEZONES, value: 'All Timezones' },
  { lang: 'en', key: TRANS_KEYS.PROJECT_TEAM, value: 'Project Team' },
  { lang: 'en', key: TRANS_KEYS.ALL_USERS, value: 'All Users' },
  { lang: 'en', key: TRANS_KEYS.CLIENT_CONTACTS, value: 'Client Contacts' },
  { lang: 'en', key: TRANS_KEYS.START_DATE, value: 'Start Date' },
  { lang: 'en', key: TRANS_KEYS.LOE_DATE, value: 'LOE Date' },
  { lang: 'en', key: TRANS_KEYS.OFFHOLD_DATE, value: 'Offhold Date' },
  { lang: 'en', key: TRANS_KEYS.END_DATE, value: 'End Date' },
  { lang: 'en', key: TRANS_KEYS.START_RECORDING, value: 'Start Dictation' },
  { lang: 'en', key: TRANS_KEYS.STOP_RECORDING, value: 'Stop Dictating' },
  { lang: 'en', key: TRANS_KEYS.PLEASE_WAIT, value: 'Please Wait' },
  { lang: 'en', key: TRANS_KEYS.MAX_RECORDING_TIME, value: 'Max dictation time is 3 minutes.' },
  { lang: 'en', key: TRANS_KEYS.LOGGED_A, value: 'logged a' },
  {
    lang: 'en',
    key: TRANS_KEYS.ATHENA_INVITE_HEADING,
    value: 'Invite To Athena : Email Required'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ATHENA_INVITE_SUB_HEADING,
    value: 'Contact does not have a email. Please add a email to send Athena Invite'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ATHENA_INVITE_SAVE_AND_INVITE,
    value: 'Invite'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.JOB_PORTAL_SUCCESS,
    value: 'Application is submitted successfully'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.JOB_PORTAL_SUCCESS_WITH_RESUME,
    value: 'Application and Resume are submitted successfully'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.FIT_SCORE,
    value: 'Fit Score'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.FIT_SCORE_EXPERIENCE_SCORE,
    value: 'Experience Score'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.FIT_SCORE_COMPETENCY_SCORE,
    value: 'Competency Score'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_PROJECT,
    value: 'Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_CANDIDATE,
    value: 'Candidate'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_REPORT,
    value: 'Report'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_TEMPLATE,
    value: 'Template'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_LANGUAGE,
    value: 'Language'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_FILE_FORMAT,
    value: 'File Format'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_SELECT_PIPELINE,
    value: 'Select Pipeline'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_GENERATE_AND_DOWNLOAD,
    value: 'Generate and Download'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_REPORTS,
    value: 'Reports'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REPORT_BUILDER_REPORTS,
    value: 'Reports'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_TO_WORKBENCH,
    value: 'Add To Workbench'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.PROJECT_NAME,
    value: 'Project Name'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_LIST,
    value: 'Add List'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.MY_LIST,
    value: 'My List'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ALL_LIST,
    value: 'All List'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.OWNER,
    value: 'Owner'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.LIST_NAME,
    value: 'List Name'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.WORKBENCH_DELETE_HEADER,
    value: 'Are You Sure?'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REMOVE,
    value: 'Remove'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.WELCOME_TO_WORKBENCH,
    value: 'Welcome To Workbench'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.NO_WORKBENCH_PARA,
    value: 'It looks like you don`t have any lists yet. Click the Add List button to create your first list and get started.'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CREATED_BY,
    value: 'Created By'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CREATED_ON,
    value: 'Created On'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.UPDATED_ON,
    value: 'Updated On'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.LIST_TYPE,
    value: 'List Type'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REMOVE_PROJECT_FROM_WORKBENCH,
    value: 'Remove Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.REMOVE_CONTACT_FROM_WORKBENCH,
    value: 'Remove Contact'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.DELETE_WORKBENCH,
    value: 'Delete Workbench'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.ADD_EXISTING_PROJECT,
    value: 'Add Existing Project'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.PIPELINE_WITH_TARGETS,
    value: 'Pipeline Targets Only'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.FULL_PIPELINE,
    value: 'Full Pipeline'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.NO_PIPELINE,
    value: 'No Pipeline'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.PIPELINE_NO_TARGETS,
    value: 'Pipeline with No Targets'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CLIENT_FEEDBACK,
    value: 'Client Feedback'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.MOVED_TO,
    value: 'Moved to'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.BILLING_STATUS,
    value: 'Billing Status'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.SUMMARIZE,
    value: 'Summarize'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.CLIENT_INTAKE_CALL,
    value: 'Client Intake Call'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.POSITION_PROFILE_HEADER,
    value: 'DUMMY POSITION PROFILE HEADER'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.DELETE_ACTIVITY,
    value: 'Activity Deleted Successfully'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.EDITED_BY,
    value: 'Edited By'
  },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_MARK_OFF_LIMITS, value: 'Mark Off-limits' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_DURATION, value: 'Duration' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_INDEFINITE, value: 'Indefinite' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SPECIFIC_END_DATE, value: 'Specific End Date' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SEVERITY, value: 'Severity' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_HARD, value: 'Hard' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_SOFT, value: 'Soft' },
  { lang: 'en', key: TRANS_KEYS.CONTACTS_OFF_LIMITS_REASON, value: 'Off-limits Reason' },
  { lang: 'en', key: TRANS_KEYS.CONFIGURE_CANDIDATE_STATUS_TITLE, value: 'Configure Candidate Status & Project Status combinations that should apply an inherited off-limits on a candidate.' },
  { lang: 'en', key: TRANS_KEYS.PROJECT_STATUS_DRAFT, value: 'Draft' },
  { lang: 'en', key: TRANS_KEYS.PROJECT_STATUS_OPEN, value: 'Open' },
  { lang: 'en', key: TRANS_KEYS.PROJECT_STATUS_CLOSED, value: 'Closed' },
  { lang: 'en', key: TRANS_KEYS.PICK_A_DATE, value: 'Pick a date' },
  { lang: 'en', key: TRANS_KEYS.EDIT_OFF_LIMIT_TITLE, value: 'Edit Off-limits' },
  { lang: 'en', key: TRANS_KEYS.MARK_OFF_LIMIT_TITLE, value: 'Mark Off-limits' },
  { lang: 'en', key: TRANS_KEYS.SHOW_OFF_LIMIT_TITLE, value: 'Off-limits Info' },
  { lang: 'en', key: TRANS_KEYS.DIRECT_OFF_LIMIT, value: 'Direct Off-limit' },
  { lang: 'en', key: TRANS_KEYS.INHERITED_OFF_LIMIT, value: 'Inherited Off-limit' },
  {
    lang: 'en',
    key: TRANS_KEYS.PARSE,
    value: 'Parse'
  },
  {
    lang: 'en',
    key: TRANS_KEYS.RESUME_PARSE_LIMIT,
    value: 'Only 25 files can be parsed at once, please remove '
  },
  {
    lang: 'en',
    key: TRANS_KEYS.RESUME_PARSE_HELPER,
    value: 'Please select one or more resumes to parse (Maximum 25 files)'
  }
];

export const getBackendNamespaces = body => {
  const { namespaces = [], lang = 'en' } = body;
  const list = LANGUAGE_TRANSLATIONS.filter(ele => {
    return ele.lang === lang && namespaces.find(el => el === ele.key) ? true : false;
  });
  return { data: list };
};
