import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DrawerComponent from '../../../../components/common/Drawer';
import Loader from '../../../../components/common/Loader';

import { Box, Slide, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import CustomButton from '../../../../components/common/CustomButton';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import { downloadHeliaReports } from '../../../../services/ApiService';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';

const ViewReportDrawer = ({ candidateName, projectName, isDrawerOpen, setIsDrawerOpen, fromHeaderBar, projectId, contactId, candidateId }) => {
  const [drawerWidth, setDrawerWidth] = useState();
  const [isWidthApply, setIsWidthApply] = useState();
  const [loading, setLoading] = useState(false);
  const templates = ['STANDARD'];
  const languages = [
    {
      title: 'English',
      value: 'eng'
    },
    {
      title: 'Spanish',
      value: 'esp'
    },
    {
      title: 'Portuguese',
      value: 'por'
    }
  ];
  const fileFormats = ['DOCX', 'PDF'];

  const reports = fromHeaderBar
    ? [
        { title: 'Search Status Report', value: 'IGN_SSR' },
        { title: 'Search Roadmap Report', value: 'IGN_SRR' }
      ]
    : [
        { title: 'Candidate Details Report', value: 'IGN_CDR' },
        { title: 'Interview Preparation Report', value: 'IGN_IPR' }
      ];

  const {
    register,
    unregister,
    setValue,
    watch,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onTouched'
  });
  const handleDownloadReport = async () => {
    try {
      setLoading && setLoading(true);
      const payload = {
        project_id: projectId || '',
        contact_id: contactId || '',
        language: watch('language').value,
        report_code: watch('report').value,
        candidate_id: candidateId,
        file_Type: `${watch('file_format')}`.toLocaleLowerCase(),
        report_group_name: getValues('pipeline').value
      };
      const res = await downloadHeliaReports(payload);
      // its a pdf file so we can download it directly
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', `${candidateName || projectName}_${payload.report_code}_report.${`${watch('file_format')}`.toLocaleLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setLoading && setLoading(false);
    } catch (err) {
      setLoading && setLoading(false);
    }
  };
  const { t } = useLanguageHooks([
    TRANS_KEYS.REPORT_BUILDER_PROJECT,
    TRANS_KEYS.REPORT_BUILDER_CANDIDATE,
    TRANS_KEYS.REPORT_BUILDER_REPORT,
    TRANS_KEYS.REPORT_BUILDER_TEMPLATE,
    TRANS_KEYS.REPORT_BUILDER_FILE_FORMAT,
    TRANS_KEYS.REPORT_BUILDER_LANGUAGE,
    TRANS_KEYS.REPORT_BUILDER_SELECT_PIPELINE,
    TRANS_KEYS.REPORT_BUILDER_GENERATE_AND_DOWNLOAD,
    TRANS_KEYS.REPORT_BUILDER_REPORTS,
    TRANS_KEYS.PIPELINE_WITH_TARGETS,
    TRANS_KEYS.PIPELINE_NO_TARGETS,
    TRANS_KEYS.FULL_PIPELINE,
    TRANS_KEYS.NO_PIPELINE
  ]);

  // TODO: Call pipeline API to get the pipelines
  const [pipelines] = useState([
    {
      key: t(`${TRANS_KEYS.PIPELINE_WITH_TARGETS}:key`),
      value: TRANS_KEYS.PIPELINE_WITH_TARGETS
    },
    {
      key: t(`${TRANS_KEYS.PIPELINE_NO_TARGETS}:key`),
      value: TRANS_KEYS.PIPELINE_NO_TARGETS
    },
    {
      key: t(`${TRANS_KEYS.FULL_PIPELINE}:key`),
      value: TRANS_KEYS.FULL_PIPELINE
    },
    {
      key: t(`${TRANS_KEYS.NO_PIPELINE}:key`),
      value: TRANS_KEYS.NO_PIPELINE
    }
  ]);

  const handleClose = () => {
    setIsDrawerOpen(false);
    setValue('template', 'STANDARD');

    trigger();
  };

  useEffect(() => {
    register('template', { required: 'Template is mandatory' });
    register('report', { required: 'Report is mandatory' });
    register('language', { required: 'Please select the language' });
    register('file_format', { required: 'Please select the file format' });
    register('pipeline', { required: 'Please select the pipeline' });
    setValue('report', fromHeaderBar ? { title: 'Search Status Report', value: 'IGN_SSR' } : { title: 'Candidate Details Report', value: 'IGN_CDR' });
    setValue('template', 'STANDARD');

    setValue('language', { title: 'English', value: 'eng' });
    setValue('file_format', 'DOCX');
    setValue('pipeline', pipelines?.[0]);

    return () => {
      unregister('template');
      unregister('report');
      unregister('language');
      unregister('file_format');
    };
  }, [register, unregister]);

  const handleGenerateAndDownload = async () => {
    // const formData = getValues();
    trigger();
    // console.log({ formData, projectName, candidateName, errors });
    if (Object.keys(errors).length !== 0) return;
    await handleDownloadReport();
  };

  return (
    <DrawerComponent
      setIsMenuOpen={setIsDrawerOpen}
      isMenuOpen={isDrawerOpen}
      id={'Contact_view_drawer'}
      anchor={'right'}
      open={isDrawerOpen}
      isViewDrawer={false}
      onClose={handleClose}
      closeIconPosition='close-rectangle-position'
      width='48vw'
      drawerWidth={drawerWidth}
      setDrawerWidth={setDrawerWidth}
      isWidthApply={isWidthApply}
      setIsWidthApply={setIsWidthApply}
      transitionType={Slide}
    >
      <>
        <Loader show={loading} />
        <Box padding={4}>
          <Typography fontWeight={'bolder'}>{t(`${TRANS_KEYS.REPORT_BUILDER_REPORTS}:key`)}</Typography>
          <Box marginTop={4} display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={4}>
            <TextField label={t(`${TRANS_KEYS.REPORT_BUILDER_PROJECT}:key`)} value={projectName} disabled size='small' />

            {!fromHeaderBar && <Box></Box>}

            {!fromHeaderBar && <TextField label={t(`${TRANS_KEYS.REPORT_BUILDER_CANDIDATE}:key`)} value={candidateName} disabled size='small' />}

            <Box></Box>
            <Box display={'flex'} flexDirection={'column'}>
              <CustomMuiDropdown
                size='small'
                label='Report'
                getOptionLabel={option => {
                  return option.title;
                }}
                name='report'
                onChange={(e, val) => {
                  setValue('report', val);

                  trigger('report');
                }}
                options={reports}
                value={watch('report')}
              />
              <Typography color={'red'} fontSize={'12px'}>
                {errors?.['report']?.message}
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <CustomMuiDropdown
                size='small'
                label={t(`${TRANS_KEYS.REPORT_BUILDER_TEMPLATE}:key`)}
                name='template'
                getOptionLabel={option => {
                  return option;
                }}
                onChange={(e, val) => {
                  setValue('template', val);
                  trigger('template');
                }}
                options={templates}
                value={watch('template')}
              />
              <Typography color={'red'} fontSize={'12px'}>
                {errors?.['template']?.message}
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <CustomMuiDropdown
                size='small'
                label={t(`${TRANS_KEYS.REPORT_BUILDER_LANGUAGE}:key`)}
                name='language'
                getOptionLabel={option => {
                  return option.title;
                }}
                onChange={(e, val) => {
                  setValue('language', val);
                  trigger('language');
                }}
                options={languages}
                value={watch('language')}
              />
              <Typography color={'red'} fontSize={'12px'}>
                {errors?.['language']?.message}
              </Typography>
            </Box>

            <Box display={'flex'} flexDirection={'column'}>
              <CustomMuiDropdown
                size='small'
                label={t(`${TRANS_KEYS.REPORT_BUILDER_FILE_FORMAT}:key`)}
                name='file_format'
                getOptionLabel={option => {
                  return option;
                }}
                onChange={(e, val) => {
                  setValue('file_format', val);
                  trigger('file_format');
                }}
                options={fileFormats}
                value={watch('file_format')}
              />
              <Typography color={'red'} fontSize={'12px'}>
                {errors?.['file_format']?.message}
              </Typography>
            </Box>

            {fromHeaderBar && getValues('report')?.value === 'IGN_SSR' && (
              <Box display={'flex'} flexDirection={'column'}>
                <CustomMuiDropdown
                  size='small'
                  label={t(`${TRANS_KEYS.REPORT_BUILDER_SELECT_PIPELINE}:key`)}
                  name='pipeline'
                  getOptionLabel={option => {
                    return option.value;
                  }}
                  onChange={(e, val) => {
                    setValue('pipeline', val);
                    trigger('pipeline');
                  }}
                  options={pipelines}
                  value={watch('pipeline')}
                />
                <Typography color={'red'} fontSize={'12px'}>
                  {errors?.['pipeline']?.message}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display={'flex'} justifyContent={'end'} marginTop={4}>
            <CustomButton
              isLoading={loading}
              disabled={Object.keys(errors).length !== 0}
              buttonText={t(`${TRANS_KEYS.REPORT_BUILDER_GENERATE_AND_DOWNLOAD}:key`)}
              variant='contained'
              onClick={handleGenerateAndDownload}
              startAdornment={null}
            />
          </Box>
        </Box>
      </>
    </DrawerComponent>
  );
};

ViewReportDrawer.propTypes = {
  candidateName: PropTypes.string,
  projectName: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  fromHeaderBar: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  handleDownloadReport: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  projectId: PropTypes.string,
  contactId: PropTypes.string,
  candidateId: PropTypes.string
};

export default ViewReportDrawer;
