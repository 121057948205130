/* eslint-disable indent */
/* eslint-disable no-unused-vars */
//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { REPLACE_SPACE_REGEX, SPECIAL_CHARACTERS_REPLACE_REGEX } from '../../services/constantService';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import IconBreadcrumbs from '../BreadCrumbs/BreadCrumbs';
import SubTabs from './SubTabs';
import './TabLayout.scss';
import TabPanel from './TabPanel';

const AntTab = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontSize: 16
  }
}))(props => <Tab {...props} />);

/**
 * DynamicTabLayout component is used to create dynamic tabs with subtabs
 * IMPORTANT : Use isDrawer for JS and isDrawerCss for CSS
 */
const DynamicTabLayout = props => {
  const {
    navItems,
    children,
    navLinks = [],
    disableRipple = true,
    showBreadcrumbs = false,
    isDrawerCss = false,
    isHeaderNav,
    tabIndexSet = [],
    drawerLayoutClass = '',
    // use if you want to deep link this component else keep it false
    onTabChange = () => {},
    activeTab = 0, // parent tab index
    tabIndex = 0, // child tab index
    setActiveTab = () => {},
    setTabIndex = () => {}
  } = props;

  const navigate = useNavigate();
  const newNavItems = navItems.map(item => {
    return {
      ...item,
      label: item.label.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and').toLowerCase().replace(REPLACE_SPACE_REGEX, '-'),
      subTabs: item.subTabs
        ? item.subTabs.map(ele => {
            return {
              ...ele,
              label: ele.label.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and').toLowerCase().replace(REPLACE_SPACE_REGEX, '-')
            };
          })
        : []
    };
  });
  // const handleChange = async (event, newValue) => {
  //   setActiveTab(newValue);
  // };
  const handleTabContentClick = async (tabIndex, subTabIndex) => {
    const result = await onTabChange(newNavItems[tabIndex].subTabs[subTabIndex].label);
    if (result) {
      setTabIndex(subTabIndex);
    } else {
      setTabIndex(subTabIndex);
      onTabChange(newNavItems[tabIndex].subTabs[subTabIndex].label);
    }
  };

  const handleChange = async (event, newValue) => {
    if (newNavItems[newValue].disabled) return;
    const result = await onTabChange(newNavItems[newValue].label);
    if (result) {
      setActiveTab(newValue);
      // setTabIndex(0);
    } else {
      setActiveTab(newValue);
      // setTabIndex(0);
      onTabChange(newNavItems[newValue].label);
    }
  };
  useEffect(() => {
    onTabChange(newNavItems[activeTab].label);
  }, [activeTab]);
  useEffect(() => {
    if (newNavItems[activeTab].subTabs.length > 0) {
      onTabChange(newNavItems[activeTab].subTabs[tabIndex].label);
    }
  }, [tabIndex]);

  useEffect(() => {
    navigate(window.location);
  }, [navigate]);

  useEffect(() => {
    if (tabIndexSet.length > 0) {
      handleTabContentClick(tabIndexSet[0], tabIndexSet[1]);
    }
  }, [tabIndexSet]);

  const buildTabDetails = tabItem => {
    const childrenList = Array.isArray(children) ? children : [children];
    return childrenList
      .filter(item => item)
      .map((item, index) => {
        return (
          <TabPanel key={index} index={item.props.index} value={navItems[activeTab].label}>
            <Box
              className={`tab-layout-content ${isDrawerCss ? '' : 'p-4'}`}
              sx={{
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5
              }}
            >
              {showBreadcrumbs && (
                <Box className='mb-3'>
                  <IconBreadcrumbs tabItem={tabItem} navLinks={navLinks}></IconBreadcrumbs>
                </Box>
              )}
              {item}
            </Box>
          </TabPanel>
        );
      });
  };

  return (
    <Box id='tab-layout' className={`tab-layout-container ${isDrawerCss ? '' : 'pt-8'}`}>
      <Box>
        <Tabs value={activeTab} variant='scrollable' scrollButtons='on' onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }} className='tab-background'>
          {navItems.map((tab, index) => (
            <AntTab
              disableRipple={disableRipple}
              disabled={tab.disabled}
              key={index}
              label={tab.label}
              indicatorColor='none'
              className={`header-font py-2 ${
                isDrawerCss && activeTab === index
                  ? 'active-tab-drawer px-3'
                  : !isDrawerCss && activeTab === index
                    ? 'active-tab px-4'
                    : isDrawerCss
                      ? 'non-active-tab-drawer px-3'
                      : 'non-active-tab px-4'
              } ${isDrawerCss ? 'body-text' : ''}`}
            />
          ))}
        </Tabs>
      </Box>

      {navItems.map((tab, index) => (
        <TabPanel key={index} value={activeTab} index={index}>
          {tab.subTabs.length ? (
            <Box className='sub-tab-layout'>
              <SubTabs
                disableRipple={disableRipple}
                subTabs={tab.subTabs}
                onTabContentClick={subTabIndex => handleTabContentClick(index, subTabIndex)}
                activeSubTab={tabIndex}
                // children={children}
                navLinks={navLinks}
                showBreadcrumbs={showBreadcrumbs}
                isDrawer={isDrawerCss}
                isHeaderNav={isHeaderNav}
                drawerLayoutClass={drawerLayoutClass}
              >
                {children}
              </SubTabs>
            </Box>
          ) : (
            <Box>{buildTabDetails(tab)}</Box>
          )}
        </TabPanel>
      ))}
    </Box>
  );
};

DynamicTabLayout.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
  navItems: PropTypes.array,
  baseRoute: PropTypes.string,
  isHeaderNav: PropTypes.string,
  navLinks: PropTypes.array,
  disableRipple: PropTypes.bool,
  showBreadcrumbs: PropTypes.bool,
  isDrawer: PropTypes.bool,
  isDrawerCss: PropTypes.bool,
  queryParams: PropTypes.string,
  onTabChange: PropTypes.func,
  tabIndexSet: PropTypes.array,
  drawerLayoutClass: PropTypes.string,
  isDynamicTab: PropTypes.bool,
  activeTab: PropTypes.number,
  tabIndex: PropTypes.number,
  setActiveTab: PropTypes.func,
  setTabIndex: PropTypes.func
};

export default DynamicTabLayout;
